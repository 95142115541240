import { useQuery, QueryResult, useQueryCache } from 'react-query';
import { useEffect } from 'react';
import { client } from './api-client';

export interface Medication {
  orderDisplayLineShort: string;
  orderDate: string;
  orderComment: any;
}

interface OEFieldId {
  oeFieldId: number;
}

interface MedicationsInputJson {
  personId: number;
}

interface MedicationsClientRequestItem {
  path: String;
  data: MedicationsInputJson;
}

const medicationsConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

const getMedicationsConfig = (
  queryKey,
  { path, data }: MedicationsClientRequestItem,
) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);
    if (!response.ok && !response.medications && response.medications.length === 0) {
      throw new Error('Unable to retrieve data from EMR');
    }
    return response.medications;
  },
  config: { ...medicationsConfig },
});

function useMedications(
  data: MedicationsInputJson,
): QueryResult<Array<Medication>> {
  const requestItem: MedicationsClientRequestItem = {
    data,
    path: `patient/${data.personId}/Medications`,
  };
  return useQuery<Array<Medication>>(
    getMedicationsConfig('medications', requestItem),
  );
}

function usePrefetchMedication(latestData, page) {
  const cache = useQueryCache();
  useEffect(() => {
    if (latestData?.hasMore) {
      cache.prefetchQuery(['medications', page + 1], () =>
        client(`medications?page=${page + 1}`).then(data => data),
      );
    }
  }, [latestData, page]);
}

export { useMedications, usePrefetchMedication };
