/* eslint-disable no-nested-ternary */
import { API } from 'aws-amplify';

// Check if we are running code in test environment
// If so, apiURL should be process.env.REACT_APP_TEST_API_URL

const apiURL = process.env.REACT_APP_API_URL;
const apiName = process.env.REACT_APP_API_NAME;

async function testClient(
  endpoint,
  {
    data,
    token = null,
    method = null,
    headers: customHeaders = {},
    testUrl = null,
    ...customConfig
  } = {},
) {
  const config = {
    method: method || (data ? 'POST' : 'GET'),
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };

  return window
    .fetch(`${testUrl || apiURL}/${endpoint}`, config)
    .then(async response => {
      if (response.status === 401) {
        return Promise.reject({ message: 'Please re-authenticate.' });
      }

      const data = await response.json();
      if (response.ok) {
        return data;
      }
      return Promise.reject(data);
    });
}

async function client(endpoint, customConfig = {}) {
  const {
    data,
    token = null,
    method = 'GET',
    headers: customHeaders = {},
    testUrl = null,
    ...custom
  } = customConfig;

  if (testUrl) {
    return testClient(endpoint, customConfig);
  }

  let request = null;

  switch (method) {
    case 'DELETE':
      request = API.del;
      break;
    case 'POST':
      request = API.post;
      break;
    case 'PUT':
      request = API.put;
      break;
    default:
      request = data ? API.post : API.get;
      break;
  }

  const config = {
    // method: method || (data ? 'POST' : 'GET'),
    body: data ? data : undefined,
    response: true,
    headers: {
      'Content-Type': 'application/json',
      ...customHeaders,
    },
    ...custom,
  };

  const fetch = request.bind(API);

  return fetch(apiName, endpoint, config).then(async response => {
    if (response.status === 401) {
      return Promise.reject({ message: 'Please re-authenticate.' });
    }

    if (
      response.statusText === 'OK' ||
      response.status === 200 ||
      response.status === 202 ||
      response.status === 204
    ) {
      return response.data;
    }
    return Promise.reject(response);
  });
}

export { client, testClient, apiURL };
