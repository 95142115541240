import React from 'react';
import { Field } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useErrorStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}));

const Error = ({ name }) => {
  const styles = useErrorStyles();
  return (
    <Field
      name={name}
      subscription={{
        error: true,
        visited: true,
        touched: true,
      }}
      render={({ meta: { error, visited, touched } }) =>
        (touched && error) || (visited && error) ? (
          <Typography className={styles.root}>{error}</Typography>
        ) : null
      }
    />
  );
};

export { Error };
