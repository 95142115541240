import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from './theme';
import { AuthProvider } from './auth-context';

const queryConfig = {
  useErrorBoundary: true,
  refetchAllOnWindowFocus: false,
};

function AppProviders({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <ReactQueryConfigProvider config={queryConfig}>
        <Router>
          <AuthProvider>{children}</AuthProvider>
        </Router>
      </ReactQueryConfigProvider>
    </MuiThemeProvider>
  );
}

export { AppProviders };
