import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { formatDOBDate } from 'utils/dateFormat';

const useEmailStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    list: {
      backgroundColor: theme.palette.background.default,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      '& ul > li': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: 'auto',
      },
    },
    patient: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(1350)]: {
        flexWrap: 'wrap',
      },
    },
  };
});

interface PatientMessageInfoProps {
  currentMessage: {
    patient?: {
      name: String;
      dateOfBirth: String;
      gender?: any;
      mrn: String;
      id: Number;
    };
  };
}

function PatientMessageInfo(props: PatientMessageInfoProps) {
  const { currentMessage = {} } = props;
  const classes = useEmailStyles();
  return (currentMessage.patient && currentMessage.patient.id !== 0) ? (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.list}>
            <List className={classes.patient}>
              <ListItem>
                <Box mr={0.5}>
                  <Typography variant="subtitle2">Patient:</Typography>
                </Box>
                <ListItemText>
                  <Typography variant="body2">
                    {currentMessage.patient.name}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <Box mr={0.5}>
                  <Typography variant="subtitle2">Gender:</Typography>
                </Box>
                <ListItemText>
                  <Typography variant="body2">
                    {currentMessage.patient.gender.display}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <Box mr={0.5}>
                  <Typography variant="subtitle2">MRN:</Typography>
                </Box>
                <ListItemText>
                  <Typography variant="body2">
                    {currentMessage.patient.mrn}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <Box mr={0.5}>
                  <Typography variant="subtitle2">DOB:</Typography>
                </Box>
                <ListItemText>
                  <Typography variant="body2">
                    {formatDOBDate(currentMessage.patient.dateOfBirth)}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
    </>
  ) : null;
}

export default PatientMessageInfo;
