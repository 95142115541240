import React, { useState, useEffect } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useReplyMessage } from 'utils/messages';
import { EmailButton } from './buttons';
import RecipientInput from './RecipientInput';
import SearchModal from './SearchModal';
import AdvancedRecipientSearch from './AdvancedRecipientSearch';
import PatientMessageInfo from './PatientMessageInfo';
import ReceivedMessage from './ReceivedMessage';
import { Error } from './ErrorField';
import WarningModal from './WarningModal';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiGrid-container': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

function getSenderName(sender) {
  return sender.prsnlId !== 0 ? sender.prsnlName : sender.groupName;
}

function ReplyMessage(props: ReplyMessageProps) {
  const {
    setCollapsed,
    currentMsg,
    user,
    exposeReplyForm,
    setCurrentMessage,
    currentTab,
  } = props;
  const [createMessage, { status: createStatus }] = useReplyMessage();
  const [open, setOpen] = useState(false);
  const [openCC, setOpenCC] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([] as any);
  const [warning, setWarningModal] = useState(false);
  const [selectedCCContacts, setSelectedCCContacts] = useState([] as any);

  useEffect(() => {
    if (currentMsg.sender) {
      if (
        Boolean(currentMsg.sender.groupId) &&
        Boolean(currentMsg.sender.prsnlId)
      ) {
        const toPool = {
          id: currentMsg.sender.groupId,
          name: currentMsg.sender.groupName,
          positionCd: currentMsg.sender.position.code,
          position: currentMsg.sender.position,
          groupFlag: 1,
        };

        setSelectedContacts([toPool]);
      } else {
        const id =
          currentMsg.sender.prsnlId !== 0
            ? currentMsg.sender.prsnlId
            : currentMsg.sender.groupId;
        const groupFlag = currentMsg.sender.prsnlId === 0 ? 1 : 0;

        const to = {
          name: getSenderName(currentMsg.sender),
          id,
          positionCd: currentMsg.sender.position.code,
          position: currentMsg.sender.position,
          groupFlag,
        };
        setSelectedContacts([to]);
      }
    }
  }, [currentMsg]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenCC = () => setOpenCC(true);
  const handleCloseCC = () => setOpenCC(false);
  const handleCloseWarning = () => setWarningModal(false);

  const validate = values => {
    const errors = {} as any;
    if (!values.recipient && !selectedContacts.length) {
      errors.recipient = 'Recipient is required';
    }
    if (!values.message) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  const onSubmit = async ({ message, subject }) => {
    const data = {
      selectedContacts,
      selectedCCContacts,
      selectedPatient: [{ personId: currentMsg.patient.id }],
      subject,
      user,
      message,
      notificationUid: currentMsg.notificationUid,
    };
    return createMessage(data as any);
  };
  const classes = useStyles();

  return (
    <>
      <Box style={{ overflow: 'scroll' }} px={1}>
        <PatientMessageInfo currentMessage={currentMsg} />
        <div>
          <Paper elevation={3}>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                subject: `Re: ${currentMsg.subject}`,
                message: '',
              }}
            >
              {({ handleSubmit, form, submitting, pristine }) => (
                <form
                  className={classes.root}
                  onSubmit={async e => {
                    handleSubmit(e)?.then(() => {
                      form.reset();
                      setSelectedContacts([]);
                      setSelectedCCContacts([]);
                      setCollapsed('reset');
                    });
                  }}
                  autoComplete="off"
                >
                  <Box p={2} mt={2}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Close
                        color="disabled"
                        fontSize="small"
                        onClick={() => {
                          if (!pristine) {
                            setWarningModal(true);
                          } else {
                            setCollapsed('reset');
                            setCurrentMessage({});
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </Box>
                    <Grid container>
                      <Grid item container alignItems="center">
                        <Field
                          name="recipient"
                          component={RecipientInput}
                          selectedContacts={selectedContacts}
                          setSelectedContacts={setSelectedContacts}
                          InputAdornmentComponent={
                            <EmailButton
                              color="primary"
                              underline="none"
                              onClick={handleOpen}
                            >
                              <Typography>To:</Typography>
                            </EmailButton>
                          }
                        />
                      </Grid>
                      <Error name="recipient" />
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} container alignItems="center">
                        <RecipientInput
                          selectedContacts={selectedCCContacts}
                          setSelectedContacts={setSelectedCCContacts}
                          InputAdornmentComponent={
                            <EmailButton
                              color="primary"
                              underline="none"
                              onClick={handleOpenCC}
                            >
                              <Typography>CC:</Typography>
                            </EmailButton>
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid alignItems="flex-end" container>
                      <Grid item xs={12}>
                        <Field name="subject">
                          {({ input }) => (
                            <TextField
                              id="subject"
                              rowsMax={4}
                              variant="standard"
                              fullWidth
                              {...input}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box px={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Field name="message">
                          {({ input }) => (
                            <TextField
                              id="outlined-multiline-static"
                              label="Message"
                              multiline
                              rows={8}
                              variant="outlined"
                              style={{ width: '100%' }}
                              {...input}
                            />
                          )}
                        </Field>
                        <Error name="message" />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box p={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={submitting || pristine}
                      type="submit"
                    >
                      {submitting ? 'Sending' : 'Send'}
                    </Button>
                  </Box>
                  <FormSpy
                    subscription={{ pristine: true }}
                    onChange={({ pristine }) =>
                      props.exposeReplyForm({ pristine })
                    }
                  />
                </form>
              )}
            </Form>
          </Paper>
        </div>
        <ReceivedMessage currentMessage={currentMsg} currentTab={currentTab} />
        {currentMsg.render && (
          <Box pl={4}>
            <Typography
              variant="body2"
              component="div"
              className="content"
              dangerouslySetInnerHTML={{
                __html: currentMsg.msgBody!,
              }}
            />
          </Box>
        )}
      </Box>

      <SearchModal open={open} handleClose={handleClose}>
        <AdvancedRecipientSearch
          handleClose={handleClose}
          setSelectedContacts={setSelectedContacts}
          selectedContacts={selectedContacts}
        />
      </SearchModal>
      <SearchModal open={openCC} handleClose={handleCloseCC}>
        <AdvancedRecipientSearch
          handleClose={handleCloseCC}
          setSelectedContacts={setSelectedCCContacts}
          selectedContacts={selectedCCContacts}
        />
      </SearchModal>
      <WarningModal
        open={warning}
        handleClose={handleCloseWarning}
        setCollapsed={setCollapsed}
        exposeReplyForm={exposeReplyForm}
        setCurrentMessage={setCurrentMessage}
      />
    </>
  );
}

interface ReplyMessageProps {
  children?: React.ReactNode;
  setCollapsed: Function;
  currentMsg?: any;
  exposeReplyForm: Function;
  setCurrentMessage: Function;
  currentTab: number;
  user: any;
}

export default ReplyMessage;
