/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

type display = {
  display: string;
};

type relashionship = {
  providerName: string;
  relationshipType: display;
};

type encounter = {
  regDate: string | null;
  dischargeDate: string;
  encounterRelationships: Array<relashionship>;
  encounterType: display;
};
type appointment = {
  display: string;
  apptType: display;
  apptBeginDate: string;
};

export interface Visit {
  encounter: encounter | null;
  appointment: appointment;
}

const visitsConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

interface CodeDisplay {
  code: number;
  display: string;
}

interface VisitsInputJson {
  personId: number;
}

export interface VisitsClientRequestItem {
  data: VisitsInputJson;
  name?: string;
  path: string;
  token?: any;
  method?: any;
  headers?: {} | undefined;
  testUrl?: any;
}

const getVisitsConfig = (queryKey, { path, data }) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);
    if (!response.ok && !response.visits && response.visits.length === 0) {
      throw new Error('Unable to retrieve data from EMR');
    }
    return response.visits;
  },
  config: { ...visitsConfig },
});

function useVisits(data: VisitsInputJson): QueryResult<Array<Visit>> {
  const requestItem: VisitsClientRequestItem = {
    data,
    path: `patient/${data.personId}/visits`,
  };
  return useQuery<Array<Visit>>(getVisitsConfig('visits', requestItem));
}
export { useVisits };
