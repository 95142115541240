/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

export interface Problem {
  description: string;
  onSetDate: string;
  onSetDateDisplay: string;
}
const problemsConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

export interface ProblemsInputJson {
  personId: number;
}

export interface ProblemsClientRequestItem {
  data: ProblemsInputJson;
  name?: string;
  path: string;
  token?: any;
  method?: any;
  headers?: {} | undefined;
  testUrl?: any;
}

const getProblemsConfig = (
  queryKey,
  { path, data }: ProblemsClientRequestItem,
) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);

    if (!response.ok && !response.problems && response.problems.length === 0) {
      throw new Error('Unable to retrieve data from EMR');
    }

    return response.problems;
  },
  config: { ...problemsConfig },
});

function useProblems(data: ProblemsInputJson): QueryResult<Array<Problem>> {
  const apiPath = `patient/${data.personId}/Problems`;
  const requestItem: ProblemsClientRequestItem = {
    data,
    path: apiPath,
  };

  return useQuery<Array<Problem>>(getProblemsConfig('problems', requestItem));
}
export { useProblems };
