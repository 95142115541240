import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: theme.spacing(1 / 2),
  },
  toolbar: {
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 2),
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <AppBar position="relative" color="primary" className={classes.footer}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <HelpIcon fontSize="small" />
          <Typography color="inherit">1-855-269-6560</Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
