import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'core-js/es/typed-array/from';
import 'core-js/es/object';
import 'isomorphic-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query-devtools';
import Amplify, { Auth } from 'aws-amplify';
import { polyfill } from 'es6-promise';

import 'index.css';
import 'test/server';
import AuthSessionStorge from './config/AuthSessionStorage';
import awsConfig from './config/amplify-config';
import App from './App';
import { AppProviders } from './context';

polyfill();

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('test/data/messagesDB');
}

let config: any = { storage: AuthSessionStorge };
if (process.env.NODE_ENV === 'development') {
  // localStorage for dev env
  config = {};
}
Amplify.configure(awsConfig);
Auth.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
    <ReactQueryDevtools initialIsOpen={false} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
