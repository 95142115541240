/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import { Form, Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import { useNewMessage } from 'utils/messages';
import RecipientInput from './RecipientInput';
import SearchModal from './SearchModal';
import AdvancedRecipientSearch from './AdvancedRecipientSearch';
import AdvancedPatientSearch from './AdvancedPatientSearch';
import { EmailButton, TextButton } from './buttons';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiGrid-container': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  recipients: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    height: 50,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  reciepientField: {
    flexGrow: 1,
  },
}));

const useErrorStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}));

const Error = ({ name }) => {
  const styles = useErrorStyles();
  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? (
          <Typography className={styles.root}>{error}</Typography>
        ) : null
      }
    />
  );
};

interface ComposeMessageProps {
  setEmailCollapsed: Function;
  user: any;
}

function ComposeMessage(props: ComposeMessageProps) {
  const [createMessage, { status: createStatus }] = useNewMessage();
  const { setEmailCollapsed, user } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openCC, setOpenCC] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([] as any);
  const [selectedCCContacts, setSelectedCCContacts] = useState([] as any);
  const [selectedPatient, setSelectedPatient] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenCC = () => setOpenCC(true);
  const handleCloseCC = () => setOpenCC(false);
  const handlePatientOpen = () => setOpenPatient(true);
  const handlePatientClose = () => setOpenPatient(false);

  const onSubmit = async ({ message, subject }) => {
    const data = {
      selectedContacts,
      message,
      user,
      selectedCCContacts,
      selectedPatient,
      subject,
    };
    return createMessage(data as any);
  };

  const validate = values => {
    const errors = {} as any;
    if (!values.recipient && !selectedContacts.length) {
      errors.recipient = 'Recipient is required';
    }
    if (!values.message) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  return (
    <div>
      <Paper elevation={3}>
        <Box className={classes.recipients} px={3}>
          <Typography>Recipients</Typography>
          <Close
            color="disabled"
            fontSize="small"
            onClick={() => setEmailCollapsed('reset')}
            style={{ cursor: 'pointer' }}
          />
        </Box>
        <Box px={3}>
          <Form onSubmit={onSubmit} validate={validate}>
            {({ handleSubmit, form, pristine, submitting }) => (
              <form
                className={classes.root}
                onSubmit={async (e: any) => {
                  handleSubmit(e)?.then(() => {
                    form.reset();
                    setSelectedContacts([]);
                    setSelectedCCContacts([]);
                    setSelectedPatient([]);
                    setEmailCollapsed('reset');
                  });
                }}
                autoComplete="off"
              >
                <Grid container>
                  <Grid item container alignItems="center">
                    <Field
                      name="recipient"
                      component={RecipientInput}
                      selectedContacts={selectedContacts}
                      setSelectedContacts={setSelectedContacts}
                      InputAdornmentComponent={
                        <EmailButton onClick={handleOpen} underline="none">
                          <Typography>To:</Typography>
                        </EmailButton>
                      }
                    />
                  </Grid>
                  <Error name="recipient" />
                </Grid>
                <Grid container alignItems="center">
                  <RecipientInput
                    selectedContacts={selectedCCContacts}
                    setSelectedContacts={setSelectedCCContacts}
                    InputAdornmentComponent={
                      <EmailButton onClick={handleOpenCC} underline="none">
                        <Typography>CC:</Typography>
                      </EmailButton>
                    }
                  />
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Field name="subject">
                      {({ input }) => (
                        <TextField
                          id="subject"
                          label="Subject"
                          variant="standard"
                          {...input}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Box py={0.5}>
                  <Grid item xs={12} container>
                    <TextButton
                      onClick={handlePatientOpen}
                      disabled={selectedPatient.length === 1}
                    >
                      <Typography>Patient:</Typography>
                    </TextButton>
                    <Box pl={1}>
                      <RecipientInput
                        patient
                        selectedContacts={selectedPatient}
                        setSelectedContacts={setSelectedPatient}
                      />
                    </Box>
                  </Grid>
                </Box>
                <Box pb={1}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Field name="message">
                        {({ input }) => (
                          <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            multiline
                            rows={10}
                            variant="outlined"
                            fullWidth
                            {...input}
                          />
                        )}
                      </Field>
                      <Error name="message" />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  display="flex"
                  pb={3}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={submitting || pristine}
                    type="submit"
                  >
                    {submitting ? 'Sending' : 'Send'}
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </Box>
      </Paper>
      <SearchModal open={open} handleClose={handleClose}>
        <AdvancedRecipientSearch
          handleClose={handleClose}
          setSelectedContacts={setSelectedContacts}
          selectedContacts={selectedContacts}
        />
      </SearchModal>
      <SearchModal open={openCC} handleClose={handleCloseCC}>
        <AdvancedRecipientSearch
          handleClose={handleCloseCC}
          setSelectedContacts={setSelectedCCContacts}
          selectedContacts={selectedCCContacts}
        />
      </SearchModal>
      <SearchModal open={openPatient} handleClose={handlePatientClose}>
        <AdvancedPatientSearch
          handleClose={handlePatientClose}
          setSelectedContacts={setSelectedPatient}
          selectedContacts={selectedPatient}
        />
      </SearchModal>
    </div>
  );
}
export default ComposeMessage;
