import { format } from 'date-fns';

export function formatDateMessage(date) {
  return date ? format(new Date(date), "LLLL dd',' yyyy h:mma") : null;
}

export function formatDOBDate(date) {
  return date ? format(new Date(date), "LLL dd',' yyyy") : null;
}

export function formatVisitDateAndTime(date) {
  return date ? format(new Date(date), "LLL dd',' yyyy h:mma") : null;
}

export function formatDate(date) {
  return date ? format(new Date(date), "LLL dd',' yyyy") : null;
}

export function formatDateTime(date) {
  return date ? format(new Date(date), "LLL dd',' yyyy h:mma") : null;
}

export function formatDatePicker(date) {
  return date ? format(new Date(date), "LLL dd',' yyyy") : null;
}

export function getPendingStartDate() {
  const d = new Date();
  d.setMonth(d.getMonth() - 6);
  return d;
}

export function getStartDate(months) {
  const d = new Date();
  d.setMonth(d.getMonth() - months);
  return d;
}

export function getEndDate(months) {
  const d = new Date();
  d.setMonth(d.getMonth() + months);
  return d;
}

export function dateDiffInDays(a, b) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}
