import styled from '@emotion/styled';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

const EmailButton = styled(Link)({
  textTransform: 'none',
  cursor: 'pointer',
  color: '#979DB0',
});

const TextButton = styled(Button)({
  textTransform: 'none',
  cursor: 'pointer',
  color: '#979DB0',
});

export { EmailButton, TextButton };
