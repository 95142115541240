import React, { useState, useEffect } from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Close from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useReplyMessage } from 'utils/messages';
import { EmailButton } from './buttons';
import RecipientInput from './RecipientInput';
import SearchModal from './SearchModal';
import AdvancedRecipientSearch from './AdvancedRecipientSearch';
import PatientMessageInfo from './PatientMessageInfo';
import ReceivedMessage from './ReceivedMessage';
import { Error } from './ErrorField';
import WarningModal from './WarningModal';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiGrid-container': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

function ReplyAllMessage(props: ReplyAllMessageProps) {
  const {
    setCollapsed,
    currentMsg,
    user,
    exposeReplyForm,
    setCurrentMessage,
    currentTab,
  } = props;
  const classes = useStyles();
  const [createMessage, { status: createStatus }] = useReplyMessage();
  const [open, setOpen] = useState(false);
  const [openCC, setOpenCC] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([] as any);
  const [selectedCCContacts, setSelectedCCContacts] = useState([] as any);
  const [warning, setWarningModal] = useState(false);

  useEffect(() => {
    if (currentMsg.sender) {
      if (
        Boolean(currentMsg.sender.groupId) &&
        Boolean(currentMsg.sender.prsnlId)
      ) {
        const toPool = {
          id: currentMsg.sender.groupId,
          name: currentMsg.sender.groupName,
          positionCd: currentMsg.sender.position.code,
          position: currentMsg.sender.position,
          groupFlag: 1,
        };

        setSelectedContacts([toPool]);
      } else {
        const id =
          currentMsg.sender.prsnlId !== 0
            ? currentMsg.sender.prsnlId
            : currentMsg.sender.groupId;
        const groupFlag = currentMsg.sender.prsnlId === 0 ? 1 : 0;

        const to = {
          name: currentMsg.sender.prsnlName,
          id,
          positionCd: currentMsg.sender.position.code,
          position: currentMsg.sender.position,
          groupFlag,
        };
        setSelectedContacts([to]);
      }
      setSelectedCCContacts([
        ...currentMsg.ccAssign.map(c => ({
          name: c.name,
          id: c.id,
          positionCd: c.positionCd,
          position: c.position,
          groupFlag: c.groupFlag,
        })),
        ...currentMsg.toAssign.map(c => ({
          name: c.name,
          id: c.id,
          positionCd: c.positionCd,
          position: c.position,
          groupFlag: c.groupFlag,
        })),
      ]);
    }
  }, [currentMsg]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenCC = () => setOpenCC(true);
  const handleCloseCC = () => setOpenCC(false);
  const handleCloseWarning = () => setWarningModal(false);

  const validate = values => {
    const errors = {} as any;
    if (!values.recipient && !selectedContacts.length) {
      errors.recipient = 'Recipient is required';
    }
    if (!values.message) {
      errors.message = 'Mesage is required';
    }
    return errors;
  };

  const onSubmit = async ({ message, subject }) => {
    const data = {
      selectedContacts,
      selectedCCContacts,
      selectedPatient: [currentMsg.patient],
      subject,
      user,
      message,
      notificationUid: currentMsg.notificationUid,
    };
    return createMessage(data as any);
  };

  return (
    <>
      <Box style={{ overflow: 'scroll' }} px={1}>
        <PatientMessageInfo currentMessage={currentMsg} />
        <Paper elevation={3}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
              subject: `Re: ${currentMsg.subject}`,
              message: '',
            }}
          >
            {({ handleSubmit, form, submitting, pristine }) => (
              <form
                className={classes.root}
                onSubmit={async e => {
                  handleSubmit(e)?.then(() => {
                    form.reset();
                    setSelectedContacts([]);
                    setSelectedCCContacts([]);
                    setCollapsed('reset');
                  });
                }}
                autoComplete="off"
              >
                <Box p={2} mt={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Close
                      fontSize="small"
                      onClick={() => {
                        if (!pristine) {
                          setWarningModal(true);
                        } else {
                          setCollapsed('reset');
                          setCurrentMessage({});
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </Box>
                  <Grid container>
                    <Grid item xs={12} alignItems="center" container>
                      <Field
                        name="recipient"
                        component={RecipientInput}
                        selectedContacts={selectedContacts}
                        setSelectedContacts={setSelectedContacts}
                        InputAdornmentComponent={
                          <EmailButton
                            color="primary"
                            underline="none"
                            onClick={handleOpen}
                          >
                            <Typography>To:</Typography>
                          </EmailButton>
                        }
                      />
                    </Grid>
                    <Error name="recipient" />
                  </Grid>
                  <Grid item xs={12} container alignItems="center">
                    <RecipientInput
                      selectedContacts={selectedCCContacts}
                      setSelectedContacts={setSelectedCCContacts}
                      InputAdornmentComponent={
                        <EmailButton
                          color="primary"
                          underline="none"
                          onClick={handleOpenCC}
                        >
                          <Typography>CC:</Typography>
                        </EmailButton>
                      }
                    />
                  </Grid>

                  <Grid alignItems="flex-end" container>
                    <Grid item xs={12}>
                      <Field name="subject">
                        {({ input }) => (
                          <TextField
                            id="subject"
                            rowsMax={4}
                            fullWidth
                            variant="standard"
                            {...input}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Box>
                <Box p={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Field name="message">
                        {({ input }) => (
                          <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            multiline
                            rows={8}
                            variant="outlined"
                            style={{ width: '100%' }}
                            {...input}
                          />
                        )}
                      </Field>
                      <Error name="message" />
                    </Grid>
                  </Grid>
                </Box>
                <FormSpy
                  subscription={{ pristine: true }}
                  onChange={({ pristine }) =>
                    props.exposeReplyForm({ pristine })
                  }
                />
                <Box p={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={submitting || pristine}
                    type="submit"
                  >
                    {submitting ? 'Sending' : 'Send'}
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </Paper>
        <ReceivedMessage currentMessage={currentMsg} currentTab={currentTab} />

        {currentMsg.render && (
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: currentMsg.msgBody!,
            }}
          />
        )}
      </Box>
      <SearchModal open={open} handleClose={handleClose}>
        <AdvancedRecipientSearch
          handleClose={handleClose}
          setSelectedContacts={setSelectedContacts}
          selectedContacts={selectedContacts}
        />
      </SearchModal>
      <SearchModal open={openCC} handleClose={handleCloseCC}>
        <AdvancedRecipientSearch
          handleClose={handleCloseCC}
          setSelectedContacts={setSelectedCCContacts}
          selectedContacts={selectedCCContacts}
        />
      </SearchModal>
      <WarningModal
        open={warning}
        handleClose={handleCloseWarning}
        setCollapsed={setCollapsed}
        exposeReplyForm={exposeReplyForm}
        setCurrentMessage={setCurrentMessage}
      />
    </>
  );
}

interface ReplyAllMessageProps {
  children?: React.ReactNode;
  setCollapsed: Function;
  currentMsg?: any;
  exposeReplyForm: Function;
  setCurrentMessage: Function;
  currentTab: number;
  user: any;
}

export default ReplyAllMessage;
