import React, { useState, useEffect } from 'react';

interface TemporaryProps {
  timeOut?: number;
  cb?: Function;
  children: React.ReactNode;
}

function Temporary(props: TemporaryProps) {
  const { timeOut = 10000, children, cb = () => {} } = props;
  const [renderChild, setRenderChild] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderChild(false);
      cb();
    }, timeOut);
    return () => clearTimeout(timer);
  }, []);

  return <>{renderChild ? children : null}</>;
}

export default Temporary;
