/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

type display = {
  display: string;
};

const labResultsConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

export interface labResult {
  orderedMnemonic: string;
  resultName: display;
  resultValue: string;
  date: string;
  resultUnit: display;
  normalcy: display;
}

interface CodeDisplay {
  code: number;
  display: string;
}

interface LabResultsInputJson {
  personId: number;
}

const getLabResultsConfig = (queryKey, { path, data }, opts) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);
    if (!response.ok && !response.labResults && response.labResults.length === 0) {
      throw new Error('Unable to retrieve data from EMR');
    }
    return response.labResults;
  },
  config: { ...labResultsConfig, ...opts },
});

interface LabResultsClientRequestItem {
  path: String;
  data: LabResultsInputJson;
}

function useLabResults(
  data: LabResultsInputJson,
  opts = {},
): QueryResult<Array<labResult>> {
  const requestItem: LabResultsClientRequestItem = {
    data,
    path: `patient/${data.personId}/labResults`,
  };
  return useQuery<Array<labResult>>(
    getLabResultsConfig('labResults', requestItem, opts),
  );
}
export { useLabResults };
