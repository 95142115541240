/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

type display = {
  display: string;
};

export interface Note {
  resultName: display;
  dtTm: string;
  author: string;
  id: number;
}

const notesConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

type Encounter = {
  id: number;
};
export interface notesInputJson {
  personId: number;
  encounters?: Encounter[];
}

const getNotesConfig = (queryKey: string, data: notesInputJson) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(`patient/${data.personId}/documents`);

    if (
      !response.ok &&
      !response.documents &&
      response.documents.length === 0
    ) {
      throw new Error('Unable to retrieve data from EMR');
    }

    return response.documents;
  },
  config: { ...notesConfig },
});

function useNotes(data: notesInputJson): QueryResult<Array<Note>> {
  return useQuery<Array<Note>>(getNotesConfig('notes', data));
}

const getuseNotesDocumentConfig = (
  queryKey: string,
  data: documentInputJson,
) => ({
  queryKey: [queryKey, { eventId: data.eventId }],
  queryFn: () => client(`documents/${data.eventId}`).then(d => d.document),
  config: { ...notesConfig, enabled: !!data.eventId },
});

interface documentInputJson {
  eventId: number | null;
}

type Document = {
  fullDocument: string;
  documentId: number;
  documentName: string;
  documentDtTm: string;
  documentAuthor: string;
  documentStatus: { display: string };
  encounter: any;
};

function useNotesDocument(data: documentInputJson) {
  return useQuery<Document>(getuseNotesDocumentConfig('document', data));
}

export { useNotes, useNotesDocument };
