import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    action: { selectedOpacity: 1, disabled: '#979DB0' },
    primary: {
      main: '#162942',
      constrastText: '#FFFFFF',
    },
    secondary: { main: '#2743AA' },
    text: {
      primary: '#162942',
      secondary: '#979DB0',
    },
    background: {
      default: '#EFEFF3',
      navBar: '#3757FF',
    },
  },
  typography: {
    fontFamily: ['Muli'].join(','),
    fontSize: 14,
    htmlFontSize: 16,
    fontWeightMedium: 700,
    body1: {},
    body2: { fontWeight: 'normal' },
    button: {
      textTransform: 'none',
    },
    subtitle2: {
      fontWeight: 'bold',
    },
  },
});
