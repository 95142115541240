/* eslint-disable no-restricted-syntax */
import { useQuery, queryCache } from 'react-query';
import { client } from './api-client';
import { AsyncDebounce } from './hooks';

const patientQueryConfig = {
  staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
};

const getPatientsSearchConfig = (client, query, key, options) => ({
  queryKey: ['patientsSearch', { query }],
  queryFn: AsyncDebounce(
    () =>
      client(`patients?query=${encodeURIComponent(query)}&key=${key}`, {
        // testUrl: 'http://localhost:25969/api',
        ...options,
      }).then(data => data.patients),
    500,
  ),
  config: {
    onSuccess(patients) {
      for (const patient of patients) {
        queryCache.setQueryData(
          ['patient', { patientId: patient.personId }],
          patient,
          patientQueryConfig,
        );
      }
    },
  },
});

function usePatientsSearch(query, key, options) {
  const result = useQuery(getPatientsSearchConfig(client, query, key, options));
  return { ...result, patients: result.data };
}

export { usePatientsSearch };
