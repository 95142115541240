/* eslint-disable no-restricted-syntax */
import { useQuery, queryCache, QueryResult, useMutation } from 'react-query';
import { client } from './api-client';

const workListQueryConfig = {
  // staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
};

interface UseWorkList {
  onSuccess?: Function | null;
  FacilityId?: number;
  refetchOnWindowFocus?: boolean;
  retry?: boolean;
  retryOnMount?: boolean;
}

export interface WorkListInterface {
  id: number;
  name: string;
  encounterId: number;
  gender: string;
  nextVisit?: any;
  lastVisit?: any;
  documentation: Array<any>;
  dob: string;
  mrn: string;
  age?: number;
  patient: any;
  pcp?: { name: string };
  facility: { name: string };
}

interface WorkListResult extends QueryResult<Array<WorkListInterface>> {
  workList: Array<WorkListInterface>;
}

function useWorkList({ onSuccess, ...config }: UseWorkList): WorkListResult {
  const result = useQuery<Array<WorkListInterface>>({
    queryKey: ['workList'],
    queryFn: () =>
      client('patientworklist').then(data => {
        return data.items;
      }),
    config: {
      async onSuccess(workList) {
        await onSuccess?.(workList);
        for (const workListItem of workList) {
          queryCache.setQueryData(
            ['workListItem', { id: workListItem.patient.id }],
            workListItem,
            workListQueryConfig,
          );
        }
      },
      ...workListQueryConfig,
      ...config,
    },
  });
  return { ...result, workList: result.data ?? [] };
}

const itemQueryConfig = {
  staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
};

function useWrokListItemFromCache(id) {
  const queryKey = ['workListItem', { id }];
  const query = queryCache.getQuery(queryKey);
  return query;
}

function useWorkListItem(id) {
  const result = useQuery<WorkListInterface>({
    queryKey: ['workListItem', { id }],
    queryFn: () => client(`worklist/${id}`).then(data => data.workListItem),
    ...itemQueryConfig,
  });
  return result;
}

const defaultMutationOptions = {
  onError: (err, variables, recover) =>
    typeof recover === 'function' ? recover() : null,
  onSuccess: () => queryCache.invalidateQueries(['workList']),
};

interface RequestAccess {
  firstName: string;
  lastName: string;
  mrn: string;
  dob: string;
  gender: string;
  facility?: number;
}

function useRequestAccess(options = {}) {
  return useMutation(
    ({ firstName, lastName, mrn, dob, gender, facility }: RequestAccess) =>
      client('patients/requestAccess', {
        data: { firstName, lastName, mrn, dob, gender, facilityId: facility },
      }),
    {
      ...defaultMutationOptions,
      ...options,
    },
  );
}

export interface Gender {
  code: string;
  display: string;
}

const getGenderConfig = (queryKey, opts) => ({
  queryKey: [queryKey],
  queryFn: () =>
    client('Codesets/GetByName', {
      data: { names: ['gender'] },
    }).then(data => {
      const genderData = data.items.find(d => d.name === 'gender');
      return genderData.values ?? [];
    }),
  config: {
    ...opts,
  },
});

function useGender(opts): QueryResult<Array<Gender>> {
  return useQuery<Array<Gender>>(getGenderConfig('gender', opts));
}

interface RemovePatient {
  reason: string;
  patientId;
}

function RemovedItem(removedItem) {
  const previousItems = queryCache.getQueryData(['workList']);
  queryCache.setQueryData(
    ['workList'],
    (old: WorkListInterface[] | undefined = []) => {
      return old.filter(
        item => item.patient.patientId !== removedItem.patientId,
      );
    },
  );
  // return recover function
  return () => queryCache.setQueryData(['workList'], previousItems);
}

function useRemovePatient(opts = {}) {
  return useMutation(
    ({ patientId, reason }: RemovePatient) =>
      client(`patientWorklist/${patientId}`, {
        method: 'DELETE',
        data: { reason },
      }),
    {
      ...defaultMutationOptions,
      onMutate: RemovedItem,
      ...opts,
    },
  );
}

export {
  useWorkList,
  useRequestAccess,
  useGender,
  useWorkListItem,
  useWrokListItemFromCache,
  useRemovePatient,
};
