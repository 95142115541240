import queryString from 'query-string';

import { client } from './api-client';

function all({
  query,
  startDateTime,
  endDateTime,
  messageBodyFlag,
  lastTaskId,
}) {
  const obj = {
    start: startDateTime === '0' ? undefined : startDateTime,
    end: endDateTime === '0' ? undefined : endDateTime,
    messageType: query,
    messageBodyFlag,
    lastTaskId,
  };
  const queryStr = queryString.stringify(obj);

  return client(`messages?${queryStr}`, {
    method: 'GET',
  });
}

function read(messageId) {
  return client(`messages/${messageId}`, {
    method: 'GET',
    testUrl: process.env.REACT_APP_TEST_API_URL,
  });
}

function update(updates) {
  return client('messages', {
    method: 'PUT',
    data: updates,
  });
}

function remove(data) {
  return client('messages', {
    method: 'DELETE',
    data,
  });
}

function removeMessages(data) {
  return client('messages', {
    method: 'PUT',
    data,
  });
}

function create(data) {
  return client('messages', {
    method: 'POST',
    data,
  });
}

function restoreMessages(data) {
  return client('messages', {
    method: 'PUT',
    data,
  });
}

export { all, read, update, removeMessages, create, restoreMessages, remove };
