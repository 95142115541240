import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { Dialog } from '@material-ui/core';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export interface DialogProps {
  open: boolean;
  onClose: () => void;
  children: any;
}

function DialogWrapper(props: DialogProps) {
  const { onClose, open, children } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="dialog-patient-error"
      open={open}
    >
      {children}
    </Dialog>
  );
}

export default DialogWrapper;
