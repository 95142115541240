import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Auth, Hub } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyConfirmSignIn,
} from '@aws-amplify/ui-react';
import { useAsync } from 'utils/use-async-hook';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/Chart-Access-Logo.svg';
import { client } from 'utils/api-client';
import { LoadingSpinner } from 'components/PatientMessages';
import { useAudit } from 'utils/audit';
import TermsAndConditions from './TermsAndConditions';

function isSignedIn(authState) {
  return authState === AuthState.SignedIn;
}

async function getConfigSettings() {
  const data = await client('configSettings');
  queryCache.setQueryData('configSettings', data);
  return data;
}

function useBootstrapUserData(authState, signedTerm) {
  const { data, status, error, isLoading, isIdle, isError, isSuccess, run } =
    useAsync();

  useEffect(() => {
    if (
      (isSignedIn(authState) && !signedTerm) ||
      (isSignedIn(authState) && Boolean(signedTerm))
    ) {
      const promise = getConfigSettings();
      run(promise);
    }
  }, [isSignedIn(authState), signedTerm, run]);

  return { data, status, error, isLoading, isIdle, isError, isSuccess };
}

function useSignAgreement(sign, agreement) {
  const { data, status, error, isLoading, isIdle, isError, isSuccess, run } =
    useAsync();
  useEffect(() => {
    if (sign && agreement) {
      const promise = client('Agreement', {
        data: { id: agreement.id },
      });
      run(promise);
    }
  }, [sign, agreement, run]);

  return { data, status, error, isLoading, isIdle, isError, isSuccess, run };
}

function hasSignedUserAgreement(settings) {
  const currentTerms = settings.terms;
  const userAgreements = settings.user.signedTermsAndConditions;
  const agreement = userAgreements.find(
    a => a.termsAndConditionsId === currentTerms.id,
  );
  return Boolean(agreement);
}

export interface UserSignOn {
  email: string;
  password: string;
}

const AuthContext = React.createContext(null);
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
  const [audit] = useAudit();
  const [sing, setSign] = useState<boolean>(false);
  const [agreement, setAgreement] = useState(null);
  const history = useHistory();
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setData] = useState<any>(null);

  const { data: signedTerm, isLoading: isSigning } = useSignAgreement(
    sing,
    agreement,
  );

  const { data: configSettings, isLoading } = useBootstrapUserData(
    authState,
    signedTerm,
  );

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setData(authData);
    });
  }, []);

  useEffect(() => {
    return Hub.listen('auth', data => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        audit({
          eventType: 'UI:SignedIn',
          details: {
            uiScreen: 'Sign In',
          },
        });
        history.push('/');
      }
    });
  });

  const logout = useCallback(
    async (eventType: string, uiScreen: string): Promise<void> => {
      audit({ eventType, details: { uiScreen } });
      setData(null);
      queryCache.clear();
      await Auth.signOut();
      history.push('/');
    },
    [setData],
  );

  const value = useMemo(
    // eslint-disable-next-line prefer-arrow-callback
    function getUser() {
      if (user && user.signInUserSession && !user.facilityName) {
        Auth.currentSession().then(session => {
          const token = session.getIdToken();
          const { facilityName, name } = token.payload;
          setData({ ...user, facilityName, name });
        });
      }
      return {
        user,
        logout,
        configSettings,
      };
    },
    [user, configSettings, logout],
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (
    isSignedIn(authState) &&
    user &&
    configSettings &&
    hasSignedUserAgreement(configSettings)
  ) {
    return <AuthContext.Provider value={value} {...props} />;
  }
  if (isSignedIn(authState) && user && configSettings) {
    return (
      <TermsAndConditions
        isSigning={isSigning}
        onHandleClick={e => {
          setSign(true);
          setAgreement(configSettings.terms);
        }}
      >
        {configSettings.terms.content}
      </TermsAndConditions>
    );
  }
  return (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp headerText="">
        <div slot="header-subtitle">
          <Logo />
        </div>
      </AmplifySignIn>
      <AmplifyConfirmSignIn
        headerText="Confirm Sign In"
        slot="confirm-sign-in"
        user={user}
      />
    </AmplifyAuthenticator>
  );
}

function useAuth(): any {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

function useClient() {}

export { AuthProvider, useAuth, useClient };
