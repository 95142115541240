class AuthSessionStorage {
  static storageType() {
    return 'AuthStorageMemory';
  }

  constructor() {
    this.storageProp = 'Session Storage';
  }

  static setItem(key, value) {
    sessionStorage.setItem(key, value);
    return value;
  }

  static getItem(key) {
    return sessionStorage.getItem(key);
  }

  static removeItem(key) {
    if (this.getItem(key)) {
      sessionStorage.removeItem(key);
      return true.valueOf;
    }
    return false;
  }

  static clear() {
    sessionStorage.clear();
    return sessionStorage;
  }
}

export default AuthSessionStorage;
