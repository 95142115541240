import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Close from '@material-ui/icons/Close';
import { jsx, css } from '@emotion/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 5),
    width: '95vw',
    maxWidth: 1100,
    overflow: 'scroll',
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const { open, handleClose, children } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} style={{ maxHeight: '80vh' }}>
        <div className={classes.paper}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            pr={1.5}
          >
            <Close
              fontSize="small"
              color="disabled"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Box>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              id="transition-modal-title"
            >
              Type a name or select from list:
            </Typography>
          </Box>
          {children}
        </div>
      </Fade>
    </Modal>
  );
}
