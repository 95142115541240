import { useMutation } from 'react-query';
import { client } from './api-client';

const defaultMutationOptions = {
  onError: (err, variables, recover) =>
    typeof recover === 'function' ? recover() : null,
};

type Details = {
  patientId?: number;
  uiScreen: string;
  eventId?: string;
};

type Audit = {
  eventType: string;
  details: Details;
};

function useAudit(options = {}) {
  return useMutation(
    (values: Audit) =>
      client('audit', {
        data: values,
        method: 'POST',
      }),
    {
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useDeleteAudit(options = {}) {
  return useMutation(
    () =>
      client('audit', {
        method: 'DELETE',
      }),
    {
      ...defaultMutationOptions,
      ...options,
    },
  );
}

export { useAudit, useDeleteAudit };
