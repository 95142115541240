/* eslint-disable no-restricted-syntax */
import { useQuery, queryCache } from 'react-query';
import { AsyncDebounce } from 'utils/hooks';
import { client } from './api-client';

const contactQueryConfig = {
  staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
};

const getContactsSearchConfig = (client, query, selectedValue, options) => ({
  queryKey: ['contactsSearch', { query }],
  queryFn: AsyncDebounce(
    () =>
      client(`${selectedValue === 'personnel'
        ? `AddressBookSearch?searchString=${encodeURIComponent(query)}&limit=10`
        : `pools?searchString=${encodeURIComponent(query)}`}`, {
        ...options,
      }).then(data => {
        if (selectedValue === 'personnel') return data.addrBookSearch;
        return data.pools;
      }),
    500,
  ),
  config: {
    onSuccess(contacts) {
      for (const contact of contacts) {
        queryCache.setQueryData(
          ['contact', { contactId: contact.personId || contact.id }],
          contact,
          contactQueryConfig,
        );
      }
    },
  },
});

function useContactsSearch(query, selectedValue, options) {
  const result = useQuery(getContactsSearchConfig(client, query, selectedValue, options));
  return { ...result, contacts: result.data };
}

const getRequestContactsConfig = (client, options) => ({
  queryKey: ['request-contacts'],
  queryFn: () =>
    client('AddressBookSearch/AccessRequestPools', {
      ...options,
    }).then(data => data),

  config: {
    onSuccess(contacts) {
      for (const contact of contacts) {
        queryCache.setQueryData(
          ['contact', { contactId: contact.personId }],
          contact,
          contactQueryConfig,
        );
      }
    },
  },
});

function useRequestAccessContacts(options) {
  const result = useQuery(getRequestContactsConfig(client, options));
  return { ...result, contacts: result.data ?? [] };
}

export { useContactsSearch, useRequestAccessContacts };
