import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import { Form, Field, useFormState } from 'react-final-form';
import Close from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import Temporary from 'components/Temporary';
import { useGender, useRequestAccess } from 'utils/worklist';
import { useAuth } from 'context/auth-context';
import DialogActions from '@material-ui/core/DialogActions';
import DialogWrapper from 'components/Dialog';
import { useAudit } from 'utils/audit';
import { DialogTitle } from '@material-ui/core';

interface GrantAccessProps {
  classes: any;
  openForm: boolean;
  setSideBarOpen: Function;
  currentRow: any;
}

export interface FormValues {
  dob: string;
  firstName: string;
  gender: string;
  lastName: string;
  mrn: string;
  facility: string;
}

const useFormStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  subHeader: {
    marginRight: theme.spacing(2),
  },
  group: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  formControl: {
    minWidth: 120,
  },
}));

const validate = values => {
  interface Errors {
    firstName?: string;
    lastName?: string;
    mrn?: string;
    dob?: string;
    gender?: string;
    facility?: string;
  }

  const errors: Errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.mrn) {
    errors.mrn = 'Required';
  }
  if (!values.dob) {
    errors.dob = 'Required';
  }
  if (!values.gender) {
    errors.gender = 'Required';
  }
  if (!values.facility) {
    errors.facility = 'Must select a facility';
  }

  return errors;
};

interface DialogInterface {
  open: boolean;
  handleClose: () => void;
}

function ErrorDialog({ open, handleClose }: DialogInterface) {
  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <Box m={3}>
        <DialogTitle>Patient Not Found</DialogTitle>
        <Typography variant="body1">
          The patient could not be found based on search parameters entered.
        </Typography>
        <Typography variant="body1">Check the values and try again.</Typography>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Box>
    </DialogWrapper>
  );
}

type Facility = {
  name: string;
  facilityId: number;
};

interface ConfirmDialogInterface extends DialogInterface {
  facilities: Facility[];
  submitting: boolean;
  handleSubmit: any;
}

function getFacilityName(facilities, id) {
  return facilities.find(f => f.facilityId === id);
}

function ConfirmDialog({
  open,
  handleClose,
  facilities,
  handleSubmit,
  submitting,
}: ConfirmDialogInterface) {
  const { values } = useFormState();
  const facility = getFacilityName(facilities, values['facility']);
  const firstName = values['firstName'];
  const lastName = values['lastName'];

  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <Box m={3}>
        <DialogTitle>
          Confirm {firstName + ' ' + lastName} is located at {facility?.name}.
        </DialogTitle>
        <Box mt={1} mb={3} mx={3}>
          <Typography variant="body1">
            The facility will be granted access to this patient's chart.
          </Typography>
        </Box>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={submitting}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </DialogWrapper>
  );
}

function GrantAccess({ classes, openForm, setSideBarOpen }: GrantAccessProps) {
  const formClasses = useFormStyles();
  const [values, setValues] = useState({
    open: false,
    success: false,
    error: false,
  });
  const [audit] = useAudit();
  const {
    configSettings: { facilities },
  } = useAuth();

  const [formValues, setFormValues] = useState({} as FormValues);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [patientFound, setPatientFound] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [requestAccess, { isError, isSuccess, reset, status }] =
    useRequestAccess({
      throwOnError: true,
    });

  const { data: genderData } = useGender({ refetchOnWindowFocus: false });

  const handleErrorClose = () => reset();
  const handleConfirmClose = () => setOpenDialog(false);
  const handleDrawerOpen = () => setSideBarOpen(true);

  const onSubmit = async (requestValues: FormValues) => {
    const data: any = { ...requestValues };
    data.gender = genderData?.find(g => requestValues.gender === g.code);

    return requestAccess({ ...data, gender: { display: data.gender.display } })
      .then(
        () => {
          setPatientFound(true);
          setValues(prev => ({ ...prev, open: false }));
          setOpenDialog(false);
        },
        () => {
          setOpenDialog(false);
        },
      )
      .then(() => {
        audit({
          eventType: 'Patients:Grant:Access:FacilityId:Update',
          details: {
            uiScreen: 'Worklist',
          },
        });
      });
  };

  return (
    <div>
      <Box
        display="flex"
        mt={2}
        mb={2}
        className={`${classes.containerBox} ${
          openForm && classes.contentShift
        } `}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: openForm,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Button
          onClick={() => setValues(prev => ({ ...prev, open: !prev.open }))}
        >
          <Typography variant="subtitle2">
            Grant Access
            <IconButton aria-label="expand row" size="small">
              {values.open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Typography>
        </Button>
        {patientFound && (
          <Temporary cb={() => setPatientFound(false)}>
            <Alert severity="success">
              Success! The patient has been added to the worklist.
            </Alert>
          </Temporary>
        )}
      </Box>
      <Collapse
        in={values.open}
        timeout="auto"
        unmountOnExit
        onExited={() => reset()}
      >
        <Box mb={3} ml={6}>
          <Box mb={2} ml={1}>
            <Box display="flex" justifyContent="space-between">
              {isError && (
                <Box display="flex" alignItems="center">
                  <Typography>
                    The patient could not be found. Try again or send a message
                    to continue the request to access this patient’s chart.
                  </Typography>
                </Box>
              )}

              {((!isSuccess && !isError) || status !== 'error') && (
                <>
                  <Typography>
                    Enter the details below to grant the facility access to the
                    patient's chart.
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          {((!isSuccess && !isError) || status !== 'error') && (
            <Box display="flex" justifyContent="space-between">
              <Form onSubmit={onSubmit} validate={validate}>
                {({ handleSubmit, invalid, pristine, submitting }) => (
                  <form noValidate autoComplete="off">
                    <Box className={formClasses.root}>
                      <Field name="firstName">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            id="standard-basic"
                            label="First Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <Field name="lastName">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            id="filled-basic"
                            label="Last Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <Box className={formClasses.inline}>
                        <Typography>MRN:</Typography>
                        <Field name="mrn">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="outlined-basic"
                              label="MRN"
                              variant="outlined"
                            />
                          )}
                        </Field>
                      </Box>
                      <Box className={formClasses.inline}>
                        <Typography>DOB:</Typography>
                        <Field name="dob">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="date"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <Box className={formClasses.inline}>
                        <Typography className={formClasses.subHeader}>
                          Gender:
                        </Typography>
                        <FormControl
                          variant="outlined"
                          className={formClasses.formControl}
                        >
                          <InputLabel id="gender-label">Gender</InputLabel>
                          <Field name="gender">
                            {({ input, meta }) => (
                              <Select
                                {...input}
                                labelId="gender-label"
                                label="Gender"
                              >
                                {genderData?.map(obj => (
                                  <MenuItem key={obj.code} value={obj.code}>
                                    {obj.display}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </Field>
                        </FormControl>
                      </Box>
                      <Box className={formClasses.inline} ml={1}>
                        <Typography className={formClasses.subHeader}>
                          Facility:
                        </Typography>
                        <FormControl
                          variant="outlined"
                          className={formClasses.formControl}
                        >
                          <InputLabel id="facility-label">Facility</InputLabel>
                          <Field name="facility">
                            {({ input, meta }) => (
                              <Select
                                {...input}
                                labelId="facility-label"
                                label="Facility"
                              >
                                {facilities.map(obj => (
                                  <MenuItem
                                    key={obj.facilityId}
                                    value={obj.facilityId}
                                  >
                                    {obj.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </Field>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box m={1} mt={3}>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        disabled={pristine || invalid || submitting}
                        onClick={() => setOpenDialog(true)}
                      >
                        Grant Access
                      </Button>
                    </Box>
                    <ConfirmDialog
                      submitting={submitting}
                      open={openDialog}
                      handleClose={handleConfirmClose}
                      facilities={facilities}
                      handleSubmit={handleSubmit}
                    />
                  </form>
                )}
              </Form>
              <Box mr={5}>
                <Close
                  color="primary"
                  fontSize="small"
                  onClick={() => {
                    setValues(prev => ({ ...prev, open: false }));
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Collapse>
      <ErrorDialog open={isError} handleClose={handleErrorClose} />
    </div>
  );
}

export default GrantAccess;
