import { useQuery, queryCache, useMutation } from 'react-query';
import { lowerCaseAllWordsExceptFirstLetters } from 'components/ConfirmDialog';
import * as messagesClient from './messages-client';

const htmlToRtf = require('html-to-rtf');

const messageQueryConfig = {
  cacheTime: 1000 * 60 * 60,
  staleTime: 1000 * 60 * 60,
};

function getMessages(
  queryKey,
  { query, startDateTime, endDateTime, messageBodyFlag, lastTaskId },
) {
  return messagesClient
    .all({
      query,
      startDateTime,
      endDateTime,
      messageBodyFlag,
      lastTaskId,
    })
    .then(data => data);
}

const getMessagesConfig = (
  queryKey,
  query,
  timeFrame,
  startDateTime,
  endDateTime,
  messageBodyFlag,
  lastTaskId = 0,
  page,
  opts = {},
) => ({
  queryKey: [queryKey, { query, timeFrame, page }],
  queryFn: () => {
    return getMessages(queryKey, {
      query,
      startDateTime,
      endDateTime,
      messageBodyFlag,
      lastTaskId,
      page,
    });
  },
  config: {
    onSuccess(result) {
      const messages = result ? result.messages : [];
      // eslint-disable-next-line no-restricted-syntax
      for (const message of messages) {
        queryCache.setQueryData(
          ['message', { taskId: message.taskId }],
          message,
          messageQueryConfig,
        );
      }
    },
    refetchOnWindowFocus: false,
    ...opts,
  },
});

const defaultMutationOptions = {
  onError: (err, variables, recover) =>
    typeof recover === 'function' ? recover() : null,
  useErrorBoundary: false,
  throwOnError: true,
};

function onUpdateMutation({
  query = 'inbox',
  timeFrame,
  page,
  updtInfoList,
  ...newItem
}) {
  const previousItems = queryCache.getQueryData([
    'messages',
    { query, timeFrame, page },
  ]);

  queryCache.setQueryData(
    ['messages', { query, timeFrame, page }],
    (old = {}) => {
      return {
        ...previousItems,
        messages: old.messages.map(item => {
          return item.taskId === newItem.taskId
            ? { ...item, ...newItem }
            : item;
        }),
      };
    },
  );

  return () => {
    queryCache.setQueryData(
      ['messages', { query, timeFrame, page }],
      previousItems,
    );
  };
}

function useUpdateMessage(options) {
  return useMutation(
    ({ updtInfoList }) => {
      const data = {
        msgType: 'O',
        updtInfoList,
      };
      return messagesClient.update(data);
    },
    {
      onMutate: onUpdateMutation,
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useRestoreMessage(options) {
  return useMutation(updates => messagesClient.update(updates.id, updates), {
    onSettled: () => {
      queryCache.invalidateQueries('messages');
    },
    ...defaultMutationOptions,
    ...options,
  });
}

function useMessages(
  query,
  timeFrame,
  startDateTime,
  endDateTime,
  messageBodyFlag,
  lastTaskId,
  page,
  opts,
) {
  const result = useQuery(
    getMessagesConfig(
      'messages',
      query,
      timeFrame,
      startDateTime,
      endDateTime,
      messageBodyFlag,
      lastTaskId,
      page,
      opts,
    ),
  );
  const messages = result.data ? result.data.messages : [];
  const totalMessageCnt = result.data ? result.data?.totalMessageCnt : null;
  return { ...result, messages, totalMessageCnt };
}

function useRemoveMessage(options = {}) {
  return useMutation(
    // eslint-disable-next-line camelcase
    ({ taskId, notification_Uid, currentTab }) => {
      if (currentTab === 'trash') {
        const data = {
          deleteInfoList: [
            {
              taskId,
              notificationUid: notification_Uid,
            },
          ],
        };

        return messagesClient.remove(data);
      }
      const data = {
        msgType: 'D',
        updtInfoList: [
          {
            taskId,
            notification_Uid,
          },
        ],
      };
      return messagesClient.removeMessages(data);
    },
    {
      onMutate: ({ page, currentTab, timeFrame, taskId }) => {
        const previousItems = queryCache.getQueryData([
          'messages',
          { query: currentTab, timeFrame, page },
        ]);

        queryCache.setQueryData(
          ['messages', { query: currentTab, timeFrame, page }],
          old => {
            const newMessages = old.messages.filter(
              item => item.taskId !== taskId,
            );
            return { ...old, messages: newMessages };
          },
        );

        return () =>
          queryCache.setQueryData(
            ['messages', { page, query: currentTab, timeFrame }],
            previousItems,
          );
      },
      onSettled: () => {
        queryCache.invalidateQueries('messages');
      },
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useRemoveMessages(options) {
  return useMutation(
    ({ updtInfoList, tab }) => {
      if (tab === 'trash') {
        const data = {
          deleteInfoList: updtInfoList.map(message => {
            return {
              taskId: message.taskId,
              notificationUid: message.notification_Uid,
            };
          }),
        };
        return messagesClient.remove(data);
      }
      const data = {
        msgType: 'D',
        updtInfoList,
      };
      return messagesClient.removeMessages(data);
    },
    {
      onMutate: ({ page, tab, timeFrame, updtInfoList }) => {
        const taskIdList = updtInfoList.map(m => m.taskId);
        const previousItems = queryCache.getQueryData([
          'messages',
          { query: tab, timeFrame, page },
        ]);

        queryCache.setQueryData(
          ['messages', { query: tab, timeFrame, page }],
          old => {
            const newMessages = old.messages.filter(
              item => !taskIdList.includes(item.taskId),
            );

            return { ...old, messages: newMessages };
          },
        );

        return () =>
          queryCache.setQueryData(
            ['messages', { page, query: tab, timeFrame }],
            previousItems,
          );
      },

      onSettled: () => {
        queryCache.invalidateQueries('messages');
      },
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useNewMessage(options) {
  return useMutation(
    ({ message, user, ...data }) => {
      let msg = message.split('\n').join('<br />');
      msg = `${msg} <br /> <br /> Sent from Chart Access by ${lowerCaseAllWordsExceptFirstLetters(
        user.name,
      )}. `;
      const rtf = htmlToRtf.convertHtmlToRtf(`<div>${msg}</div>`);

      const {
        selectedContacts,
        selectedCCContacts,
        selectedPatient = [],
        subject,
      } = data;

      const patientInfo =
        selectedPatient.length > 0
          ? {
              personId: selectedPatient[0].patientId,
            }
          : {};

      const poolInfo = {};
      const prsnlInfo = {};

      if (selectedCCContacts) {
        poolInfo.assignPools = selectedCCContacts
          .filter(contact => contact.groupFlag === 1)
          .map(cc => ({ poolId: cc.id, ccFlag: true }));
        prsnlInfo.assignPrsnl = selectedCCContacts
          .filter(contact => contact.groupFlag === 0)
          .map(cc => ({ prsnlId: cc.personId, ccFlag: true }));
      }

      if (selectedContacts) {
        poolInfo.assignPools = [
          ...poolInfo.assignPools,
          ...selectedContacts
            .filter(contact => contact.groupFlag === 1)
            .map(cc => ({ poolId: cc.id, ccFlag: false })),
        ];
        prsnlInfo.assignPrsnl = [
          ...prsnlInfo.assignPrsnl,
          ...selectedContacts
            .filter(contact => contact.groupFlag === 0)
            .map(to => ({ prsnlId: to.personId, ccFlag: false })),
        ];
      }

      const newData = {
        ...patientInfo,
        ...prsnlInfo,
        ...poolInfo,
        saveToChartFlag: false,
        priorityFlag: false,
        subject,
        msgBody: btoa(rtf),
        htmlFlag: false,
        messageType: 'N',
      };

      return messagesClient.create(newData);
    },
    {
      onSettled: () => {
        queryCache.invalidateQueries(['messages', { query: 'sent' }]);
      },
      ...defaultMutationOptions,
      ...options,
    },
  );
}

async function onNewMessageMutation(data) {
  const queryKey = ['messages', { query: 'sent' }];
  const previousItems = queryCache.getQueryData(queryKey);
  // queryCache.setQueryData(queryKey, (old = []) => [
  //   {
  //     msgBody: data.message,
  //   },
  //   ...old,
  // ]);
  // return recover function
  return () => queryCache.setQueryData(queryKey, previousItems);
}

function useReplyMessage(options) {
  return useMutation(
    ({ message, user, ...data }) => {
      let msg = message.split('\n').join('<br />');
      msg = `${msg} <br /> sent from Chart Access by ${user.name}. `;
      const rtf = htmlToRtf.convertHtmlToRtf(`<div>${msg}</div>`);

      const {
        selectedContacts,
        selectedCCContacts,
        selectedPatient,
        subject,
        notificationUid,
      } = data;

      const patientInfo =
        selectedPatient.length > 0
          ? {
              personId: selectedPatient[0].personId,
            }
          : {};

      const poolInfo = {};
      const prsnlInfo = {};

      if (selectedCCContacts) {
        poolInfo.assignPools = selectedCCContacts
          .filter(contact => contact.groupFlag === 1)
          .map(cc => ({ poolId: cc.id, ccFlag: true }));
        prsnlInfo.assignPrsnl = selectedCCContacts
          .filter(contact => contact.groupFlag === 0)
          .map(cc => ({ prsnlId: cc.id || cc.personId, ccFlag: true }));
      }

      if (selectedContacts) {
        poolInfo.assignPools = [
          ...poolInfo.assignPools,
          ...selectedContacts
            .filter(contact => contact.groupFlag === 1)
            .map(cc => ({ poolId: cc.id, ccFlag: false })),
        ];
        prsnlInfo.assignPrsnl = [
          ...prsnlInfo.assignPrsnl,
          ...selectedContacts
            .filter(contact => contact.groupFlag === 0)
            .map(to => ({ prsnlId: to.id || to.personId, ccFlag: false })),
        ];
      }

      const newData = {
        ...patientInfo,
        ...prsnlInfo,
        ...poolInfo,
        saveToChartFlag: false,
        priorityFlag: false,
        subject,
        msgBody: btoa(rtf),
        htmlFlag: false,
        messageType: 'R',
        notificationUid,
        includePreviousMsgBodyFlag: true,
      };
      return messagesClient.create(newData);
    },
    {
      onSettled: () => {
        queryCache.invalidateQueries(['messages', { query: 'sent' }]);
      },
      onMutate: onNewMessageMutation,
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useForwardMessage(options) {
  return useMutation(
    ({ message, user, ...data }) => {
      let msg = message.split('\n').join('<br />');
      msg = `${msg} <br /> sent from Chart Access by ${user.name}. `;
      const rtf = htmlToRtf.convertHtmlToRtf(`<div>${msg}</div>`);

      const {
        selectedContacts,
        selectedCCContacts,
        selectedPatient,
        subject,
        notificationUid,
      } = data;

      const patientInfo = selectedPatient
        ? {
            personId: selectedPatient[0].personId,
          }
        : {};

      const poolInfo = {};
      const prsnlInfo = {};

      if (selectedCCContacts) {
        poolInfo.assignPools = selectedCCContacts
          .filter(contact => contact.groupFlag === 1)
          .map(cc => ({ poolId: cc.id, ccFlag: true }));
        prsnlInfo.assignPrsnl = selectedCCContacts
          .filter(contact => contact.groupFlag === 0)
          .map(cc => ({ prsnlId: cc.id || cc.personId, ccFlag: true }));
      }

      if (selectedContacts) {
        poolInfo.assignPools = [
          ...poolInfo.assignPools,
          ...selectedContacts
            .filter(contact => contact.groupFlag === 1)
            .map(cc => ({ poolId: cc.id, ccFlag: false })),
        ];
        prsnlInfo.assignPrsnl = [
          ...prsnlInfo.assignPrsnl,
          ...selectedContacts
            .filter(contact => contact.groupFlag === 0)
            .map(to => ({ prsnlId: to.id || to.personId, ccFlag: false })),
        ];
      }

      const newData = {
        ...patientInfo,
        ...prsnlInfo,
        ...poolInfo,
        saveToChartFlag: false,
        priorityFlag: false,
        subject,
        msgBody: btoa(rtf),
        htmlFlag: false,
        messageType: 'F',
        notificationUid,
        includePreviousMsgBodyFlag: true,
      };
      return messagesClient.create(newData);
    },
    {
      onSettled: () => {
        queryCache.invalidateQueries(['messages', { query: 'sent' }]);
      },
      onMutate: onNewMessageMutation,
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useRestoreMessages(options) {
  return useMutation(
    ({ updtInfoList }) => {
      const data = {
        msgType: 'R',
        updtInfoList,
      };
      return messagesClient.restoreMessages(data);
    },
    {
      onMutate: ({ updtInfoList, tab, timeFrame, page }) => {
        const taskIdList = updtInfoList.map(m => m.taskId);
        const previousItems = queryCache.getQueryData([
          'messages',
          { query: tab, timeFrame, page },
        ]);

        queryCache.setQueryData(
          ['messages', { query: tab, timeFrame, page }],
          old => {
            const newMessages = old.messages.filter(
              item => !taskIdList.includes(item.taskId),
            );

            return { ...old, messages: newMessages };
          },
        );

        return () =>
          queryCache.setQueryData(
            ['messages', { page, query: tab, timeFrame }],
            previousItems,
          );
      },
      onSettled: () => {
        queryCache.invalidateQueries('messages');
      },
      ...defaultMutationOptions,
      ...options,
    },
  );
}

export {
  useMessages,
  useUpdateMessage,
  useRemoveMessage,
  useRemoveMessages,
  useRestoreMessage,
  useNewMessage,
  useRestoreMessages,
  useReplyMessage,
  useForwardMessage,
};
