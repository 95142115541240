import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      '&:hover': {
        background: '#f5f5f5',
      },
    },
  }),
);

const StyledSelect = withStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
  },
  select: {
    paddingRight: '0',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}))(Select);

interface TimeFrameSelect {
  onSelect: (timeFrame: number) => void;
  loadAll?: boolean;
}

const TimeFrameSelect = ({ onSelect, loadAll }: TimeFrameSelect) => {
  const { formControl } = useStyles();

  const [dateRange, setDateRange] = useState('30');

  return (
    <FormControl className={formControl}>
      <StyledSelect
        id="time-frame-select"
        disableUnderline
        IconComponent={ExpandMoreIcon}
        value={dateRange}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          const timeFrame = event.target.value;
          setDateRange(timeFrame as string);
          onSelect(timeFrame as number);
        }}
      >
        <MenuItem value={7}>Last 7 days</MenuItem>
        <MenuItem value={30}>Last 30 days</MenuItem>
        <MenuItem value={60}>Last 60 days</MenuItem>
        <MenuItem value={90}>Last 90 days</MenuItem>
        {loadAll && <MenuItem value={0}>Load All</MenuItem>}
      </StyledSelect>
    </FormControl>
  );
};

export default TimeFrameSelect;
