import { useRef, useCallback, useState, useEffect } from 'react';
import { queryCache } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { client } from 'utils/api-client';
import { useAudit, useDeleteAudit } from 'utils/audit';

function useGetLatest(obj): Function {
  const ref = useRef();
  ref.current = obj;

  return useCallback(() => ref.current, []);
}

function AsyncDebounce(defaultFn, defaultWait = 0) {
  const debounceRef = useRef<any>({});

  const getDefaultFn = useGetLatest(defaultFn);
  const getDefaultWait = useGetLatest(defaultWait);

  return useCallback(
    async (...args) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          debounceRef.current.resolve(await getDefaultFn()(...args));
        } catch (err) {
          debounceRef.current.reject(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait],
  );
}

async function prefetch({
  endpoint,
  query,
  result,
  data,
  cb,
}: {
  endpoint: string;
  query: string;
  result: string;
  data?: any;
  cb?: Function;
}): Promise<void> {
  await queryCache.prefetchQuery(query, () => {
    return client(endpoint, data).then(res =>
      cb ? cb(res) : res[result ?? query],
    );
  });
}

function useRedirect(data) {
  const [match, setMatch] = useState<any>(null);
  const routeMatch = useRouteMatch({ ...data });

  useEffect(() => {
    setMatch(routeMatch);
  }, [data.path]);

  return match;
}

function useUnloadSignOut() {
  const [audit] = useAudit();
  const [deleteAudit] = useDeleteAudit();

  function unload(e) {
    if (window.sessionStorage) {
      window.sessionStorage.setItem('reload', 'true');
      audit({
        eventType: 'UI:SignOut',
        details: {
          uiScreen: 'User Sign Out Tab Closed',
        },
      });
    }
    return null;
  }

  function load() {
    if (window.sessionStorage) {
      if (window.sessionStorage.getItem('reload') === 'true') {
        // deleteAudit();
      }
    }
  }

  useEffect(() => {
    load();
    window.onbeforeunload = unload;
    return () => {
      window.removeEventListener('beforeunload', unload);
    };
  }, []);
}

export { AsyncDebounce, prefetch, useRedirect, useUnloadSignOut };
