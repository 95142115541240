/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

type Severity = {
  code: string;
  display: string;
};

export interface Allergy {
  reactions: any;
  description: string;
  severity: Severity | null;
}

const allergiesConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

export interface AllergiesInputJson {
  patientId?: string;
  personId: number;
}

export interface AllergiesClientRequestItem {
  data: AllergiesInputJson;
  name?: string;
  path: string;
  token?: any;
  method?: any;
  headers?: {} | undefined;
  testUrl?: any;
}

const getAllergiesConfig = (
  queryKey,
  { path, data }: AllergiesClientRequestItem,
) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);

    if (
      !response.ok &&
      !response.allergies &&
      response.allergies.length === 0
    ) {
      throw new Error('Unable to retrieve data from EMR');
    }

    return response.allergies;
  },
  config: { ...allergiesConfig },
});

function useAllergies(data: AllergiesInputJson): QueryResult<Array<Allergy>> {
  const apiPath = `patient/${data.personId}/Allergies`;
  const requestItem: AllergiesClientRequestItem = {
    data,
    path: apiPath,
  };

  return useQuery<Array<Allergy>>(getAllergiesConfig('allergies', requestItem));
}

export { useAllergies };
