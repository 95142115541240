import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Theme, withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function upperCaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
// match any character that is not a whitespace kind
export function lowerCaseAllWordsExceptFirstLetters(string) {
  return string.replace(/\S*/g, word => {
    return word.charAt(0) + word.slice(1).toLowerCase();
  });
}

function getTitle(name: string, option: string) {
  const resutName = upperCaseFirstLetter(
    lowerCaseAllWordsExceptFirstLetters(name),
  );
  if (option === 'Other') {
    return `Confirm ${resutName} is no longer at your facility?`;
  }
  if (option === 'Deceased') {
    return `Confirm ${resutName} deceased?`;
  }
  return `Confirm ${resutName} was ${option.toLowerCase()}?`;
}

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
  },
}))(MuiDialogActions);

export default function ConfirmDialog({
  open,
  setOpen,
  onConfirm,
  option,
  name,
}: {
  open: boolean;
  setOpen: (state: boolean) => void;
  onConfirm: () => void;
  option: string;
  name: string;
}) {
  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    onConfirm();
    setOpen(false);
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          {getTitle(name, option)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The patient will be removed from your worklist.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
