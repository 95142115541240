/* eslint-disable no-nested-ternary */
/** @jsx jsx */
/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
import React, { useState, useRef } from 'react';
import { jsx, css } from '@emotion/core';
import Downshift from 'downshift';
import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ListMui from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/HighlightOffSharp';
import Button from '@material-ui/core/Button';
import { useContactsSearch } from 'utils/contacts';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const gridStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
    minHeight: 350,
  },
  searchGrid: {
    '& > *': {
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(2.5),
    },
  },
}));

const TitleTypography = withStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))(props => <Typography {...props} />);

function AdvancedRecipientSearch(props) {
  const input = useRef();
  const classes = gridStyles();
  const [selectedValue, setSelectedValue] = useState('personnel');
  const { selectedContacts, setSelectedContacts, handleClose } = props;
  const handleContactsSourceChange = event =>
    setSelectedValue(event.target.value);

  const handleChange = (selectedContact, downshift) => {
    const contact = {
      ...selectedContact,
      groupFlag: selectedValue === 'personnel' ? 0 : 1,
    };
    setSelectedContacts(selectedContacts => [
      ...selectedContacts,
      contact,
    ]);
    downshift.reset();
  };

  const itemToString = i => (i ? i.name : '');

  const handleInputKeyDown = ({
    event,
    isOpen,
    selectHighlightedItem,
    highlightedIndex,
    reset,
    inputValue,
  }) => {
    if (isOpen && ['Tab', ',', ';'].includes(event.key)) {
      event.preventDefault();
      if (highlightedIndex != null) {
        selectHighlightedItem();
      } else {
        setSelectedContacts(selectedContacts => [
          ...selectedContacts,
          {
            id: inputValue.toLowerCase(),
            email: inputValue,
            name: inputValue,
          },
        ]);
        reset();
      }
    }
  };

  function removeContact(contact) {
    setSelectedContacts(selectedContacts =>
      selectedContacts.filter(c => c !== contact),
    );
    input.current.focus();
  }

  return (
    <Downshift
      itemToString={itemToString}
      selectedItem={null}
      onChange={handleChange}
      defaultHighlightedIndex={0}
    >
      {({
        getLabelProps,
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        toggleMenu,
        clearSelection,
        highlightedIndex,
        selectHighlightedItem,
        setHighlightedIndex,
        reset,
        inputValue,
        clearItems,
        setItemCount,
      }) => (
        <div style={{ maxWidth: '600' }}>
          <Box my={3}>
            <Grid container>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Radio
                    checked={selectedValue === 'personnel'}
                    onChange={handleContactsSourceChange}
                    value="personnel"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'Personnel' }}
                    label="Personnel"
                  />
                  <Typography>Personnel</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Radio
                    checked={selectedValue === 'pool'}
                    onChange={handleContactsSourceChange}
                    value="pool"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'Pool' }}
                    label="Pool"
                  />
                  <Typography>Pool</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <label {...getLabelProps({ style: { display: 'none' } })}>
                Select your recipients
              </label>
              <div
                css={css({
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  paddingRight: 10,
                })}
              >
                <TextField
                  label="Name"
                  id="outlined-start-adornment"
                  className={`${classes.margin} ${classes.textField}`}
                  variant="outlined"
                  {...getInputProps({
                    ref: input,
                    onKeyDown: event =>
                      handleInputKeyDown({
                        event,
                        selectHighlightedItem,
                        highlightedIndex,
                        isOpen,
                        reset,
                        inputValue,
                      }),
                    placeholder: 'Enter recipient',
                    css: css({
                      border: 'none',
                      fontSize: 16,
                      marginBottom: 15,
                      flex: 1,
                      paddingTop: 10,
                      paddingBottom: 10,
                      outline: 'none',
                      minWidth: '100',
                      zIndex: 1,
                    }),
                  })}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.searchGrid}>
            <Grid item container xs={7}>
              <Grid item xs={6}>
                <TitleTypography variant="body2">Name</TitleTypography>
              </Grid>
              <Grid item>
                <TitleTypography variant="body2">Position</TitleTypography>
              </Grid>
            </Grid>
            <Grid item>
              <TitleTypography variant="body2">Send To</TitleTypography>
            </Grid>
          </Grid>
          <Box pt={1}>
            <Grid container spacing={2}>
              <Grid item container xs={7}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    {!isOpen ? null : (
                      <ul
                        {...getMenuProps({ style: { padding: 0, margin: 0 } })}
                      >
                        {inputValue.length >= 3 && (
                          <FetchContacts
                            searchValue={inputValue}
                            omitContacts={selectedContacts}
                            selectedValue={selectedValue}
                            onLoaded={({ contacts }) => {
                              clearItems();
                              if (contacts) {
                                setHighlightedIndex(contacts.length ? 0 : null);
                                setItemCount(contacts.length);
                              }
                            }}
                          >
                            {({ loading, contacts, error }) => (
                              <div
                                css={css({
                                  height: 350,
                                  overflow: 'scroll',
                                })}
                              >
                                {loading ? (
                                  <div css={css({ padding: 10 })}>
                                    <Typography>loading...</Typography>
                                  </div>
                                ) : error ? (
                                  <div css={css({ padding: 10 })}>error...</div>
                                ) : contacts.length ? (
                                  <ContactList
                                    highlightedIndex={highlightedIndex}
                                    getItemProps={getItemProps}
                                    contacts={contacts}
                                  />
                                ) : (
                                  <div css={css({ padding: 10 })}>
                                    no results...
                                  </div>
                                )}
                              </div>
                            )}
                          </FetchContacts>
                        )}
                      </ul>
                    )}
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Paper className={classes.paper}>
                  <div className={classes.demo}>
                    <ListMui>
                      {selectedContacts.map(contact => (
                        <ListItem key={contact.personId || contact.id}>
                          <ListItemText
                            primary={contact.name || contact.nameFullFormatted}
                            secondary={
                              (contact.position && contact.position.display)
                                ? contact.position.display
                                : null
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="remove"
                              onClick={() => removeContact(contact)}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </ListMui>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item>
                <OutlinedButtons
                  handleClose={() => {
                    setSelectedContacts([]);
                    handleClose();
                  }}
                  handleOk={handleClose}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </Downshift>
  );
}

function ContactList({ highlightedIndex, getItemProps, contacts }) {
  const rowHeight = 65;
  const fullHeight = contacts.length * rowHeight;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          scrollToIndex={highlightedIndex || 0}
          height={height}
          rowCount={contacts.length}
          rowHeight={rowHeight}
          rowRenderer={({ key, index, style }) => (
            <li
              key={contacts[index].personId}
              {...getItemProps({
                item: contacts[index],
                index,
                style,
                css: css({
                  cursor: 'pointer',
                  padding: 10,
                  backgroundColor:
                    highlightedIndex === index ? '#F7F7F9' : '#FFFFFF',
                }),
              })}
            >
              <div css={{ display: 'flex' }}>
                <div css={{ flex: '1 1' }}>
                  <Typography>{contacts[index].nameFullFormatted || contacts[index].name }</Typography>
                  <div css={css({ marginLeft: 2 })}>
                    <Typography>
                      {contacts[index].position ? contacts[index].position.display : contacts[index].name }
                    </Typography>
                  </div>
                </div>
                <div css={{ flex: '1 1' }}>
                  <Typography>{ contacts[index].position ? contacts[index].position.display : contacts[index].name }</Typography>
                </div>
              </div>
            </li>
          )}
        />
      )}
    </AutoSizer>
  );
}

function FetchContacts({ limit, searchValue, selectedValue, children }) {
  const { contacts = [], error, isFetching: loading } = useContactsSearch(
    searchValue,
    selectedValue,
  );
  return children({ contacts, loading, error });
}

const useBtnStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function OutlinedButtons(props) {
  const classes = useBtnStyles();
  const { handleClose, handleOk } = props;
  return (
    <div className={classes.root}>
      <Button
        disabled={false}
        variant="contained"
        color="secondary"
        onClick={handleOk}
      >
        Ok
      </Button>
      <Button
        disabled={false}
        variant="outlined"
        color="secondary"
        onClick={handleClose}
      >
        Cancel
      </Button>
    </div>
  );
}

export default AdvancedRecipientSearch;
