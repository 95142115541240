/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult, useMutation, queryCache } from 'react-query';
import { client } from './api-client';

export interface Form {
  description: string;
  onSetDate: string;
  onSetDateDisplay: string;
}

const getFormConfig = (queryKey, Dob, FormType, Gender) => ({
  queryKey: [queryKey],
  queryFn: () =>
    client('formConfig/GetByFilters', {
      data: { Dob, FormType, Gender },
    }).then(data => data),
});

function useForm({ Dob, FormType, Gender }): QueryResult<any> {
  return useQuery<any>(getFormConfig('form', Dob, FormType, Gender));
}

const defaultMutationOptions = {
  onError: (err, variables, recover) =>
    typeof recover === 'function' ? recover() : null,
  onSettled: () => {
    queryCache.invalidateQueries('workList');
    queryCache.invalidateQueries('form-progress');
  },
};

function useSaveForLater(options = {}) {
  return useMutation(
    (values: any) =>
      client('forms/form-progress', {
        data: values,
      }),
    {
      ...defaultMutationOptions,
      ...options,
    },
  );
}

const getInProgressConfig = (
  queryKey,
  { formRefId, patientId, encounterId, ...options },
) => ({
  queryKey: [queryKey, { patientId, encounterId }],
  queryFn: () =>
    client(
      `forms/form-progress?formRefId=${formRefId}&patientId=${patientId}&encounterId=${encounterId}`,
    ).then(data => data),
  config: {
    onError: () => {
      queryCache.setQueryData(
        ['form-progress', { patientId, encounterId }],
        () => ({}),
      );
    },
    ...options,
  },
});

function useInprogressForm({
  formRefId,
  patientId,
  encounterId,
  ...options
}): QueryResult<any> {
  return useQuery<any>(
    getInProgressConfig('form-progress', {
      formRefId,
      patientId,
      encounterId,
      ...options,
    }),
  );
}

// IMPLEMENT mutation fuction for optimistic rendering and recover

function useSignForm(options = {}) {
  return useMutation(
    (values: any) =>
      client('forms/sign', {
        data: values,
      }),
    {
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useEditForm(options = {}) {
  return useMutation(
    (values: any) =>
      client('forms', {
        data: values,
        method: 'PUT',
      }),
    {
      ...defaultMutationOptions,
      ...options,
    },
  );
}

function useRemoveForm(options = {}) {
  return useMutation(
    (values: any) =>
      client('forms', {
        data: values,
        method: 'DELETE',
      } as any),
    {
      onMutate: ({ patientId, encounterId }) => {
        const previous = queryCache.getQueryData([
          'form-progress',
          { patientId, encounterId },
        ]);
        queryCache.setQueryData(
          ['form-progress', { patientId, encounterId }],
          () => ({}),
        );
        return () => queryCache.setQueryData('form-progress', previous);
      },
      ...defaultMutationOptions,
      ...options,
    },
  );
}

export {
  useForm,
  useSaveForLater,
  useInprogressForm,
  useSignForm,
  useEditForm,
  useRemoveForm,
};
