/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

type display = {
  display: string;
};

export interface Diagnostic {
  resultName: display;
  dtTm: string;
  status: display;
  id: number;
}

const diagnosticsConfig = {
  cacheTime: 1000 * 60 * 4,
  staleTime: 1000 * 15,
};

type Encounter = {
  id: number;
};

interface diagnosticsInputJson {
  personId: number;
  encounters?: Encounter[];
}

const getDiagnosticsConfig = (queryKey, data: diagnosticsInputJson) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(`Patient/${data.personId}/DiagnosticReports`);

    if (
      !response.ok &&
      !response.diagnosticReports &&
      response.diagnosticReports.length === 0
    ) {
      throw new Error('Unable to retrieve data from EMR');
    }

    return response.diagnosticReports;
  },
  config: { ...diagnosticsConfig },
});

function useDiagnosticReports(
  data: diagnosticsInputJson,
): QueryResult<Array<Diagnostic>> {
  return useQuery<Array<Diagnostic>>(getDiagnosticsConfig('diagnostics', data));
}

interface documentInputJson {
  eventId: number | null;
}
const getDiagnosticReportConfig = (queryKey, data: documentInputJson) => ({
  queryKey: [queryKey, { eventId: data.eventId }],
  queryFn: () =>
    client(`DiagnosticReports/${data.eventId}`).then(d => d.diagnosticReport),
  config: { ...diagnosticsConfig, enabled: !!data.eventId },
});

type DiagnosticReport = {
  fullDocument: string;
  reportId: number;
  reportName: string;
  reportDtTm: string;
  performedBy: string;
  reportStatus: { display: string };
  encounter: any;
};

function useDiagnosticReport(
  data: documentInputJson,
): QueryResult<DiagnosticReport> {
  return useQuery<DiagnosticReport>(
    getDiagnosticReportConfig('diagnostic', data),
  );
}

export { useDiagnosticReports, useDiagnosticReport };
