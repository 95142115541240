/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { useReactToPrint } from 'react-to-print';

export default function ScrollDialog(props: {
  open?: boolean | undefined;
  defaultOpen?: boolean;
  onChange?: Function;
  children: string | JSX.Element;
  title?: string;
  isLoading?: boolean;
  printCb?: Function;
}) {
  const {
    children,
    defaultOpen,
    onChange = () => {},
    title,
    isLoading,
    printCb = () => {},
  } = props;
  const printRef = useRef();

  const handlePrint = useReactToPrint({ content: () => printRef.current! });

  function print() {
    handlePrint!();
    printCb();
  }

  const [openState, setOpenState] = React.useState<boolean>(
    defaultOpen || false,
  );
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  function isControlled() {
    return props.open != null;
  }

  const open = isControlled() ? props.open : openState;

  const handleClose = () => {
    isControlled() ? onChange(false) : setOpenState(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open!}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
        >
          <div>{title}</div>
          {!isLoading && (
            <IconButton
              color="primary"
              aria-label="print html"
              component="span"
              onClick={print}
            >
              <PrintOutlinedIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent ref={printRef} dividers={scroll === 'paper'}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
