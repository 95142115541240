/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

type display = {
  display: string;
};

export interface Vital {
  orderedMnemonic: string;
  resultName: display;
  resultValue: string;
  resultDate: string;
  resultUnit: display;
  normalcy: display;
}

interface CodeDisplay {
  code: number;
  display: string;
}

interface VitalsInputJson {
  personId: number;
}

interface VitalsClientRequestItem {
  path: String;
  data: VitalsInputJson;
}

const vitalsConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

const getVitalsConfig = (queryKey, { path, data }, opts) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);
    if (!response.ok && !response.vitalSigns && response.vitalSigns.length === 0) {
      throw new Error('Unable to retrieve data from EMR');
    }
    return response.vitalSigns;
  },
  config: { ...vitalsConfig, ...opts },
});

function useVitals(
  data: VitalsInputJson,
  opts = {},
): QueryResult<Array<Vital>> {
  const requestItem: VitalsClientRequestItem = {
    data,
    path: `patient/${data.personId}/VitalSigns`,
  };
  return useQuery<Array<Vital>>(getVitalsConfig('vitals', requestItem, opts));
}
export { useVitals };
