import React from 'react';
import Chip from '@material-ui/core/Chip';

function Recipient({ children, onRemove }) {
  return (
    <Chip
      variant="outlined"
      color="primary"
      size="small"
      onDelete={onRemove}
      label={children}
    />
  );
}

export default Recipient;
