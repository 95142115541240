/** @jsx jsx */
/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { jsx, css } from '@emotion/core';
import Downshift from 'downshift';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { usePatientsSearch } from 'utils/patients';
import { formatDateMessage } from 'utils/dateFormat';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const gridStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    minHeight: 350,
  },
  searchGrid: {
    '& > *': {
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(2.5),
    },
  },
}));

const TitleTypography = withStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))(props => <Typography {...props} />);

function AdvancedRecipientSearch(props) {
  const input = useRef();
  const classes = gridStyles();
  const [selectedValue, setSelectedValue] = useState('Name');
  const { selectedContacts, setSelectedContacts, handleClose } = props;

  const handleContactsSourceChange = event =>
    setSelectedValue(event.target.value);

  const handleChange = (selectedContact, downshift) => {
    setSelectedContacts(selectedContacts => [
      ...selectedContacts,
      selectedContact,
    ]);
    downshift.reset();
    handleClose();
  };

  const itemToString = i => {
    // eslint-disable-next-line no-nested-ternary
    return i ? (i.name === i.email ? i.name : `${i.name} (${i.email})`) : '';
  };

  const handleInputKeyDown = ({
    event,
    isOpen,
    selectHighlightedItem,
    highlightedIndex,
    reset,
    inputValue,
  }) => {
    if (isOpen && ['Tab', ',', ';'].includes(event.key)) {
      event.preventDefault();
      if (highlightedIndex != null) {
        selectHighlightedItem();
      } else {
        setSelectedContacts(selectedContacts => [
          ...selectedContacts,
          {
            id: inputValue.toLowerCase(),
            email: inputValue,
            name: inputValue,
          },
        ]);
        reset();
      }
    }
  };

  return (
    <Downshift
      itemToString={itemToString}
      selectedItem={null}
      onChange={handleChange}
      defaultHighlightedIndex={0}
    >
      {({
        getLabelProps,
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        toggleMenu,
        clearSelection,
        highlightedIndex,
        selectHighlightedItem,
        setHighlightedIndex,
        reset,
        inputValue,
        clearItems,
        setItemCount,
      }) => (
        <div>
          <Box my={3}>
            <Grid container alignItems="center">
              <Radio
                checked={selectedValue === 'Name'}
                onChange={handleContactsSourceChange}
                value="Name"
                name="name"
                inputProps={{ 'aria-label': 'Name' }}
                label="Name"
              />
              <Typography>Name</Typography>
              <Radio
                checked={selectedValue === 'Mrn'}
                onChange={handleContactsSourceChange}
                value="Mrn"
                name="mrn"
                inputProps={{ 'aria-label': 'MRN' }}
                label="MRN"
              />
              <Typography>MRN</Typography>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <label {...getLabelProps({ style: { display: 'none' } })}>
                Select your patient
              </label>
              <div
                css={css({
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  paddingRight: 10,
                })}
              >
                <TextField
                  label={selectedValue === 'Name' ? 'Name' : 'MRN'}
                  id="outlined-start-adornment"
                  className={`${classes.margin} ${classes.textField}`}
                  variant="outlined"
                  {...getInputProps({
                    ref: input,
                    onKeyDown: event =>
                      handleInputKeyDown({
                        event,
                        selectHighlightedItem,
                        highlightedIndex,
                        isOpen,
                        reset,
                        inputValue,
                      }),
                    placeholder: 'Enter at least 3 characters',
                    css: css({
                      border: 'none',
                      fontSize: 16,
                      marginTop: 15,
                      marginBottom: 15,
                      flex: 1,
                      paddingBottom: 10,
                      outline: 'none',
                      minWidth: '100',
                      zIndex: 1,
                    }),
                  })}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ minWidth: 950, justifyContent: 'center' }}>
            <Grid item xs={12}>
              <Grid container className={classes.searchGrid}>
                <Grid item xs={3}>
                  <TitleTypography variant="body2">Name</TitleTypography>
                </Grid>
                <Grid item xs={3}>
                  <TitleTypography variant="body2">DOB</TitleTypography>
                </Grid>
                <Grid item xs={3}>
                  <TitleTypography variant="body2">Gender</TitleTypography>
                </Grid>
                <Grid item xs={3}>
                  <TitleTypography variant="body2">MRN</TitleTypography>
                </Grid>
              </Grid>
              <Paper className={classes.paper}>
                {!isOpen ? null : (
                  <ul {...getMenuProps({ style: { padding: 0, margin: 0 } })}>
                    {inputValue.length >= 3 && (
                      <FetchPatients
                        searchValue={inputValue}
                        keyValue={selectedValue}
                        omitContacts={selectedContacts}
                        onLoaded={({ patients }) => {
                          clearItems();
                          if (patients) {
                            setHighlightedIndex(patients.length ? 0 : null);
                            setItemCount(patients.length);
                          }
                        }}
                      >
                        {({ loading, patients, error }) => (
                          <div
                            css={css({
                              height: '100%',
                              overflow: 'scroll',
                            })}
                          >
                            {loading ? (
                              <div css={css({ padding: 10 })}>
                                <Typography>Loading...</Typography>
                              </div>
                            ) : error ? (
                              <div css={css({ padding: 10 })}>error...</div>
                            ) : patients.length ? (
                              <ContactList
                                highlightedIndex={highlightedIndex}
                                getItemProps={getItemProps}
                                contacts={patients}
                              />
                            ) : (
                              <div css={css({ padding: 10 })}>
                                <Typography>No Results...</Typography>
                              </div>
                            )}
                          </div>
                        )}
                      </FetchPatients>
                    )}
                  </ul>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <OutlinedButtons
                handleClose={() => {
                  setSelectedContacts([]);
                  handleClose();
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </Downshift>
  );
}

function ContactList({
  highlightedIndex,
  getItemProps,
  contacts,
  parentWidth,
}) {
  const rowHeight = 55;
  const fullHeight = contacts.length * rowHeight;
  return (
    <div style={{ display: 'flex', height: 350 }}>
      <AutoSizer style={{ flex: '1 1 auto' }}>
        {({ height, width }) => (
          <List
            width={width}
            scrollToIndex={highlightedIndex || 0}
            height={height}
            rowCount={contacts.length}
            rowHeight={rowHeight}
            rowRenderer={({ key, index, style }) => (
              <li
                key={contacts[index].id}
                {...getItemProps({
                  item: contacts[index],
                  index,
                  style,
                  css: css({
                    cursor: 'pointer',
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 10,
                    backgroundColor:
                      highlightedIndex === index ? '#F7F7F9' : 'FFFFFF',
                  }),
                })}
              >
                <div css={{ display: 'flex' }}>
                  <div css={{ flex: '1 1' }}>
                    <Typography>{contacts[index].name}</Typography>
                  </div>
                  <div css={{ flex: '1 1' }}>
                    <Typography>
                      {' '}
                      {formatDateMessage(contacts[index].dob)}
                    </Typography>
                  </div>
                  <div css={{ flex: '1 1' }}>
                    <Typography>{contacts[index].gender}</Typography>
                  </div>
                  <div css={{ flex: '1 1' }}>
                    <Typography>{contacts[index].mrn}</Typography>
                  </div>
                </div>
              </li>
            )}
          />
        )}
      </AutoSizer>
    </div>
  );
}

function FetchPatients({ limit, searchValue, keyValue, children }) {
  const {
    patients = [],
    error,
    isFetching: loading,
  } = usePatientsSearch(searchValue, keyValue);

  return children({ patients, loading, error });
}

const useBtnStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function OutlinedButtons(props) {
  const classes = useBtnStyles();
  const { handleClose } = props;
  return (
    <div className={classes.root}>
      <Button disabled={false} variant="contained" color="secondary">
        Ok
      </Button>
      <Button
        disabled={false}
        variant="outlined"
        color="secondary"
        onClick={handleClose}
      >
        Cancel
      </Button>
    </div>
  );
}

export default AdvancedRecipientSearch;
