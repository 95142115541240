import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { formatDateMessage } from 'utils/dateFormat';
import { messageType } from 'components/PatientMessages';

const useEmailStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    list: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& > ul > li': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  };
});

interface ReceivedMessageProps {
  currentMessage: messageType;
  currentTab: number;
}

function getSender({ sender }: messageType): string | null {
  if (Boolean(sender.groupId) && Boolean(sender.prsnlId)) {
    return `${sender.prsnlName} (${sender.groupName})`;
  }
  if (sender.prsnlId) {
    return sender.prsnlName;
  }
  if (sender.groupId) {
    return sender.groupName;
  }
  return null;
}

function ReceivedMessage(props: ReceivedMessageProps) {
  const {
    currentMessage: { dtTm = null, subject = null },
    currentMessage,
    currentTab,
  } = props;
  const emailClasses = useEmailStyles();

  return (
    <div className={emailClasses.list}>
      <List>
        <ListItem>
          <Box mr={2}>
            <Typography variant="subtitle2">
              {currentTab === 1 ? 'Received date:' : 'Sent date'}
            </Typography>
          </Box>
          <ListItemText>
            <Typography variant="body2">{formatDateMessage(dtTm)}</Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <Box mr={2}>
            <Typography variant="subtitle2">From:</Typography>
          </Box>
          <ListItemText>
            <Typography variant="body2">{getSender(currentMessage)}</Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <Box mr={2}>
            <Typography variant="subtitle2">To:</Typography>
          </Box>
          <ListItemText>
            <Typography variant="body2">
              {currentMessage?.toAssign.map(o => o.name).join(', ')}
            </Typography>
          </ListItemText>
        </ListItem>

        {currentMessage.ccAssign.length !== 0 && (
          <ListItem>
            <Box mr={2}>
              <Typography variant="subtitle2">CC:</Typography>
            </Box>
            <ListItemText>
              <Typography variant="body2">
                {currentMessage.ccAssign.map(o => o.name).join(', ')}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        <ListItem>
          <Box mr={2}>
            <Typography variant="subtitle2">Subject:</Typography>
          </Box>
          <ListItemText>
            <Typography variant="body2">{subject}</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
}

export default ReceivedMessage;
