/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

type display = {
  display: string;
};

export interface Immunization {
  immunizationName: display;
  date: string;
}

interface CodeDisplay {
  code: number;
  display: string;
}

interface ImmunizationInputJson {
  personId: number;
}

interface ImmunizationClientRequestItem {
  path: String;
  data: ImmunizationInputJson;
}

const immunizatiosConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

const getImmunizationsConfig = (
  queryKey,
  { path, data }: ImmunizationClientRequestItem,
) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);
    if (!response.ok && !response.immunizations && response.immunizations.length === 0) {
      throw new Error('Unable to retrieve data from EMR');
    }
    return response.immunizations;
  },
  config: { ...immunizatiosConfig },
});

function useImmunizations(
  data: ImmunizationInputJson,
): QueryResult<Array<Immunization>> {
  const requestItem: ImmunizationClientRequestItem = {
    data,
    path: `patient/${data.personId}/Immunizations`,
  };
  return useQuery<Array<Immunization>>(
    getImmunizationsConfig('immunizations', requestItem),
  );
}

export { useImmunizations };
