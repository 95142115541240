/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useReducer } from 'react';
import {
  makeStyles,
  Theme,
  styled,
  withStyles,
} from '@material-ui/core/styles';
import { queryCache } from 'react-query';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import Close from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InboxIcon from '@material-ui/icons/Inbox';
import Grid from '@material-ui/core/Grid';
import StarEmpty from '@material-ui/icons/StarBorderRounded';
import StarFilled from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CircularProgress from '@material-ui/core/CircularProgress';
import SvgIcon from '@material-ui/core/SvgIcon';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import unescape from 'lodash/unescape';
import NavigateNext from '@material-ui/icons/NavigateNextRounded';
import NavigatePrevious from '@material-ui/icons/NavigateBeforeRounded';
import { useAuth } from 'context/auth-context';
import { formatDateMessage } from 'utils/dateFormat';
import {
  useMessages,
  useUpdateMessage,
  useRemoveMessage,
  useRemoveMessages,
  useRestoreMessages,
} from 'utils/messages';
import * as messagesClient from 'utils/messages-client';
import { useAudit } from 'utils/audit';
import ComposeMessage from './ComposeMessage';
import ReplyMessage from './ReplyMessage';
import ReplyAllMessage from './ReplyAllMessage';
import PatientMessageInfo from './PatientMessageInfo';
import ReceivedMessage from './ReceivedMessage';
import WarningModal from './WarningModal';
import ForwardMessage from './ForwardMessage';
import SortableTableHead from './SortableTableHead';
import TimeFrameSelect from './TimeFrameSelect';

const starBorderIconStyles = makeStyles(theme => ({
  primary: (props: any) => ({
    color: props.selected
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
  }),
  secondary: (props: any) => ({
    color: props.selected
      ? theme.palette.primary.contrastText
      : theme.palette.action.disabled,
  }),
}));

function getCurrentTab(tab: number): string {
  const mapTabs = ['disabled', 'inbox', 'sent', 'trash'];
  return mapTabs[tab];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style?: any;
}

interface Sender {
  groupId: number;
  groupName: string;
  position: { code: number; display: string };
  prsnlId: number;
  prsnlName: string;
}

type MsgType = 'INBOX' | 'SENT' | 'TRASH';

export interface messageType {
  dtTm: string;
  sender: Sender;
  toAssign: Array<any>;
  subject: any;
  patient: any;
  priorityFlag: boolean;
  id: any;
  msgBody: string;
  followUpFlag: boolean;
  openFlag: boolean;
  render?: boolean;
  msgBodyHtml?: any;
  taskId: number;
  notificationUid?: any;
  ccAssign: Array<any>;
  msgType: MsgType;
}

function isCurrentTabSent(tab: number): boolean {
  return getCurrentTab(tab) === 'sent';
}

function isCurrentTabTrash(tab: number): boolean {
  return getCurrentTab(tab) === 'trash';
}

function isMessageSent(message: messageType): boolean {
  return message.msgType === 'SENT';
}
const useTabPanelStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    overflow: 'scroll',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const styles = useTabPanelStyles(props);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={styles.root}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: 'calc(100% - 70px)',
  },
  tabs: (props: any) => ({
    flexBasis: props.matches ? 190 : 250,
    minWidth: 190,
    borderRight: `1px solid ${theme.palette.divider}`,
  }),
  indicator: {
    display: 'none',
  },
  compose: {
    height: 50,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  collapse: {
    width: '100%',
    flexFlow: 'column',
    padding: theme.spacing(2),
  },
  container: {
    maxWidth: 750,
    flex: 1,
  },
  form: (props: any) => ({
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flex: 1.5,
    },
  }),
  message: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
  alert: {
    justifyContent: 'center',
    background: '#EFEFF3',
    borderRadius: 4,
    padding: 30,
    marginTop: 40,
    marginLeft: 30,
    marginRight: 30,
  },
}));

function handleCheckboxClick(e: any) {
  e.stopPropagation();
}

const handleFollowUp = (
  e: any,
  mutate: Function,
  message: messageType,
  timeFrame: number,
  page: number,
  query: string,
  lastTaskId: number,
) => {
  e.stopPropagation();
  mutate({
    followUpFlag: !message.followUpFlag,
    query,
    timeFrame,
    page,
    lastTaskId,
  });
};

function outputTemplate(doc, defaults, content) {
  return `
  ${content.replace(/<p[^>]*>/g, '<p style="white-space: pre-line">')}
`;
}

// eslint-disable-next-line consistent-return
function getSender(message: messageType): string | void {
  if (Boolean(message.sender.groupId) && Boolean(message.sender.prsnlId)) {
    return `${message.sender.prsnlName} (${message.sender.groupName})`;
  }
  if (Boolean(message.sender.prsnlId)) {
    return message.sender.prsnlName;
  }
  if (Boolean(message.sender.groupId)) {
    return message.sender.groupName;
  }
}

const isStrictEqual = (a, b) => a === b;

const isSelected = (
  message: messageType,
  currentMessage: messageType,
  collapsed: any,
) => Boolean(message.taskId === currentMessage.taskId && collapsed);

function renderTabNames(message: messageType, currentTab: number) {
  return (
    <>
      {isStrictEqual(getCurrentTab(currentTab), 'inbox') && getSender(message)}
      {isStrictEqual(getCurrentTab(currentTab), 'sent') &&
        message.toAssign.map(o => o.name).join(', ')}
      {isStrictEqual(getCurrentTab(currentTab), 'trash') &&
        (message.sender.prsnlName ||
          message.toAssign.map(o => o.name).join(', '))}
    </>
  );
}

function MessageStarFilled(props) {
  const starStyles = starBorderIconStyles(props);
  return <StarFilled className={starStyles.primary} {...props} />;
}

function MessageStarEmpty(props) {
  const starStyles = starBorderIconStyles(props);
  return <StarEmpty className={starStyles.secondary} {...props} />;
}

const PriorityIconStyled = styled(PriorityHighIcon)(
  ({ theme, selected }: any) => ({
    color: selected
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
  }),
);

const MessageTableCell = styled(TableCell)(
  ({ theme, selected, openFlag }: any) => ({
    color: selected
      ? theme.palette.primary.contrastText
      : openFlag
      ? theme.palette.action.disabled
      : 'inherit',
  }),
);

const MessageTableRow = styled(TableRow)(
  ({ theme, selected, openFlag }: any) => ({
    backgroundColor: openFlag && !selected ? '#F7F7F9' : 'inherit',
    cursor: 'pointer',
  }),
);

type CellTypography = {
  openFlag: Boolean;
  selected: Boolean;
};

const useCellTypographyStyles = makeStyles(theme => ({
  root: (props: CellTypography) => ({
    fontWeight: props.openFlag
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  }),
}));

function TypographyCell(props) {
  const styles = useCellTypographyStyles(props);
  return <Typography className={styles.root} {...props} />;
}

interface RenderTableRow {
  messages: messageType[];
  setDeleteCount: Function;
  setCollapsed: Function;
  setCurrentMessage: Function;
  mutate: Function;
  currentMessage: messageType;
  collapsed: boolean | string;
  selectAll: boolean;
  replyForm: any;
  setWarningModal: Function;
  exposeReplyForm: Function;
  currentTab: number;
  order: string;
  orderBy: string;
  query?: string;
  timeFrame: number;
  page: number;
  lastTaskId: number;
  audit: Function;
}

const RenderTableRow = React.forwardRef<HTMLButtonElement[], RenderTableRow>(
  (props: RenderTableRow, ref: any) => {
    const {
      messages,
      setDeleteCount,
      setCollapsed,
      setCurrentMessage,
      mutate,
      currentMessage,
      collapsed,
      selectAll,
      replyForm,
      setWarningModal,
      exposeReplyForm,
      currentTab,
      order,
      orderBy,
      query,
      timeFrame,
      page,
      lastTaskId,
      audit,
    } = props;
    const isSent = isCurrentTabSent(currentTab);

    return tableSort(messages, getComparatorFunction(order, orderBy)).map(
      (message, idx) => {
        const sel = isSelected(message, currentMessage, collapsed);
        return (
          <MessageTableRow
            key={message.taskId}
            hover
            selected={isSelected(message, currentMessage, collapsed)}
            openFlag={isSent ? true : message.openFlag}
            onClick={async e => {
              if (e.currentTarget.tagName === 'TR') {
                if (replyForm.pristine === false) {
                  setWarningModal(true);
                } else if (message.taskId === currentMessage.taskId) {
                  setCollapsed('reset');
                  setCurrentMessage({});
                } else if (
                  getCurrentTab(currentTab) !== 'sent' &&
                  !message.openFlag
                ) {
                  const updtInfoList = [
                    {
                      taskId: message.taskId,
                      notification_Uid: message.notificationUid,
                    },
                  ];
                  const updates = {
                    openFlag: true,
                    query,
                    timeFrame,
                    page,
                    lastTaskId,
                    updtInfoList,
                    taskId: message.taskId,
                  };
                  mutate(updates);
                  setCollapsed('message');
                  setCurrentMessage({ ...message, ...updates });
                } else {
                  audit({
                    eventType: 'Messages:Open:Message',
                    details: {
                      uiScreen: 'Messages Open Message',
                      eventId: String(message.taskId),
                    },
                  });
                  setCollapsed('message');
                  setCurrentMessage({ ...message });
                }
              }
            }}
          >
            {!isSent && (
              <MessageTableCell padding="checkbox">
                <Field name="deleteMultiple" type="checkbox" value={message}>
                  {({ input }) => {
                    return (
                      <Checkbox
                        checked={input.checked}
                        color="primary"
                        // eslint-disable-next-line no-param-reassign
                        inputRef={el => {
                          if (ref !== null) ref.current[idx] = el;
                        }}
                        onClick={e => handleCheckboxClick(e)}
                        onChange={e => {
                          e.persist();
                          setDeleteCount((prev: any) => {
                            if (e.target.checked) {
                              return prev + 1;
                            }
                            if (prev && !e.target.checked) {
                              return prev - 1;
                            }
                            return prev;
                          });
                          input.onChange(e);
                        }}
                      />
                    );
                  }}
                </Field>
              </MessageTableCell>
            )}
            <MessageTableCell
              selected={isSelected(message, currentMessage, collapsed)}
              openFlag={isSent ? true : message.openFlag}
            >
              <TypographyCell
                variant="body2"
                openFlag={isSent ? true : message.openFlag}
              >
                {formatDateMessage(message.dtTm)}
              </TypographyCell>
            </MessageTableCell>
            <MessageTableCell
              selected={isSelected(message, currentMessage, collapsed)}
              openFlag={isSent ? true : message.openFlag}
            >
              <TypographyCell
                variant="body2"
                openFlag={isSent ? true : message.openFlag}
              >
                {renderTabNames(message, currentTab)}
              </TypographyCell>
            </MessageTableCell>
            <MessageTableCell
              selected={isSelected(message, currentMessage, collapsed)}
              openFlag={isSent ? true : message.openFlag}
            >
              <TypographyCell
                variant="body2"
                openFlag={isSent ? true : message.openFlag}
              >
                {message.subject}
              </TypographyCell>
            </MessageTableCell>
            <MessageTableCell
              selected={isSelected(message, currentMessage, collapsed)}
              openFlag={isSent ? true : message.openFlag}
            >
              <TypographyCell
                variant="body2"
                openFlag={isSent ? true : message.openFlag}
              >
                {message.patient ? message.patient.name : ''}
              </TypographyCell>
            </MessageTableCell>
            <MessageTableCell>
              {message.priorityFlag && <PriorityIconStyled selected={sel} />}
            </MessageTableCell>
          </MessageTableRow>
        );
      },
    );
  },
);

const onSubmit =
  (
    removeMultiple: Function,
    restoreMultiple: Function,
    tab: string,
    timeFrame: number | void,
    page: number | void,
  ) =>
  async (values: any) => {
    const ids = values.deleteMultiple.map(message => message.taskId);
    const updtInfoList = values.deleteMultiple.map(message => {
      return {
        taskId: message.taskId,
        notification_Uid: message.notificationUid,
      };
    });
    const data = { ids, updtInfoList, tab, page, timeFrame };
    if (values.action === 'delete') {
      return removeMultiple(data);
    }
    return restoreMultiple(data);
  };

function MessagesTable(props: any) {
  const { children } = props;
  return <Table>{children}</Table>;
}

interface State {
  collapsed: boolean | string;
}

type Action =
  | { type: 'message' }
  | { type: 'newMessage' }
  | { type: 'replyMessage' }
  | { type: 'replyAllMessage' }
  | { type: 'forwardMessage' }
  | { type: 'reset' };

function reducer(state, action: Action): State {
  switch (action.type) {
    case 'message':
      return { collapsed: 'message' };
    case 'newMessage':
      return { collapsed: 'newMessage' };
    case 'replyMessage':
      return { collapsed: 'replyMessage' };
    case 'replyAllMessage':
      return { collapsed: 'replyAllMessage' };
    case 'forwardMessage':
      return { collapsed: 'forwardMessage' };
    case 'reset':
      return { collapsed: false };
    default:
      throw new Error();
  }
}

type TimeAction = {
  type: string;
  data: { timeFrame: number };
};

interface TimeState {
  inboxTimeFrame: number;
  inboxStartDateTime: string;
  inboxEndDateTime: string;
  sentTimeFrame: number;
  sentStartDateTime: string;
  sentEndDateTime: string;
  trashTimeFrame: number;
  trashStartDateTime: string;
  trashEndDateTime: string;
}

function getStartDate(timeFrame): string {
  if (timeFrame === 0) {
    return timeFrame.toString();
  }

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - timeFrame);
  return currentDate.toISOString();
}

function getEndDate(timeFrame): string {
  if (timeFrame === 0) {
    return timeFrame.toString();
  }
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toISOString();
}

function timeReducer(state: TimeState, action: TimeAction): TimeState {
  switch (action.type) {
    case 'inbox':
      return {
        ...state,
        inboxTimeFrame: action.data.timeFrame,
        inboxStartDateTime: getStartDate(action.data.timeFrame),
        inboxEndDateTime: getEndDate(action.data.timeFrame),
      };
    case 'sent':
      return {
        ...state,
        sentTimeFrame: action.data.timeFrame,
        sentStartDateTime: getStartDate(action.data.timeFrame),
        sentEndDateTime: getEndDate(action.data.timeFrame),
      };
    case 'trash':
      return {
        ...state,
        trashTimeFrame: action.data.timeFrame,
        trashStartDateTime: getStartDate(action.data.timeFrame),
        trashEndDateTime: getEndDate(action.data.timeFrame),
      };
    default:
      throw new Error('no action match time reducer');
  }
}

const MessageTab = withStyles(theme => ({
  root: {
    [theme.breakpoints.down(960)]: {
      marginRight: theme.spacing(2),
    },
    marginRight: theme.spacing(2.5),
    display: 'flex',
    minWidth: 140,
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexBasis: '55%',
    '& > :first-child': {
      paddingRight: theme.spacing(2),
    },
    justifyContent: 'left',
  },
  labelIcon: {
    '& .MuiTab-wrapper > *:first-child': {
      marginBottom: 0,
    },
    minHeight: 50,
    color: theme.palette.action.disabled,
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
  },
}))((props: any) => <Tab disableRipple {...props} />);

function descendingComparator(row1, row2, orderBy) {
  let val1;
  let val2;
  switch (orderBy) {
    case 'starred':
      val1 = row1.followUpFlag;
      val2 = row2.followUpFlag;
      break;

    case 'date':
      val1 = new Date(row1.dtTm);
      val2 = new Date(row2.dtTm);
      break;

    case 'from':
      val1 = getSender(row1);
      val2 = getSender(row2);
      break;

    case 'to':
      val1 = row1.toAssign.map(o => o.name).join(', ');
      val2 = row2.toAssign.map(o => o.name).join(', ');
      break;

    case 'from/to':
      val1 = getSender(row1) || row1.toAssign.map(o => o.name).join(', ');
      val2 = getSender(row2) || row2.toAssign.map(o => o.name).join(', ');
      break;

    case 'subject':
      val1 = row1.subject;
      val2 = row2.subject;
      break;

    case 'patient':
      val1 = row1.patient ? row1.patient.name : '';
      val2 = row2.patient ? row2.patient.name : '';
      break;

    case 'priority':
      val1 = row1.priorityFlag;
      val2 = row2.priorityFlag;
      break;

    default:
      // handle checked message (a value that isn't held in the patient messages data)
      return 0;
  }

  if (val2 < val1) {
    return -1;
  }
  if (val2 > val1) {
    return 1;
  }
  return 0;
}

function getComparatorFunction(order, orderBy) {
  return order === 'desc'
    ? (row1, row2) => descendingComparator(row1, row2, orderBy)
    : (row1, row2) => -descendingComparator(row1, row2, orderBy);
}

function tableSort(items, comparator) {
  const indexedItems = items.map((item, index) => [item, index]);
  indexedItems.sort((row1, row2) => {
    const order = comparator(row1[0], row2[0]);
    if (order !== 0) return order;
    return row1[1] - row2[1];
  });
  return indexedItems.map(item => item[0]);
}

const inboxHeadCells = [
  { id: 'checked', label: '' },
  { id: 'date', label: 'Date' },
  { id: 'from', label: 'From' },
  { id: 'subject', label: 'Subject' },
  { id: 'patient', label: 'Patient' },
  { id: 'priority', label: 'Priority' },
];

const sentHeadCells = [
  { id: 'date', label: 'Date' },
  { id: 'to', label: 'To' },
  { id: 'subject', label: 'Subject' },
  { id: 'patient', label: 'Patient' },
  { id: 'priority', label: 'Priority' },
];

const trashHeadCells = [
  { id: 'checked', label: '' },
  { id: 'date', label: 'Date' },
  { id: 'from/to', label: 'From / To' },
  { id: 'subject', label: 'Subject' },
  { id: 'patient', label: 'Patient' },
  { id: 'priority', label: 'Priority' },
];

export function LoadingSpinner() {
  return (
    <Container>
      <Box
        component="div"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
}

interface Pagination {
  page: number;
  setPage: any;
  hasMore: boolean;
  isPreviousData: boolean;
  data: any;
  totalPages?: number;
  totalMessageCnt: number | null;
  isLoading?: boolean;
  timeFrame: number;
  query: string;
}

function Pagination({
  page,
  setPage,
  isPreviousData,
  data,
  totalMessageCnt,
  isLoading,
  timeFrame,
  query,
  hasMore,
}: Pagination) {
  const lastMessage = data.slice(-1)?.pop();

  const calcPage = page === 1 ? 0 : page - 1;
  const totalPages = totalMessageCnt
    ? Math.ceil(totalMessageCnt / 10) + calcPage
    : 0;

  function getTaskIdFromPage(page) {
    if (page === 1) {
      return 0;
    }

    const prev: any = queryCache.getQueryData([
      'messages',
      {
        query,
        timeFrame,
        page,
      },
    ]);
    if (prev) {
      const lastMessage = prev.messages.slice(-1)?.pop();
      return lastMessage.taskId;
    }

    return 0;
  }

  return (
    <Box m={1} display="flex" justifyContent="flex-end" alignItems="center">
      <Box p={2}>
        {Boolean(totalMessageCnt) && (
          <IconButton
            type="button"
            aria-label="previous page"
            onClick={e =>
              setPage(oldPagination => ({
                page: Math.max(oldPagination.page - 1, 0),
                taskId: getTaskIdFromPage(Math.max(oldPagination.page - 1, 0)),
              }))
            }
            disabled={page === 1}
          >
            <NavigatePrevious />
          </IconButton>
        )}
      </Box>
      <Box>
        <Typography>
          {!isLoading && !isPreviousData && Boolean(totalMessageCnt) ? (
            `page ${page} of ${totalPages}`
          ) : isLoading || isPreviousData ? (
            <Typography>loading...</Typography>
          ) : null}
        </Typography>
      </Box>

      <Box p={2}>
        {Boolean(totalMessageCnt) && (
          <IconButton
            aria-label="next page"
            type="button"
            onClick={() => {
              setPage(oldPagination =>
                hasMore
                  ? {
                      page: oldPagination.page + 1,
                      taskId: lastMessage.taskId,
                    }
                  : oldPagination,
              );
            }}
            disabled={isPreviousData || !hasMore}
          >
            <NavigateNext />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

function getHasMore(page, total, maxRow) {
  if (total) {
    const calcPage = page === 1 ? 0 : page - 1;
    const totalPages = total ? Math.ceil(total / maxRow) + calcPage : 0;

    if (page < totalPages) {
      return true;
    }
    return false;
  }
  return false;
}

function PatientMessages() {
  const [currentTab, setValue] = useState(1);
  const [deleteMsg, setDeleteMsg] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [{ collapsed }, dispatch] = useReducer(reducer, { collapsed: false });
  const [currentMessage, setCurrentMessage] = useState({} as messageType);
  const [replyForm, exposeReplyForm] = useState({} as any);
  const [warning, setWarningModal] = useState(false);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date');
  const msgRef = useRef<number>();
  const matches = useMediaQuery('(max-width:960px)');

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const defaultEndDate = currentDate.toISOString();
  const defaultTimeFrame = 30;
  currentDate.setDate(currentDate.getDate() - defaultTimeFrame);
  const defaultStartDate = currentDate.toISOString();

  const [timeState, dispatchTime] = useReducer(timeReducer, {
    inboxTimeFrame: defaultTimeFrame,
    inboxStartDateTime: defaultStartDate,
    inboxEndDateTime: defaultEndDate,
    sentTimeFrame: defaultTimeFrame,
    sentStartDateTime: defaultStartDate,
    sentEndDateTime: defaultEndDate,
    trashTimeFrame: defaultTimeFrame,
    trashStartDateTime: defaultStartDate,
    trashEndDateTime: defaultEndDate,
  });

  const [audit] = useAudit();
  const { user } = useAuth();

  function setCollapsed(type) {
    dispatch({ type });
  }

  useEffect(() => {
    if (currentMessage.taskId && !currentMessage.render) {
      msgRef.current = currentMessage.taskId;
      const createMarkup = encodedHtml => {
        const result = encodedHtml
          .replace(new RegExp(' />', 'g'), '>')
          .split('<br>')
          .join('\n');
        return {
          __html: unescape(result),
        };
      };

      try {
        setCurrentMessage({
          ...currentMessage,
          msgBodyHtml: createMarkup(currentMessage.msgBody),
          render: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    }
  }, [currentMessage]);

  const [inboxPagination, setInboxPagination] = useState<any>({
    page: 1,
    taskId: 0,
  });

  const [sentPagination, setSentPagination] = useState<any>({
    page: 1,
    taskId: 0,
  });

  const [trashPagination, setTrashPagination] = useState<any>({
    page: 1,
    taskId: 0,
  });

  const {
    status,
    messages,
    isPreviousData,
    isLoading: isFetchingInbox,
    totalMessageCnt: totalMessageCntInbox,
  } = useMessages(
    'inbox',
    timeState.inboxTimeFrame,
    timeState.inboxStartDateTime,
    timeState.inboxEndDateTime,
    true,
    inboxPagination.taskId,
    inboxPagination.page,
    { keepPreviousData: true, staleTime: 10000 },
  );

  const hasMore = getHasMore(inboxPagination.page, totalMessageCntInbox, 10);

  function getCurrentTimeFrame(tab) {
    switch (tab) {
      case 'inbox':
        return timeState.inboxTimeFrame;
      case 'sent':
        return timeState.sentTimeFrame;
      case 'trash':
        return timeState.trashTimeFrame;
      default:
        break;
    }
  }

  function getCurrentPage(tab) {
    switch (tab) {
      case 'inbox':
        return inboxPagination.page;
      case 'sent':
        return sentPagination.page;
      case 'trash':
        return trashPagination.page;
      default:
        break;
    }
  }

  // Prefetch the next page!
  React.useEffect(() => {
    if (hasMore && !isPreviousData) {
      const lastMessage = messages.slice(-1)?.pop();
      queryCache.prefetchQuery(
        [
          'messages',
          {
            query: 'inbox',
            timeFrame: timeState.inboxTimeFrame,
            page: inboxPagination.page + 1,
          },
        ],
        () =>
          messagesClient
            .all({
              query: 'inbox',
              startDateTime: timeState.inboxStartDateTime,
              endDateTime: timeState.inboxEndDateTime,
              messageBodyFlag: true,
              lastTaskId: lastMessage.taskId,
            })
            .then(data => data),
      );
    }
  }, [messages, inboxPagination, hasMore]);

  const {
    status: sentStatus,
    messages: sentMessages,
    isLoading: isFetchingSent,
    isPreviousData: isSentPreviousData,
    totalMessageCnt: totalMessageCntSent,
  } = useMessages(
    'sent',
    timeState.sentTimeFrame,
    timeState.sentStartDateTime,
    timeState.sentEndDateTime,
    true,
    sentPagination.taskId,
    sentPagination.page,
    { keepPreviousData: true, staleTime: 10000 },
  );

  const sentHasMore = getHasMore(sentPagination.page, totalMessageCntSent, 10);

  // Prefetch the next page!
  React.useEffect(() => {
    if (sentHasMore && !isSentPreviousData) {
      const lastMessage = sentMessages.slice(-1)?.pop();

      queryCache.prefetchQuery(
        [
          'messages',
          {
            query: 'sent',
            timeFrame: timeState.sentTimeFrame,
            page: sentPagination.page + 1,
          },
        ],
        () =>
          messagesClient
            .all({
              query: 'sent',
              startDateTime: timeState.sentStartDateTime,
              endDateTime: timeState.sentEndDateTime,
              messageBodyFlag: true,
              lastTaskId: lastMessage.taskId,
            })
            .then(data => data),
      );
    }
  }, [sentMessages, sentPagination, sentHasMore]);

  const {
    status: trashStatus,
    messages: trashMessages,
    isLoading: isFetchingTrash,
    isPreviousData: isTrashPreviousData,
    totalMessageCnt: totalMessageCntTrash,
  } = useMessages(
    'trash',
    timeState.trashTimeFrame,
    timeState.trashStartDateTime,
    timeState.trashEndDateTime,
    true,
    trashPagination.taskId,
    trashPagination.page,
    { keepPreviousData: true, staleTime: 10000 },
  );

  const trashHasMore = getHasMore(
    trashPagination.page,
    totalMessageCntTrash,
    10,
  );

  // Prefetch
  React.useEffect(() => {
    if (trashHasMore && !isPreviousData) {
      const lastMessage = trashMessages.slice(-1)?.pop();
      queryCache.prefetchQuery(
        [
          'messages',
          {
            query: 'trash',
            timeFrame: timeState.trashTimeFrame,
            lastTaskId: lastMessage.taskId,
          },
        ],
        () =>
          messagesClient
            .all({
              query: 'trash',
              startDateTime: timeState.trashStartDateTime,
              endDateTime: timeState.trashEndDateTime,
              messageBodyFlag: true,
              lastTaskId: lastMessage.taskId,
            })
            .then(data => data),
      );
    }
  }, [trashMessages, trashPagination, trashHasMore]);

  const [handleRemoveClick, { status: removeStatus }] = useRemoveMessage();
  const [mutate] = useUpdateMessage();
  const classes = useStyles({ collapsed, matches });
  const checkboxBoxRef = React.useRef<HTMLButtonElement[]>([]);

  const [removeMultiple, { status: removeMultipleStatus }] =
    useRemoveMessages();
  const [restoreMultiple, { status: restoreMultipleStatus }] =
    useRestoreMessages();

  const deleting = removeStatus === 'loading';
  const deletingMultiple = removeMultipleStatus === 'loading';
  const restoringMultiple = restoreMultipleStatus === 'loading';

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const initial = { page: 1, taskId: 0 };
    switch (getCurrentTab(newValue)) {
      case 'inbox':
        if (inboxPagination.page !== 1) {
          setInboxPagination(initial);
        }
      case 'sent':
        if (sentPagination.page !== 1) {
          setSentPagination(initial);
        }
      case 'trash':
        if (trashPagination.page !== 1) {
          setTrashPagination(initial);
        }
      default:
        break;
    }
    setValue(newValue);
  };

  const handleCloseWarning = () => setWarningModal(false);

  const handleRequestSort = (e, column) => {
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const handleSelectAll = async e => {
    e.preventDefault();
    e.stopPropagation();

    // eslint-disable-next-line no-restricted-syntax
    for (const child of checkboxBoxRef.current) {
      if (child) {
        if (
          !selectAll &&
          !child.parentElement?.parentElement?.classList.contains('Mui-checked')
        ) {
          child.click();
        } else if (
          selectAll &&
          deleteMsg &&
          child.parentElement?.parentElement?.classList.contains('Mui-checked')
        ) {
          child.click();
        } else if (!deleteMsg) {
          child.click();
        }
      }
      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => setTimeout(resolve, 10));
    }
    setSelectAll(!selectAll);
  };

  function onSelectTimeFrame(timeFrame: number) {
    dispatchTime({ type: getCurrentTab(currentTab), data: { timeFrame } });
    setInboxPagination({ taskId: 0, page: 1 });
  }

  function onSelectSentTimeFrame(timeFrame: number) {
    dispatchTime({ type: getCurrentTab(currentTab), data: { timeFrame } });
    setSentPagination({ page: 1, taskId: 0 });
  }

  function onSelectTrashTimeFrame(timeFrame: number) {
    dispatchTime({ type: getCurrentTab(currentTab), data: { timeFrame } });
    setTrashPagination({ taskId: 0, page: 1 });
  }

  return (
    <>
      <Toolbar style={{ minHeight: 70 }} />
      <Toolbar style={{ minHeight: 70 }} />
      <div className={classes.root}>
        <Form
          onSubmit={onSubmit(
            removeMultiple,
            restoreMultiple,
            getCurrentTab(currentTab),
            getCurrentTimeFrame(getCurrentTab(currentTab)),
            getCurrentPage(getCurrentTab(currentTab)),
          )}
        >
          {({ handleSubmit, form }: any) => (
            <form
              className={classes.form}
              onSubmit={async e => {
                await handleSubmit(e);
                setDeleteMsg(0);
                setCollapsed('reset');
                form.reset();
              }}
            >
              <div className={classes.root}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={currentTab}
                  onChange={handleChange}
                  aria-label="Side menu"
                  className={classes.tabs}
                  classes={{ indicator: classes.indicator }}
                  style={{
                    display: 'flex',
                  }}
                >
                  {collapsed !== 'newMessage' ? (
                    <Button
                      className={classes.compose}
                      startIcon={<AddCircleIcon color="disabled" />}
                      onClick={() => setCollapsed('newMessage')}
                      variant="text"
                    >
                      Compose New
                    </Button>
                  ) : (
                    <div />
                  )}
                  <MessageTab
                    icon={<InboxIcon />}
                    label="Inbox"
                    {...a11yProps(1)}
                  />
                  <MessageTab
                    icon={<SendIcon />}
                    label="Sent"
                    {...a11yProps(2)}
                  />
                  <MessageTab
                    icon={<DeleteIcon />}
                    label="Trash"
                    {...a11yProps(3)}
                  />
                  {Boolean(deleteMsg) && (
                    <>
                      <Box display="flex" justifyContent="center" m={2}>
                        <Button
                          size="large"
                          variant="outlined"
                          disabled={deletingMultiple}
                          color="secondary"
                          type="submit"
                          onClick={() => {
                            form.change('action', 'delete');
                            if (selectAll) setSelectAll(!deleteMsg);
                          }}
                        >
                          {deletingMultiple
                            ? 'Deleting'
                            : getCurrentTab(currentTab) === 'trash'
                            ? 'Delete Forever'
                            : 'Delete Selected'}
                        </Button>
                      </Box>
                      {getCurrentTab(currentTab) === 'trash' && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          m={2}
                          mt={1}
                        >
                          <Button
                            size="large"
                            variant="outlined"
                            disabled={restoringMultiple}
                            color="secondary"
                            type="submit"
                            onClick={() => {
                              form.change('action', 'restore');
                            }}
                          >
                            {restoringMultiple
                              ? 'Restoring'
                              : 'Restore Selected'}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </Tabs>

                {matches && collapsed ? null : (
                  <TabPanel value={currentTab} index={1}>
                    <Box px={2}>
                      <Button
                        size="large"
                        variant="outlined"
                        color="secondary"
                        onClick={async e => handleSelectAll(e)}
                      >
                        {selectAll && Boolean(deleteMsg)
                          ? 'Unselect All'
                          : 'Select All'}
                      </Button>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <TimeFrameSelect onSelect={onSelectTimeFrame} loadAll />
                        <Pagination
                          data={messages}
                          hasMore={hasMore}
                          isPreviousData={isPreviousData}
                          page={inboxPagination.page}
                          setPage={setInboxPagination}
                          totalMessageCnt={totalMessageCntInbox}
                          isLoading={isFetchingInbox}
                          query="inbox"
                          timeFrame={timeState.inboxTimeFrame}
                        />
                      </Box>

                      <TableContainer>
                        <MessagesTable>
                          <SortableTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={inboxHeadCells}
                          />
                          <TableBody>
                            {status === 'success' && (
                              <RenderTableRow
                                messages={messages}
                                setDeleteCount={setDeleteMsg}
                                setCollapsed={setCollapsed}
                                setCurrentMessage={setCurrentMessage}
                                mutate={mutate}
                                currentMessage={currentMessage}
                                collapsed={collapsed}
                                selectAll={selectAll}
                                replyForm={replyForm}
                                setWarningModal={setWarningModal}
                                exposeReplyForm={exposeReplyForm}
                                currentTab={currentTab}
                                order={order}
                                orderBy={orderBy}
                                ref={checkboxBoxRef}
                                query="inbox"
                                timeFrame={timeState.inboxTimeFrame}
                                page={inboxPagination.page}
                                lastTaskId={inboxPagination.taskId}
                                audit={audit}
                              />
                            )}
                          </TableBody>
                        </MessagesTable>
                      </TableContainer>
                      {status === 'loading' && <LoadingSpinner />}
                      {messages.length === 0 && status !== 'loading' && (
                        <Box display="flex" className={classes.alert}>
                          <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                            No messages
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </TabPanel>
                )}
                <TabPanel value={currentTab} index={2}>
                  <Box px={4}>
                    <Button
                      size="large"
                      variant="outlined"
                      color="secondary"
                      onClick={async e => handleSelectAll(e)}
                    >
                      {selectAll && Boolean(deleteMsg)
                        ? 'Unselect All'
                        : 'Select All'}
                    </Button>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <TimeFrameSelect onSelect={onSelectSentTimeFrame} />
                      <Pagination
                        data={sentMessages}
                        hasMore={sentHasMore}
                        isPreviousData={isSentPreviousData}
                        page={sentPagination.page}
                        setPage={setSentPagination}
                        totalMessageCnt={totalMessageCntSent}
                        isLoading={isFetchingSent}
                        query="sent"
                        timeFrame={timeState.sentTimeFrame}
                      />
                    </Box>
                    <TableContainer>
                      <MessagesTable>
                        <SortableTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          headCells={sentHeadCells}
                        />
                        <TableBody>
                          {sentStatus === 'success' && (
                            <RenderTableRow
                              messages={sentMessages}
                              setDeleteCount={setDeleteMsg}
                              setCollapsed={setCollapsed}
                              setCurrentMessage={setCurrentMessage}
                              mutate={mutate}
                              currentMessage={currentMessage}
                              collapsed={collapsed}
                              selectAll={selectAll}
                              replyForm={replyForm}
                              setWarningModal={setWarningModal}
                              exposeReplyForm={exposeReplyForm}
                              currentTab={currentTab}
                              order={order}
                              orderBy={orderBy}
                              ref={checkboxBoxRef}
                              query="sent"
                              timeFrame={timeState.sentTimeFrame}
                              page={sentPagination.page}
                              lastTaskId={sentPagination.taskId}
                              audit={audit}
                            />
                          )}
                        </TableBody>
                      </MessagesTable>
                    </TableContainer>
                    {sentStatus === 'loading' && <LoadingSpinner />}
                    {sentMessages.length === 0 && sentStatus !== 'loading' && (
                      <Box display="flex" className={classes.alert}>
                        <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                          No messages
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value={currentTab} index={3}>
                  <Box px={4}>
                    <Button
                      size="large"
                      variant="outlined"
                      color="secondary"
                      onClick={async e => handleSelectAll(e)}
                    >
                      {selectAll && Boolean(deleteMsg)
                        ? 'Unselect All'
                        : 'Select All'}
                    </Button>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <TimeFrameSelect onSelect={onSelectTrashTimeFrame} />
                      <Pagination
                        data={trashMessages}
                        hasMore={trashHasMore}
                        isPreviousData={isTrashPreviousData}
                        page={trashPagination.page}
                        setPage={setTrashPagination}
                        totalMessageCnt={totalMessageCntTrash}
                        isLoading={isFetchingTrash}
                        query="trash"
                        timeFrame={timeState.trashTimeFrame}
                      />
                    </Box>
                    <TableContainer>
                      <MessagesTable>
                        <SortableTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          headCells={trashHeadCells}
                        />
                        <TableBody>
                          {trashStatus === 'success' && (
                            <RenderTableRow
                              messages={trashMessages}
                              setDeleteCount={setDeleteMsg}
                              setCollapsed={setCollapsed}
                              setCurrentMessage={setCurrentMessage}
                              mutate={mutate}
                              currentMessage={currentMessage}
                              collapsed={collapsed}
                              selectAll={selectAll}
                              replyForm={replyForm}
                              setWarningModal={setWarningModal}
                              exposeReplyForm={exposeReplyForm}
                              currentTab={currentTab}
                              order={order}
                              orderBy={orderBy}
                              ref={checkboxBoxRef}
                              query="trash"
                              timeFrame={timeState.trashTimeFrame}
                              page={trashPagination.page}
                              lastTaskId={trashPagination.taskId}
                              audit={audit}
                            />
                          )}
                        </TableBody>
                      </MessagesTable>
                    </TableContainer>
                    {trashStatus === 'loading' && <LoadingSpinner />}
                    {trashMessages.length === 0 && trashStatus !== 'loading' && (
                      <Box display="flex" className={classes.alert}>
                        <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                          No messages
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </TabPanel>
              </div>
            </form>
          )}
        </Form>
        {collapsed && (
          <div className={classes.container}>
            <div className={classes.root}>
              <Box py={4} display="flex" className={classes.collapse}>
                {
                  // eslint-disable-next-line wrap-iife
                  (function () {
                    if (currentMessage.taskId || collapsed === 'newMessage') {
                      switch (collapsed) {
                        case 'message':
                          return renderMessage();
                        case 'newMessage':
                          return (
                            <ComposeMessage
                              setEmailCollapsed={setCollapsed}
                              user={user}
                            />
                          );
                        case 'replyMessage':
                          return (
                            <ReplyMessage
                              exposeReplyForm={exposeReplyForm}
                              setCollapsed={setCollapsed}
                              currentMsg={currentMessage}
                              setCurrentMessage={setCurrentMessage}
                              currentTab={currentTab}
                              user={user}
                            />
                          );
                        case 'replyAllMessage':
                          return (
                            <ReplyAllMessage
                              exposeReplyForm={exposeReplyForm}
                              setCollapsed={setCollapsed}
                              currentMsg={currentMessage}
                              setCurrentMessage={setCurrentMessage}
                              currentTab={currentTab}
                              user={user}
                            />
                          );
                        case 'forwardMessage':
                          return (
                            <ForwardMessage
                              setCollapsed={setCollapsed}
                              currentMsg={currentMessage}
                              setCurrentMessage={setCurrentMessage}
                              exposeReplyForm={exposeReplyForm}
                              currentTab={currentTab}
                              user={user}
                            />
                          );
                        default:
                          return null;
                      }
                    }
                  })()
                }
              </Box>
            </div>
          </div>
        )}
      </div>
      <WarningModal
        open={warning}
        handleClose={handleCloseWarning}
        setCollapsed={setCollapsed}
        setCurrentMessage={setCurrentMessage}
        exposeReplyForm={exposeReplyForm}
      />
    </>
  );

  function renderMessage() {
    const isSent = isMessageSent(currentMessage);
    return (
      <>
        {!matches && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            pr={1.5}
          >
            <Close
              fontSize="small"
              color="disabled"
              onClick={() => {
                setCollapsed('reset');
                setCurrentMessage({} as messageType);
              }}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        )}
        <Grid item xs={12} style={{ overflow: 'scroll' }}>
          {matches && (
            <OutlinedButtons
              message={currentMessage}
              handleRemoveClick={handleRemoveClick}
              setCurrentMessage={setCurrentMessage}
              setCollapsed={setCollapsed}
              deleting={deleting}
              currentTab={currentTab}
              matches={matches}
              getCurrentPage={getCurrentPage}
              getCurrentTimeFrame={getCurrentTimeFrame}
            />
          )}
          <PatientMessageInfo currentMessage={currentMessage} />
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item>
              <ReceivedMessage
                currentMessage={currentMessage}
                currentTab={currentTab}
              />
            </Grid>
            {!matches && (
              <Grid item>
                <OutlinedButtons
                  message={currentMessage}
                  handleRemoveClick={handleRemoveClick}
                  setCurrentMessage={setCurrentMessage}
                  setCollapsed={setCollapsed}
                  deleting={deleting}
                  currentTab={currentTab}
                  getCurrentPage={getCurrentPage}
                  getCurrentTimeFrame={getCurrentTimeFrame}
                />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.message}>
              {currentMessage.render && (
                <Typography
                  variant="body2"
                  component="div"
                  className="content"
                  dangerouslySetInnerHTML={currentMessage.msgBodyHtml!}
                />
              )}
            </Grid>
            {getCurrentTab(currentTab) !== 'trash' && (
              <Grid item>
                <Box
                  color="action.disabled"
                  pl={3}
                  display="flex"
                  alignItems="center"
                >
                  {!isSent && (
                    <Box display="flex" alignItems="center">
                      <IconButton
                        aria-label="reply"
                        onClick={() => setCollapsed('replyMessage')}
                      >
                        <ReplyIcon color="disabled" />
                      </IconButton>
                      <Typography variant="body2">Reply</Typography>
                    </Box>
                  )}
                  <Box
                    ml={isSent ? 0 : 9}
                    display="flex"
                    alignItems="center"
                    color="action.disabled"
                  >
                    <IconButton
                      aria-label="forward"
                      onClick={() => setCollapsed('forwardMessage')}
                    >
                      <SvgIcon color="disabled">
                        <path
                          d="M19.8852 2.4H4.34281C3.80161 2.43011 3.29411 2.6724 2.9304 3.0743C2.5667 3.4762 2.37612 4.00529 2.40001 4.5468V17.4312C2.37612 17.9727 2.5667 18.5018 2.9304 18.9037C3.29411 19.3056 3.80161 19.5479 4.34281 19.578H13.08V17.4312H4.34281V6.6948L12.12 12.0636L19.8912 6.6948V12.0636H21.828V4.5468C21.8519 4.00529 21.6613 3.4762 21.2976 3.0743C20.9339 2.6724 20.4264 2.43011 19.8852 2.4ZM12.12 9.9156L4.34281 4.5468H19.8852L12.12 9.9156ZM18.9204 14.2104L22.8 18.5052L18.9144 22.8V19.5792H15.0288V17.4312H18.9144L18.9204 14.2104Z"
                          fill="#979DB0"
                        />
                      </SvgIcon>
                    </IconButton>
                    <Typography variant="body2">Forward</Typography>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

const useBtnStyles = makeStyles(theme => ({
  root: (props: any) => ({
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: props.matches ? 'flex-start' : 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  }),
}));

interface OutlinedButtonsProps {
  children?: React.ReactNode;
  handleRemoveClick: Function;
  message: messageType;
  setCurrentMessage: Function;
  setCollapsed: Function;
  deleting: boolean;
  currentTab: number;
  matches?: boolean;
  getCurrentPage: (tab: string) => number | void;
  getCurrentTimeFrame: (tab: string) => number | void;
}

function OutlinedButtons(props: OutlinedButtonsProps) {
  const classes = useBtnStyles({ matches: props.matches });
  const {
    handleRemoveClick,
    message,
    setCurrentMessage,
    setCollapsed,
    deleting,
    currentTab,
    getCurrentPage,
    getCurrentTimeFrame,
  } = props;

  const isSent = isMessageSent(message);

  return (
    <>
      {currentTab === 3 ? (
        <OutlinedTrashButtons {...props} />
      ) : (
        <div className={classes.root}>
          {props.matches && (
            <Box
              display="flex"
              alignItems="center"
              height="48px"
              color="action.disabled"
            >
              <IconButton
                aria-label="back"
                onClick={() => setCollapsed('reset')}
                style={{ height: '40px', width: '40px' }}
              >
                <SvgIcon
                  color="disabled"
                  style={{ paddingTop: '10px', paddingLeft: '10px' }}
                >
                  <path
                    d="M8.06202 11.3566L3.29601 6.7666L8.06201 2.1766L6.59401 0.7666L0.351015 6.7666L6.59402 12.7666L8.06202 11.3566Z"
                    fill="#979DB0"
                  />
                </SvgIcon>
              </IconButton>
              <Typography variant="body2">Back</Typography>
            </Box>
          )}
          {!isSent && (
            <IconButton
              aria-label="reply"
              onClick={() => setCollapsed('replyMessage')}
            >
              <ReplyIcon color="disabled" />
            </IconButton>
          )}
          {!isSent && (
            <IconButton
              aria-label="reply all"
              onClick={() => setCollapsed('replyAllMessage')}
            >
              <ReplyAllIcon color="disabled" />
            </IconButton>
          )}
          <IconButton
            aria-label="forward"
            onClick={() => setCollapsed('forwardMessage')}
          >
            <SvgIcon color="disabled">
              <path
                d="M19.8852 2.4H4.34281C3.80161 2.43011 3.29411 2.6724 2.9304 3.0743C2.5667 3.4762 2.37612 4.00529 2.40001 4.5468V17.4312C2.37612 17.9727 2.5667 18.5018 2.9304 18.9037C3.29411 19.3056 3.80161 19.5479 4.34281 19.578H13.08V17.4312H4.34281V6.6948L12.12 12.0636L19.8912 6.6948V12.0636H21.828V4.5468C21.8519 4.00529 21.6613 3.4762 21.2976 3.0743C20.9339 2.6724 20.4264 2.43011 19.8852 2.4ZM12.12 9.9156L4.34281 4.5468H19.8852L12.12 9.9156ZM18.9204 14.2104L22.8 18.5052L18.9144 22.8V19.5792H15.0288V17.4312H18.9144L18.9204 14.2104Z"
                fill="#979DB0"
              />
            </SvgIcon>
          </IconButton>
          {!isSent && (
            <IconButton
              aria-label="delete"
              disabled={deleting}
              onClick={async () => {
                handleRemoveClick({
                  taskId: message.taskId,
                  notification_Uid: message.notificationUid,
                  currentTab: getCurrentTab(currentTab),
                  page: getCurrentPage(getCurrentTab(currentTab)),
                  timeFrame: getCurrentTimeFrame(getCurrentTab(currentTab)),
                });
                setCurrentMessage({});
                setCollapsed('reset');
              }}
            >
              <DeleteIcon color="disabled" />
            </IconButton>
          )}
        </div>
      )}
    </>
  );
}

function OutlinedTrashButtons(props: OutlinedButtonsProps) {
  // const [mutate, { status: restoreStatus }] = useRestoreMessage();
  const [handleRemoveClick, { status: removeStatus }] = useRemoveMessage();
  const [restoreMultiple, { status: restoreMultipleStatus }] =
    useRestoreMessages();
  const classes = useBtnStyles({});
  const {
    message,
    setCurrentMessage,
    setCollapsed,
    currentTab,
    getCurrentPage,
    getCurrentTimeFrame,
  } = props;

  const restoring = restoreMultipleStatus === 'loading';
  const deleting = removeStatus === 'loading';

  const updtInfoList = [
    { taskId: message.taskId, notification_Uid: message.notificationUid },
  ];
  const updates = {
    updtInfoList,
    restore: true,
    tab: getCurrentTab(currentTab),
    page: getCurrentPage(getCurrentTab(currentTab)),
    timeFrame: getCurrentTimeFrame(getCurrentTab(currentTab)),
  };
  return (
    <div className={classes.root}>
      <Button
        disabled={restoring}
        variant="outlined"
        color="secondary"
        onClick={async () => {
          restoreMultiple(updates as any);
          setCurrentMessage({});
          setCollapsed('reset');
        }}
      >
        Restore
      </Button>
      <Button
        disabled={deleting}
        variant="outlined"
        color="secondary"
        onClick={async () => {
          handleRemoveClick({
            taskId: message.taskId,
            notification_Uid: message.notificationUid,
            currentTab: getCurrentTab(currentTab),
            page: getCurrentPage(getCurrentTab(currentTab)),
            timeFrame: getCurrentTimeFrame(getCurrentTab(currentTab)),
          });
          setCurrentMessage({});
          setCollapsed('reset');
        }}
      >
        {(deleting && 'Deleting') || 'Delete Forever'}
      </Button>
    </div>
  );
}

export default PatientMessages;
