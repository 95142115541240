/** @jsx jsx */
/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import { useForm } from 'react-final-form';
import Downshift from 'downshift';
import { List } from 'react-virtualized';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/Input';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useContactsSearch } from 'utils/contacts';
import Recipient from './Recipient';

function CustomInput(props) {
  const {
    contacts,
    className,
    removeContact,
    itemToString,
    InputAdornmentComponent,
    ...other
  } = props;

  const list = contacts.map(c => (
    <Box pr={1.5} pb={1}>
      <Recipient
        key={c.personId}
        onRemove={() => {
          removeContact(c);
        }}
      >
        {itemToString(c)}
      </Recipient>
    </Box>
  ));

  return (
    <div css={css({ display: 'flex', flexWrap: 'wrap' })}>
      <Box pr={1}>{InputAdornmentComponent}</Box>
      {list}
      <InputBase {...other} />
    </div>
  );
}

function RecipientInput(props) {
  const inputRef = useRef();
  const form = useForm();
  const {
    selectedContacts = [],
    setSelectedContacts,
    patient,
    input,
    InputAdornmentComponent,
  } = props;

  useEffect(() => {
    form.change('recipient', undefined);
  }, [selectedContacts]);

  const handleChange = (selectedContact, downshift) => {
    downshift.reset();
    const contact = { ...selectedContact, groupFlag: 1 };
    setSelectedContacts(selectedContacts => [...selectedContacts, contact]);
  };

  const itemToString = i => (i ? i.name || i.nameFullFormatted : '');

  const handleInputKeyDown = ({
    event,
    isOpen,
    selectHighlightedItem,
    highlightedIndex,
    reset,
    inputValue,
  }) => {
    if (event.key === 'Backspace' && !event.target.value) {
      setSelectedContacts(selectedContacts =>
        selectedContacts.length
          ? selectedContacts.slice(0, selectedContacts.length - 1)
          : [],
      );
      reset();
    } else if (isOpen && ['Tab', ',', ';'].includes(event.key)) {
      event.preventDefault();
      if (highlightedIndex != null) {
        selectHighlightedItem();
      } else {
        reset();
        setSelectedContacts(selectedContacts => [
          ...selectedContacts,
          {
            id: inputValue.toLowerCase(),
            email: inputValue,
            name: inputValue,
          },
        ]);
      }
    }
  };

  function removeContact(contact) {
    setSelectedContacts(selectedContacts =>
      selectedContacts.filter(c => c !== contact),
    );
    !patient && inputRef.current.focus();
  }

  return (
    <Downshift
      itemToString={itemToString}
      selectedItem={null}
      onChange={handleChange}
      defaultHighlightedIndex={0}
    >
      {({
        getLabelProps,
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        toggleMenu,
        clearSelection,
        highlightedIndex,
        selectHighlightedItem,
        setHighlightedIndex,
        reset,
        inputValue,
        clearItems,
        setItemCount,
      }) => (
        <div style={{ flexGrow: 1 }}>
          <label {...getLabelProps({ style: { display: 'none' } })}>
            Select your recipients
          </label>
          <div
            css={css({
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            })}
          >
            {!patient ? (
              <TextField
                InputProps={{
                  inputComponent: CustomInput,
                  inputProps: {
                    contacts: selectedContacts,
                    removeContact,
                    itemToString,
                    InputAdornmentComponent,
                    //startAdornment: InputAdornmentComponent,
                  },
                }}
                {...getInputProps({
                  ref: inputRef,
                  ...input,
                  onKeyDown: event =>
                    handleInputKeyDown({
                      event,
                      selectHighlightedItem,
                      highlightedIndex,
                      isOpen,
                      reset,
                      inputValue,
                    }),
                  css: css({
                    border: 'none',
                    flex: 1,
                    outline: 'none',
                    minWidth: '100',
                    zIndex: 1,
                  }),
                })}
              />
            ) : (
              selectedContacts.map(c => (
                <Box pr={1.5} pb={1}>
                  <Recipient
                    key={c.personId}
                    onRemove={() => {
                      removeContact(c);
                    }}
                  >
                    {itemToString(c)}
                  </Recipient>
                </Box>
              ))
            )}
          </div>
          {!isOpen ? null : (
            <ul
              {...getMenuProps({
                style: { padding: 0, margin: 0, zIndex: 10000 },
              })}
            >
              {inputValue.length >= 3 && (
                <FetchContacts
                  searchValue={inputValue}
                  omitContacts={selectedContacts}
                  onLoaded={({ contacts }) => {
                    clearItems();
                    if (contacts) {
                      setHighlightedIndex(contacts.length ? 0 : null);
                      setItemCount(contacts.length);
                    }
                  }}
                >
                  {({ loading, contacts, error }) => {
                    return (
                      <div
                        css={css({
                          position: 'absolute',
                          backgroundColor: 'white',
                          width: 300,
                          maxHeight: 280,
                          overflow: 'scroll',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          border: '1px solid rgba(0,0,0,.2)',
                          zIndex: 10000,
                        })}
                      >
                        {loading ? (
                          <div css={css({ padding: 10, zIndex: 10000 })}>
                            <Typography>Loading...</Typography>
                          </div>
                        ) : error ? (
                          <div css={css({ padding: 10 })}>error...</div>
                        ) : contacts.length ? (
                          <ContactList
                            highlightedIndex={highlightedIndex}
                            getItemProps={getItemProps}
                            contacts={contacts}
                          />
                        ) : (
                          <div css={css({ padding: 10 })}>
                            {' '}
                            <Typography>No results...</Typography>
                          </div>
                        )}
                      </div>
                    );
                  }}
                </FetchContacts>
              )}
            </ul>
          )}
        </div>
      )}
    </Downshift>
  );
}

function ContactList({ highlightedIndex, getItemProps, contacts }) {
  const rowHeight = 50;
  const fullHeight = contacts.length * rowHeight;
  console.log(contacts, 'eee');
  return (
    <List
      style={{ zIndex: 1000, borderRadius: 4 }}
      width={300}
      scrollToIndex={highlightedIndex || 0}
      height={fullHeight > 280 ? 280 : fullHeight}
      rowCount={contacts.length}
      rowHeight={rowHeight}
      rowRenderer={({ key, index, style }) => (
        <li
          key={contacts[index].personId}
          {...getItemProps({
            item: contacts[index],
            index,
            style,
            css: css({
              cursor: 'pointer',
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 5,
              backgroundColor: highlightedIndex === index ? '#eee' : 'white',
              borderRadius: 4,
            }),
          })}
        >
          <Typography>
            {contacts[index].name || contacts[index].nameFullFormatted}
          </Typography>
          <Typography css={css({ fontSize: '0.8em', marginLeft: 2 })}>
            {contacts[index]?.positionCd?.display}
          </Typography>
        </li>
      )}
    />
  );
}

function FetchContacts({ limit, searchValue, children }) {
  const { contacts = [], error, status } = useContactsSearch(searchValue);
  const loading = status === 'loading';
  return children({ contacts, loading, error });
}

export default RecipientInput;
