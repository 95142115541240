/* eslint-disable no-restricted-syntax */
import { useQuery, QueryResult } from 'react-query';
import { client } from './api-client';

export interface PendingOrder {
  orderedMnemonic: string;
  orderDate: string;
  orderingProvider: string;
}

const pendingConfig = {
  staleTime: 1000 * 15,
  cacheTime: 1000 * 60 * 4,
};

interface PendingOrdersInputJson {
  personId: number;
}

export interface PendingOrdersClientRequestItem {
  data: PendingOrdersInputJson;
  name?: string;
  path: string;
  token?: any;
  method?: any;
  headers?: {} | undefined;
  testUrl?: any;
}

const getPendingOrdersConfig = (
  queryKey: String,
  { path, data }: PendingOrdersClientRequestItem,
) => ({
  queryKey: [queryKey, { patientId: data.personId }],
  queryFn: async () => {
    const response = await client(path);
    if (!response.ok && !response.pendingOrders && response.pendingOrders.length === 0) {
      throw new Error('Unable to retrieve data from EMR');
    }
    return response.pendingOrders;
  },
  config: { ...pendingConfig },
});

function usePendingOrders(
  data: PendingOrdersInputJson,
): QueryResult<Array<PendingOrder>> {
  const requestItem: PendingOrdersClientRequestItem = {
    data,
    path: `patient/${data.personId}/pendingOrders`,
  };
  return useQuery<Array<PendingOrder>>(
    getPendingOrdersConfig('pendingOrders', requestItem),
  );
}
export { usePendingOrders };
