import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

const useBtnStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

interface OutlinedButtonsProps {
  onHandlePrimary: any;
  onHandleSecondary: any;
}

function OutlinedButtons(props: OutlinedButtonsProps) {
  const classes = useBtnStyles();
  const { onHandlePrimary, onHandleSecondary } = props;
  return (
    <div className={classes.root}>
      <Button variant="outlined" color="primary" onClick={onHandlePrimary}>
        Yes
      </Button>

      <Button variant="outlined" color="secondary" onClick={onHandleSecondary}>
        No
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

interface WarningModalProps {
  open: boolean;
  handleClose: any;
  setCollapsed: Function;
  exposeReplyForm: Function;
  setCurrentMessage: Function;
}

export default function WarningModal(props: WarningModalProps) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    setCollapsed,
    exposeReplyForm,
    setCurrentMessage,
  } = props;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div id="transition-modal-title">
              <Typography variant="h6" color="primary">
                Do you want to close this message without sending?
              </Typography>
            </div>
            <div id="transition-modal-description">
              <OutlinedButtons
                onHandleSecondary={handleClose}
                onHandlePrimary={() => {
                  setCollapsed('reset');
                  exposeReplyForm({});
                  setCurrentMessage({});
                  handleClose();
                }}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
