/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  makeStyles,
  withStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberOutlined from '@material-ui/icons/FiberManualRecordOutlined';
import FiberFilled from '@material-ui/icons/FiberManualRecord';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import SvgIcon from '@material-ui/core/SvgIcon';
import Radio from '@material-ui/core/Radio';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Alert from '@material-ui/lab/Alert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  useWorkList,
  useRequestAccess,
  WorkListInterface,
  useGender,
  useRemovePatient,
} from 'utils/worklist';
import { prefetch } from 'utils/hooks';
import {
  formatDOBDate,
  formatVisitDateAndTime,
  dateDiffInDays,
} from 'utils/dateFormat';
import ComposeDialog from 'components/ComposeDialog';
import Temporary from 'components/Temporary';
import SortableTableHead from 'components/SortableTableHead';
import matchSorter from 'match-sorter';
import { addDays, isWithinInterval } from 'date-fns';
import ConfirmDialog from 'components/ConfirmDialog';
import { useAudit } from 'utils/audit';
import { ReactComponent as Person } from 'assets/Person.svg';
import RemoveOptionsMenu from 'components/RemoveOptionsMenu';
import { useAuth } from 'context/auth-context';
import GrantAccess from 'components/GrantAccess';
import DialogActions from '@material-ui/core/DialogActions';
import DialogWrapper from 'components/Dialog';
import { DialogTitle } from '@material-ui/core';

function getGenderCallback(data) {
  const genderData = data.items.find(d => d.name === 'gender');
  return genderData.values ?? [];
}

prefetch({
  query: 'gender',
  endpoint: 'Codesets/GetByName',
  result: 'items',
  data: { names: ['gender'] },
  cb: getGenderCallback,
});

prefetch({
  endpoint: 'request-access/contacts',
  query: 'request-access/contacts',
  result: 'contacts',
});

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  anchorLeft: {
    left: 'auto',
  },
  dockLeft: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 0,
    height: 'calc(100% - 70px)',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  containerBox: {
    height: 64,
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(6),
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1),
  },
  button: {
    justifyContent: 'center',
  },
  filter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    color: theme.palette.primary.main,
  },
  body: {
    fontSize: 14,
    borderBottom: 'none',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
    height: 85,
    borderRadius: theme.shape.borderRadius,
  },
}))(TableRow);

function descendingComparator(row1, row2, orderBy) {
  let val1;
  let val2;
  switch (orderBy) {
    case 'patient':
      val1 = row1.patient.name;
      val2 = row2.patient.name;
      break;

    case 'dob':
      val1 = new Date(row1.patient.dob);
      val2 = new Date(row2.patient.dob);
      break;

    case 'gender':
      val1 = row1.patient.gender;
      val2 = row2.patient.gender;
      break;

    case 'pcp':
      val1 = row1.pcp.name;
      val2 = row2.pcp.name;
      break;

    case 'patient location':
      val1 = row1.facility.name;
      val2 = row2.facility.name;
      break;

    case 'discharge':
      val1 = row1.facility.name;
      val2 = row2.facility.name;
      break;

    case 'next visit':
      val1 = new Date(row1.nextVisit?.date);
      val2 = new Date(row2.nextVisit?.date);
      break;

    case 'next visit location':
      val1 =
        row1.nextVisit?.unitLocation.display +
        row1.nextVisit?.providers?.[0]?.name;
      val2 =
        row2.nextVisit?.unitLocation.display +
        row2.nextVisit?.providers?.[0]?.name;
      break;

    case 'status':
      val1 = row1.documentation[0].status;
      val2 = row2.documentation[0].status;
      break;
    default:
      // TODO: handle opened or unopened message data
      return 0;
  }

  if (val2 < val1) {
    return -1;
  }
  if (val2 > val1) {
    return 1;
  }
  return 0;
}

function getComparatorFunction(order, orderBy) {
  return order === 'desc'
    ? (row1, row2) => descendingComparator(row1, row2, orderBy)
    : (row1, row2) => -descendingComparator(row1, row2, orderBy);
}

function tableSort(items, comparator) {
  const indexedItems = items.map((item, index) => [item, index]);
  indexedItems.sort((row1, row2) => {
    const order = comparator(row1[0], row2[0]);
    if (order !== 0) return order;
    return row1[1] - row2[1];
  });
  return indexedItems.map(item => item[0]);
}

const headOwnCells = [
  { id: 'patient', label: 'Patient' },
  { id: 'dob', label: 'DOB' },
  { id: 'gender', label: 'Gender' },
  { id: 'next visit', label: 'Next Visit' },
  { id: 'next visit location', label: 'Next Visit Location' },
  { id: 'status', label: '' },
  { id: 'actions', label: '' },
];

const headAllCells = [
  { id: 'patient', label: 'Patient' },
  { id: 'patient location', label: 'Patient Location' },
  { id: 'pcp', label: 'PCP' },
  { id: 'discharge', label: 'Last Hospital Discharge Date' },
  { id: 'last seen', label: 'Days Since Last Seen' },
  { id: 'next visit', label: 'Next Visit' },
  { id: 'status', label: '' },
  { id: 'actions', label: '' },
];

function getUserCells(config) {
  switch (config.user.facilityViewType) {
    case 'OWN': {
      return headOwnCells;
    }
    case 'ALL': {
      return headAllCells;
    }
    default:
      throw new Error('Unknown user type');
  }
}

const useTableStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
  },
  container: {},
}));

interface CustomizedTablesProps {
  workList: any;
  isLoading: boolean;
  currentRow: any;
  setCurrentRow: (a: any) => void;
}

function CustomizedTables({
  workList,
  isLoading,
  currentRow,
  setCurrentRow,
}: CustomizedTablesProps) {
  const { configSettings } = useAuth();
  const classes = useTableStyles();
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('patient');

  const handleClickOpen = row => {
    setCurrentRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRow({});
  };

  const handleRequestSort = (e, column) => {
    const isAsc = orderBy === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Box
            component="div"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 400,
            }}
          >
            <CircularProgress />
          </Box>
        </Container>
      ) : (
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} aria-label="patients table">
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={getUserCells(configSettings)}
            />
            <TableBody>
              {tableSort(workList, getComparatorFunction(order, orderBy)).map(
                row => (
                  <Row
                    key={row.patient.id}
                    row={row}
                    onClick={() => handleClickOpen(row)}
                  />
                ),
              )}
            </TableBody>
          </Table>
          <SimpleDialog open={open} onClose={handleClose} row={currentRow} />
        </TableContainer>
      )}
    </>
  );
}

const useDialogStyles = makeStyles(theme => ({
  divider: {
    flex: '1 1',
  },
  preVist: {
    padding: theme.spacing(1),
  },
}));

function SimpleDialog(props) {
  const classes = useDialogStyles();
  const { onClose, open, row } = props;
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="patient-information"
      open={open}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mt={1}
        mr={1}
      >
        <Close
          color="disabled"
          fontSize="small"
          onClick={handleClose}
          style={{ cursor: 'pointer' }}
        />
      </Box>
      <DialogContent>
        <Box mx={2}>
          <List>
            <ListItem>
              <ListItemText>
                <Box display="flex">
                  <Typography variant="subtitle2"> Patient: </Typography>
                  <Box pl={1}>
                    <Typography variant="body2">{row.patient?.name}</Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Box display="flex">
                  <Typography variant="subtitle2">Last Vist Date:</Typography>
                  <Box pl={1}>
                    <Typography variant="body2">
                      {formatVisitDateAndTime(row.lastVisit?.date)}
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Box display="flex">
                  <Typography variant="subtitle2">
                    Last Visit Location:
                  </Typography>
                  <Box pl={1}>
                    <Typography variant="body2">
                      {row.lastVisit?.unitLocation.description ??
                        row.lastVisit?.unitLocation.display}
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Box display="flex">
                  <Typography variant="subtitle2">
                    Last Visit Provider:
                  </Typography>
                  <Box pl={1}>
                    <Typography variant="body2">
                      {row.lastVisit?.providers[0]?.name}
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Box display="flex">
                  <Typography variant="subtitle2">Next Scheduled:</Typography>
                  <Box pl={1}>
                    <Typography variant="body2">
                      {formatVisitDateAndTime(row.nextVisit?.date)}
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Box display="flex" alignItems="center">
                  <Divider className={classes.divider} />
                  <Typography variant="subtitle2" className={classes.preVist}>
                    Pre Vist Form
                  </Typography>
                  <Divider className={classes.divider} />
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Box display="flex">
                  <Typography variant="subtitle2"> Status: </Typography>
                  <Box pl={1}>
                    <Typography variant="body2">
                      {row.documentation ? row.documentation[0]?.status : null}
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItem>
            <ListItem autoFocus>
              <Box display="flex" justifyContent="center" flex="1 1">
                <Link
                  to={`/chart/${row.patient?.id}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    type="submit"
                  >
                    Open Chart
                  </Button>
                </Link>
              </Box>
            </ListItem>
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function getStatus(status) {
  const empty = 'not started';
  const half = 'incomplete';
  const full = 'complete';

  switch (status) {
    case empty:
      return <FiberOutlined fontSize="small" />;
    case full:
      return <FiberFilled fontSize="small" />;
    case half:
      return (
        <SvgIcon style={{ color: 'rgba(0, 0, 0, 0.04)' }}>
          <circle cx="7.5" cy="7.5" r="6.5" stroke="#162942" strokeWidth="2" />
          <path
            d="M12.5 11.5005L3.5 2.50049V2.00049L5.5 1.00049H7.5L10.5 2.00049L12.5 3.00049L13.5 5.00049L14 7.00049V9.00049L12.5 11.5005Z"
            fill="#162942"
            stroke="#162942"
          />
        </SvgIcon>
      );
    default:
      return null;
  }
}

function getOwnNextVisit(row, onClick) {
  return (
    <>
      <StyledTableCell onClick={onClick}>
        {formatVisitDateAndTime(row.nextVisit?.date)}
      </StyledTableCell>

      <StyledTableCell onClick={onClick}>
        {row.nextVisit?.unitLocation.description ??
          row.nextVisit?.unitLocation.display}
        {row.nextVisit?.providers?.[0] && (
          <Box display="flex">
            <Typography variant="subtitle2">Provider:</Typography>
            <Box pl={1}>{row.nextVisit?.providers?.[0]?.name}</Box>
          </Box>
        )}
      </StyledTableCell>
    </>
  );
}

function hasNextVisit(visit) {
  return visit && visit.appointmentId !== 0;
}

function getAllNextVisit(row, warning, onClick) {
  return (
    <>
      {row.nextVisit && row.nextVisit.appointmentId !== 0 ? (
        <StyledTableCell onClick={onClick}>
          {formatVisitDateAndTime(row.nextVisit?.date)}
          <Typography variant="body2">
            {row.nextVisit?.unitLocation.description ??
              row.nextVisit?.unitLocation.display}
          </Typography>
          {row.nextVisit?.providers?.[0] && (
            <Box display="flex">
              <Typography variant="subtitle2">Provider:</Typography>
              <Box pl={1}>{row.nextVisit?.providers?.[0]?.name}</Box>
            </Box>
          )}
        </StyledTableCell>
      ) : (
        <>
          {warning.type ? (
            <StyledTableCell>
              <WarningText warning={warning} />
            </StyledTableCell>
          ) : (
            <StyledTableCell />
          )}
        </>
      )}
    </>
  );
}

function isViewTypeAll(config) {
  return config.user.facilityViewType === 'ALL';
}

type Warning = { type: string | null; days: number | null };

const getWarnings = warnings => (dischargeDate, lastSeen, nextVisit) => {
  const {
    pastDischargeWindowDays,
    warnBeforeWindowYellow,
    warnAfterWindowYellow,
    warnAfterWindowRed,
    warnBeforeWindowRed,
  } = warnings;

  let result: Warning = { type: null, days: null };

  if (
    dateDiffInDays(new Date(dischargeDate), new Date()) <
    pastDischargeWindowDays
  ) {
    if (
      lastSeen > warnBeforeWindowYellow &&
      (!nextVisit || !hasNextVisit(nextVisit))
    ) {
      result = { type: 'yellow', days: warnBeforeWindowYellow };
    }
  }

  if (
    dateDiffInDays(new Date(dischargeDate), new Date()) >
    pastDischargeWindowDays
  ) {
    if (
      lastSeen > warnAfterWindowYellow &&
      (!nextVisit || !hasNextVisit(nextVisit))
    ) {
      result = { type: 'yellow', days: warnAfterWindowYellow };
    }
  }

  if (
    dateDiffInDays(new Date(dischargeDate), new Date()) <
    pastDischargeWindowDays
  ) {
    if (
      lastSeen > warnBeforeWindowRed &&
      (!nextVisit || !hasNextVisit(nextVisit))
    ) {
      result = { type: 'red', days: warnBeforeWindowRed };
    }
  }

  if (
    dateDiffInDays(new Date(dischargeDate), new Date()) >
    pastDischargeWindowDays
  ) {
    if (
      lastSeen > warnAfterWindowRed &&
      (!nextVisit || !hasNextVisit(nextVisit))
    ) {
      result = { type: 'red', days: warnAfterWindowRed };
    }
  }

  return result;
};

const useWarningStyles = makeStyles((theme: Theme) => {
  return createStyles({
    warning: {
      color: theme.palette.warning.main,
    },
    red: {
      color: theme.palette.error.main,
    },
  } as any);
});

interface WarningProps {
  warning: Warning;
}

function WarningText({ warning }: WarningProps) {
  const styles = useWarningStyles();
  const { type, days } = warning;

  switch (type) {
    case 'yellow':
      return (
        <Box display="flex" alignItems="center">
          <Typography className={styles.warning}>
            Needs to be scheduled.
          </Typography>
          <Typography className={styles.warning}>
            <ErrorOutline />
          </Typography>
        </Box>
      );
    case 'red':
      return (
        <Box display="flex" alignItems="center">
          <Typography className={styles.red}>Needs to be scheduled.</Typography>
          <ErrorOutline className={styles.red} />
        </Box>
      );
    default:
      return null;
  }
}

interface DialogInterface {
  open: boolean;
  handleClose: () => void;
}

const useDialogtyles = makeStyles(theme => ({
  root: {
    maxWidth: 250,
  },
}));

function WarningDialog({ open, handleClose }: DialogInterface) {
  const {
    configSettings: { config },
  } = useAuth();

  const classes = useDialogtyles();
  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <Box m={3} className={classes.root}>
        <DialogTitle>Schedule Warning</DialogTitle>
        <Typography variant="body1">{config.warnings.message}</Typography>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Box>
    </DialogWrapper>
  );
}

function ErrorOutline(props) {
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <IconButton onClick={() => setOpen(true)} {...props}>
        <ErrorOutlineIcon />
      </IconButton>
      <WarningDialog open={open} handleClose={handleClose} />
    </>
  );
}

function Warning({ warning }: WarningProps) {
  const styles = useWarningStyles();
  const { type } = warning;

  switch (type) {
    case 'yellow':
      return <PriorityHighIcon className={styles.warning} />;
    case 'red':
      return <PriorityHighIcon className={styles.red} />;
    default:
      return null;
  }
}

function Row({
  row,
  onClick,
  ...rest
}: {
  row: any;
  onClick: (
    event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
  ) => void;
}) {
  const [removePatient, { isSuccess, reset, isError }] = useRemovePatient({
    throwOnError: true,
  });
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [reason, setReasonValue] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const { configSettings } = useAuth();

  const getWarningData = getWarnings(configSettings.config.warnings);
  const lastSeen = dateDiffInDays(new Date(row.lastVisit?.date), new Date());

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleSelect(value: string) {
    setConfirmOpen(true);
    setReasonValue(value);
  }

  function handleConfirm() {
    removePatient({ patientId: row.patient.patientId, reason });
  }

  const warning = getWarningData(
    row.dischargeDateTime,
    lastSeen,
    row.nextVisit,
  );

  return (
    <>
      <StyledTableRow key={row.id} {...rest} style={{ cursor: 'pointer' }}>
        <StyledTableCell component="th" scope="row" onClick={onClick}>
          <Box display="flex">
            <Box>
              {isViewTypeAll(configSettings) && <Warning warning={warning} />}
            </Box>
            <Box>
              {row.patient?.name}
              {isViewTypeAll(configSettings) && (
                <Typography variant="body2">
                  DOB: {formatDOBDate(row.patient?.dob)}
                </Typography>
              )}
            </Box>
          </Box>
        </StyledTableCell>
        {isViewTypeAll(configSettings) ? (
          <StyledTableCell onClick={onClick}>
            {row.facility?.name}
          </StyledTableCell>
        ) : (
          <StyledTableCell onClick={onClick}>
            {formatDOBDate(row.patient?.dob)}
          </StyledTableCell>
        )}
        {isViewTypeAll(configSettings) ? (
          <StyledTableCell onClick={onClick}>{row.pcp.name}</StyledTableCell>
        ) : (
          <StyledTableCell onClick={onClick}>
            {row.patient.gender}
          </StyledTableCell>
        )}
        {isViewTypeAll(configSettings) && (
          <StyledTableCell onClick={onClick}>
            {formatVisitDateAndTime(row.dischargeDateTime)}
          </StyledTableCell>
        )}
        {isViewTypeAll(configSettings) && (
          <StyledTableCell style={{ textAlign: 'center' }} onClick={onClick}>
            {lastSeen}
          </StyledTableCell>
        )}
        {isViewTypeAll(configSettings)
          ? getAllNextVisit(row, warning, onClick)
          : getOwnNextVisit(row, onClick)}

        <StyledTableCell onClick={onClick}>
          {row.nextVisit?.providers?.[0] && row.documentation.length
            ? getStatus(row.documentation[0].status)
            : null}
        </StyledTableCell>

        <StyledTableCell>
          <IconButton
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Person />
          </IconButton>
          <RemoveOptionsMenu
            open={open}
            setOpen={setOpen}
            ref={anchorRef}
            onSelect={handleSelect}
          />
        </StyledTableCell>
      </StyledTableRow>
      <ConfirmDialog
        name={row.patient?.name}
        option={reason}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      />
    </>
  );
}

const WorkList = () => {
  const classes = useStyles();
  const { configSettings } = useAuth();
  const [applyFilters, setApplyFilters] = useState<boolean>(false);
  const [worklistFiltered, setWorklistFiltered] = useState<
    Array<WorkListInterface>
  >([]);
  const [open, setOpen] = useState<boolean>(true);
  const [initialized, setInitial] = useState<boolean>(true);
  const [workListLocations, setWorkListLocations] = useState<
    Array<WorkListInterface>
  >([]);
  const [currentRow, setCurrentRow] = useState({});

  const { isLoading, workList }: any = useWorkList({
    refetchOnWindowFocus: false,
    retry: false,
    retryOnMount: false,
  });

  if (workList.length !== 0 && initialized) {
    setInitial(false);
    setWorkListLocations(workList);
  }

  if (
    (workList.length === 0 && !isLoading) ||
    (worklistFiltered.length === 0 && applyFilters)
  ) {
    return (
      <div className={classes.root}>
        <SideBarForm
          submitting={isLoading}
          open={open}
          setOpen={setOpen}
          workList={workListLocations}
          setWorklistFiltered={setWorklistFiltered}
          setApplyFilters={setApplyFilters}
        />
        <main style={{ flexGrow: 1 }}>
          <Toolbar style={{ minHeight: 70 }} />
          <Toolbar style={{ minHeight: 70 }} />
          {isViewTypeAll(configSettings) ? (
            <GrantAccess
              classes={classes}
              openForm={open}
              setSideBarOpen={setOpen}
              currentRow={currentRow}
            />
          ) : (
            <RequestAccess
              classes={classes}
              openForm={open}
              setSideBarOpen={setOpen}
            />
          )}
          <div
            className={`${classes.content}  ${open && classes.contentShift} `}
            style={{ marginTop: '20%' }}
          >
            <Typography align="center">
              No patients qualify for selected filters
            </Typography>
          </div>
        </main>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <Box
          component="div"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }
  return (
    <div className={classes.root}>
      <SideBarForm
        submitting={isLoading}
        open={open}
        setOpen={setOpen}
        workList={workListLocations}
        setWorklistFiltered={setWorklistFiltered}
        setApplyFilters={setApplyFilters}
      />
      <main style={{ flexGrow: 1 }}>
        <Toolbar style={{ minHeight: 70 }} />
        <Toolbar style={{ minHeight: 70 }} />
        {isViewTypeAll(configSettings) ? (
          <GrantAccess
            classes={classes}
            openForm={open}
            setSideBarOpen={setOpen}
            currentRow={currentRow}
          />
        ) : (
          <RequestAccess
            classes={classes}
            openForm={open}
            setSideBarOpen={setOpen}
          />
        )}
        <div className={`${classes.content}  ${open && classes.contentShift} `}>
          <CustomizedTables
            workList={applyFilters ? worklistFiltered : workList}
            isLoading={isLoading}
            currentRow={currentRow}
            setCurrentRow={setCurrentRow}
          />
        </div>
      </main>
    </div>
  );
};

interface SideBarForm {
  submitting: boolean;
  open: boolean;
  setOpen: Function;
  workList: Array<WorkListInterface>;
  setWorklistFiltered: Function;
  setApplyFilters: Function;
}

function SideBarForm(props: SideBarForm) {
  const classes = useStyles();
  const theme = useTheme();
  const { configSettings } = useAuth();
  const {
    submitting,
    open,
    setOpen,
    workList,
    setWorklistFiltered,
    setApplyFilters,
  } = props;

  const onSubmit = ({
    previsit: status,
    nextVisit,
    notScheduled,
    location,
    pcp,
    facility,
    lastSeen,
  }) => {
    let results = workList;

    if (status) {
      results = status.reduce((acc, statusValue) => {
        return [
          ...acc,
          ...matchSorter(workList, statusValue, {
            keys: ['documentation.0.status'],
            threshold: matchSorter.rankings.EQUAL,
          }),
        ];
      }, []);
    }

    if (location) {
      results = location.reduce((acc, locationValue) => {
        return [
          ...acc,
          ...matchSorter(results, locationValue, {
            keys: ['nextVisit.unitLocation.code'],
          }),
        ];
      }, []);
    }

    if (pcp) {
      results = pcp.reduce((acc, pcpValue) => {
        return [
          ...acc,
          ...matchSorter(results, pcpValue, {
            keys: ['pcp.name'],
          }),
        ];
      }, []);
    }

    if (facility) {
      results = facility.reduce((acc, facilityValue) => {
        return [
          ...acc,
          ...matchSorter(results, facilityValue, {
            keys: ['facility.name'],
          }),
        ];
      }, []);
    }

    if (lastSeen) {
      results = results.filter(
        item =>
          dateDiffInDays(new Date(item.lastVisit?.date), new Date()) > lastSeen,
      );
    }

    if (nextVisit || notScheduled) {
      let nextVisitResults: WorkListInterface[] = [];
      let notScheduledResults: WorkListInterface[] = [];

      if (nextVisit) {
        const arrayNextVisit = nextVisit.split(',');

        nextVisitResults = arrayNextVisit.reduce((acc, nextVisitValue) => {
          return [
            ...acc,
            ...results.filter(item =>
              isWithinInterval(new Date(item?.nextVisit?.date), {
                start: Date.now(),
                end: addDays(Date.now(), nextVisitValue),
              }),
            ),
          ];
        }, []);
      }

      if (notScheduled) {
        notScheduledResults = results.filter(
          item => item?.nextVisit?.date === null,
        );
      }
      results = [...nextVisitResults, ...notScheduledResults];
    }

    setApplyFilters(true);
    setWorklistFiltered(results);
  };

  const handleDrawerClose = () => setOpen(false);

  return (
    <>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              open={open}
              classes={{
                paper: classes.drawerPaper,
                paperAnchorLeft: classes.anchorLeft,
                paperAnchorDockedLeft: classes.dockLeft,
              }}
              anchor="left"
            >
              <Toolbar style={{ minHeight: 70 }} />
              <Toolbar style={{ minHeight: 70 }} />
              <List>
                <ListItem>
                  <ListItemText>
                    <Box className={classes.filter}>
                      <Typography
                        className={classes.subtitle}
                        align="center"
                        variant="subtitle1"
                      >
                        Filters
                      </Typography>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </Box>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText>
                    <Typography align="center">PreVisit Form Status</Typography>
                  </ListItemText>
                </ListItem>
                {[
                  { key: 'Complete', value: 'complete' },
                  { key: 'Incomplete', value: 'incomplete' },
                  { key: 'Not Started', value: 'not started' },
                ].map((object, index) => (
                  <ListItem button key={object.key}>
                    <ListItemIcon>
                      <Field
                        name="previsit"
                        component="input"
                        type="checkbox"
                        value={object.value}
                      >
                        {({ input, meta }) => (
                          <Checkbox
                            {...input}
                            size="small"
                            color="primary"
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                          />
                        )}
                      </Field>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body2" color="textSecondary">
                        {object.key}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography align="center">Next Visit Date</Typography>
                  </ListItemText>
                </ListItem>
                {[
                  { key: 'Within next 7 Days', value: '7' },
                  { key: 'Within next 14 Days', value: '14' },
                  { key: 'Within next 30 Days', value: '30' },
                ].map((object, index) => (
                  <ListItem button key={object.key}>
                    <ListItemIcon>
                      <Field
                        name="nextVisit"
                        type="radio"
                        component="input"
                        value={object.value}
                      >
                        {({ input, meta }) => (
                          <Radio
                            {...input}
                            size="small"
                            color="primary"
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                          />
                        )}
                      </Field>
                    </ListItemIcon>
                    <Typography variant="body2" color="textSecondary">
                      {object.key}
                    </Typography>
                  </ListItem>
                ))}
                <ListItem button>
                  <ListItemIcon>
                    <Field
                      name="notScheduled"
                      type="checkbox"
                      component="input"
                    >
                      {({ input, meta }) => (
                        <Checkbox
                          {...input}
                          size="small"
                          color="primary"
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                        />
                      )}
                    </Field>
                  </ListItemIcon>
                  <Typography variant="body2" color="textSecondary">
                    Not Scheduled
                  </Typography>
                </ListItem>
              </List>
              <Divider />
              {isViewTypeAll(configSettings) && (
                <List>
                  <ListItem>
                    <ListItemText>
                      <Typography align="center">PCP</Typography>
                    </ListItemText>
                  </ListItem>
                  {workList
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(i => item.pcp?.name === i.pcp?.name),
                    )
                    .filter(item => Boolean(item.pcp?.name))
                    .map((item, index) => (
                      <ListItem button key={index}>
                        <ListItemIcon>
                          <Field
                            name="pcp"
                            component="input"
                            type="checkbox"
                            value={item.pcp?.name}
                          >
                            {({ input, meta }) => (
                              <Checkbox
                                {...input}
                                size="small"
                                color="primary"
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                              />
                            )}
                          </Field>
                        </ListItemIcon>
                        <Typography variant="body2" color="textSecondary">
                          {item.pcp?.name}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              )}
              {!isViewTypeAll(configSettings) && (
                <List>
                  <ListItem>
                    <ListItemText>
                      <Typography align="center">
                        Next Visit Location
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {workList
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          i =>
                            item.nextVisit?.unitLocation.code ===
                            i.nextVisit?.unitLocation.code,
                        ),
                    )
                    .filter(
                      item =>
                        item.nextVisit?.unitLocation.code !== 0 &&
                        item.nextVisit?.unitLocation.code !== undefined,
                    )
                    .map((item, index) => (
                      <ListItem button key={index}>
                        <ListItemIcon>
                          <Field
                            name="location"
                            component="input"
                            type="checkbox"
                            value={Number(item.nextVisit?.unitLocation.code)}
                          >
                            {({ input, meta }) => (
                              <Checkbox
                                {...input}
                                size="small"
                                color="primary"
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                              />
                            )}
                          </Field>
                        </ListItemIcon>
                        <Typography variant="body2" color="textSecondary">
                          {item.nextVisit?.unitLocation.display ??
                            item.nextVisit?.unitLocation.description}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              )}
              <Divider />
              {isViewTypeAll(configSettings) && (
                <List>
                  <ListItem>
                    <ListItemText>
                      <Typography align="center">Patient Location</Typography>
                    </ListItemText>
                  </ListItem>
                  {workList
                    .filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          i => item.facility?.name === i.facility?.name,
                        ),
                    )
                    .filter(item => Boolean(item.facility?.name))
                    .map((item, index) => (
                      <ListItem button key={index}>
                        <ListItemIcon>
                          <Field
                            name="facility"
                            component="input"
                            type="checkbox"
                            value={item.facility?.name}
                          >
                            {({ input, meta }) => (
                              <Checkbox
                                {...input}
                                size="small"
                                color="primary"
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                              />
                            )}
                          </Field>
                        </ListItemIcon>
                        <Typography variant="body2" color="textSecondary">
                          {item.facility?.name}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              )}
              {isViewTypeAll(configSettings) && (
                <>
                  <Divider />
                  <List>
                    <ListItem>
                      <ListItemText>
                        <Typography align="center">
                          Days Since Last Seen
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    {[
                      { key: '> 30', value: '30' },
                      { key: '> 60', value: '60' },
                      { key: '> 90', value: '90' },
                    ].map((object, index) => (
                      <ListItem button key={object.key}>
                        <ListItemIcon>
                          <Field
                            name="lastSeen"
                            type="radio"
                            component="input"
                            value={object.value}
                          >
                            {({ input, meta }) => (
                              <Radio
                                {...input}
                                size="small"
                                color="primary"
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                              />
                            )}
                          </Field>
                        </ListItemIcon>
                        <Typography variant="body2" color="textSecondary">
                          {object.key}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
              <Divider />
              <List
                style={{
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 10,
                  backgroundColor: 'white',
                }}
              >
                <ListItem className={classes.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Apply Filters
                  </Button>
                </ListItem>
                <ListItem className={classes.button}>
                  <Button
                    type="button"
                    variant="text"
                    onClick={() => {
                      form.reset();
                      setWorklistFiltered([]);
                      setApplyFilters(false);
                    }}
                  >
                    <Typography color="textSecondary">Clear</Typography>
                  </Button>
                </ListItem>
              </List>
            </Drawer>
          </form>
        )}
      </Form>
    </>
  );
}

interface RequestAccessProps {
  classes: any;
  openForm: boolean;
  setSideBarOpen: Function;
}

export interface FormValues {
  dob: string;
  firstName: string;
  gender: string;
  lastName: string;
  mrn: string;
}

const useFormStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  subHeader: {
    marginRight: theme.spacing(2),
  },
  group: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  formControl: {
    minWidth: 120,
  },
}));

function RequestAccess({
  classes,
  openForm,
  setSideBarOpen,
}: RequestAccessProps) {
  const formClasses = useFormStyles();
  const [values, setValues] = useState({
    open: false,
    success: false,
    error: false,
  });
  const [formValues, setFormValues] = useState({} as FormValues);
  const [openCompose, setComposeOpen] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [patientFound, setPatientFound] = useState<boolean>(false);
  const [requestAccess, { isError, isSuccess, reset, status }] =
    useRequestAccess({
      throwOnError: true,
    });

  const [
    audit,
    {
      isError: isAuditError,
      isSuccess: isAuditSuccess,
      reset: resetAudit,
      status: statusAudit,
    },
  ] = useAudit();

  const { data: genderData } = useGender({ refetchOnWindowFocus: false });

  const handleDrawerOpen = () => setSideBarOpen(true);

  const validate = values => {
    interface Errors {
      firstName?: string;
      lastName?: string;
      mrn?: string;
      dob?: string;
      gender?: string;
    }

    const errors: Errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (!values.mrn) {
      errors.mrn = 'Required';
    }
    if (!values.dob) {
      errors.dob = 'Required';
    }
    if (!values.gender) {
      errors.gender = 'Required';
    }
    return errors;
  };

  const onSubmit = async requestValues => {
    const data: any = { ...requestValues };
    data.gender = genderData?.find(g => requestValues.gender === g.code);

    return requestAccess({ ...data, gender: { display: data.gender.display } })
      .then(
        () => {
          setPatientFound(true);
          setValues(prev => ({ ...prev, open: false }));
        },
        () => {
          setFormValues(requestValues);
        },
      )
      .then(() => {
        audit({
          eventType: 'Patients:Request:Access:FacilityId:Update',
          details: {
            uiScreen: 'Worklist',
          },
        });
      });
  };

  return (
    <>
      <Box
        display="flex"
        mt={2}
        mb={2}
        className={`${classes.containerBox} ${
          openForm && classes.contentShift
        } `}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: openForm,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          Dont see your patient?
        </Typography>
        <Button
          onClick={() => setValues(prev => ({ ...prev, open: !prev.open }))}
        >
          <Typography variant="subtitle2">
            Request Access
            <IconButton aria-label="expand row" size="small">
              {values.open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Typography>
        </Button>
        {messageSent && (
          <Temporary cb={() => setMessageSent(false)}>
            <Alert severity="success">Message has been sent.</Alert>
          </Temporary>
        )}
        {patientFound && (
          <Temporary cb={() => setPatientFound(false)}>
            <Alert severity="success">
              Success! The patient has been added to your worklist.
            </Alert>
          </Temporary>
        )}
      </Box>
      <Collapse
        in={values.open}
        timeout="auto"
        unmountOnExit
        onExited={() => reset()}
      >
        <Box mb={3} ml={6}>
          <Box mb={2} ml={1}>
            <Box display="flex" justifyContent="space-between">
              {isError && (
                <Box display="flex" alignItems="center">
                  <Message>
                    The patient could not be found. Try again or send a message
                    to continue the request to access this patient’s chart.
                  </Message>
                  <Box mx={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={false}
                      onClick={() => setComposeOpen(true)}
                    >
                      Start Message
                    </Button>
                  </Box>
                </Box>
              )}

              {((!isSuccess && !isError) || status !== 'error') && (
                <>
                  <Message>
                    Enter the details below to request access to the patient. By
                    doing this you are agreeing that the patient is located in
                    your facility.
                  </Message>
                </>
              )}
            </Box>
          </Box>
          {((!isSuccess && !isError) || status !== 'error') && (
            <Box display="flex" justifyContent="space-between">
              <Form onSubmit={onSubmit} validate={validate}>
                {({ handleSubmit, invalid, pristine, submitting }) => (
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Box className={formClasses.root}>
                      <Field name="firstName">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            id="standard-basic"
                            label="First Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <Field name="lastName">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            id="filled-basic"
                            label="Last Name"
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <Box className={formClasses.inline}>
                        <Typography>MRN:</Typography>
                        <Field name="mrn">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="outlined-basic"
                              label="MRN"
                              variant="outlined"
                            />
                          )}
                        </Field>
                      </Box>
                      <Box className={formClasses.inline}>
                        <Typography>DOB:</Typography>
                        <Field name="dob">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              id="date"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <Box className={formClasses.inline}>
                        <Typography className={formClasses.subHeader}>
                          Gender:
                        </Typography>
                        <FormControl
                          variant="outlined"
                          className={formClasses.formControl}
                        >
                          <InputLabel id="gender-label">Gender</InputLabel>
                          <Field name="gender">
                            {({ input, meta }) => (
                              <Select
                                {...input}
                                labelId="gender-label"
                                label="Gender"
                              >
                                {genderData?.map(obj => (
                                  <MenuItem key={obj.code} value={obj.code}>
                                    {obj.display}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </Field>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box m={1} mt={3}>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        type="submit"
                        disabled={pristine || invalid || submitting}
                      >
                        Request Access
                      </Button>
                    </Box>
                  </form>
                )}
              </Form>
              <Box mr={5}>
                <Close
                  color="primary"
                  fontSize="small"
                  onClick={() => {
                    setValues(prev => ({ ...prev, open: false }));
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Collapse>
      <ComposeDialog
        open={openCompose}
        setOpen={setComposeOpen}
        values={formValues}
        genderData={genderData}
        setMessageSent={setMessageSent}
        resetForm={() => setValues(values => ({ ...values, open: false }))}
      />
    </>
  );
}

interface Message {
  error?: boolean;
  children: React.ReactNode;
}

const Message = (props: Message) => {
  const { children } = props;
  const classes = useFormStyles();
  return (
    <Typography
      className={classes.subtitle}
      variant="subtitle2"
      component="div"
    >
      {children}
    </Typography>
  );
};

export default WorkList;
