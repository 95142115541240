/* eslint-disable prefer-arrow-callback */
import React, { forwardRef, Ref } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }),
);

export default forwardRef(function RemoveOptionsMenu(
  {
    open,
    setOpen,
    onSelect,
  }: {
    open: boolean;
    setOpen: Function;
    onSelect: (event: any) => void;
  },
  anchorRef: any,
) {
  const classes = useStyles();

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef!.current &&
      anchorRef!.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef!.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  function handleSelect(e: any, value: string) {
    onSelect(value);
    handleClose(e);
  }

  return (
    <div className={classes.root}>
      <Popper
        open={open}
        anchorEl={anchorRef!.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={e => handleSelect(e, 'Discharged to home')}
                  >
                    Discharged to home
                  </MenuItem>
                  <MenuItem
                    onClick={e =>
                      handleSelect(e, 'Transferred to another facility')
                    }
                  >
                    Transferred to another facility
                  </MenuItem>
                  <MenuItem onClick={e => handleSelect(e, 'Deceased')}>
                    Deceased
                  </MenuItem>
                  <MenuItem onClick={e => handleSelect(e, 'Other')}>
                    Other
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
});
