import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { queryCache } from 'react-query';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Error } from 'components/ErrorField';
import { FormValues } from 'components/WorkList';
import { formatDOBDate } from 'utils/dateFormat';
import { Gender } from 'utils/worklist';
import { useNewMessage } from 'utils/messages';
import { useRequestAccessContacts } from 'utils/contacts';
import { useAuth } from 'context/auth-context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiGrid-container': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
    text: {
      '& :nth-child(2)': {
        marginLeft: theme.spacing(1),
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    recipients: {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
      height: 50,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

function getGender(code, genders) {
  const gender = genders.find(i => code === i.code);
  return gender ? gender.display : null;
}

interface ComposeDialogProps {
  open: boolean;
  setOpen: Function;
  values: FormValues;
  genderData: Array<Gender> | undefined;
  setMessageSent: Function;
  resetForm: Function;
}

interface Contact {
  name: string;
}

function ComposeDialog(props: ComposeDialogProps) {
  const { open, setOpen, values, genderData, setMessageSent, resetForm } =
    props;
  const classes = useStyles();

  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const { contacts }: any = useRequestAccessContacts();
  const [createMessage] = useNewMessage({
    onSettled: () => {
      queryCache.refetchQueries(['messages', { query: 'sent' }]);
    },
  });
  const { user } = useAuth();

  const handleClose = () => setOpen(false);

  interface Errors {
    contact?: string;
  }

  const validate = values => {
    const errors: Errors = {};
    if (!values.contact) {
      errors.contact = 'Required';
    }
    return errors;
  };

  function renderMsgBody(classes, values) {
    return (
      <>
        <Box my={2}>
          <Typography>
            {user.facilityName} is requesting access to a patient in Chart
            Access.
          </Typography>
          <Typography>
            There is not an exact patient match based on the data entered.
            Please review this request.
          </Typography>
        </Box>
        <Box display="flex" className={classes.text}>
          <Typography>First Name:</Typography>
          <Typography>{values.firstName}</Typography>
        </Box>
        <Box display="flex" className={classes.text}>
          <Typography>Last Name:</Typography>
          <Typography>{values.lastName}</Typography>
        </Box>
        <Box display="flex" className={classes.text}>
          <Typography>MRN:</Typography>
          <Typography>{values.mrn}</Typography>
        </Box>
        <Box display="flex" className={classes.text}>
          <Typography>Date of Birth:</Typography>
          <Typography>{formatDOBDate(new Date(values.dob))}</Typography>
        </Box>
        <Box display="flex" className={classes.text}>
          <Typography>Gender:</Typography>
          <Typography>{getGender(values.gender, genderData)}</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            If you can identify the patient, please reply to this message with
            the correct information for each of the 5 fields.
          </Typography>
        </Box>
      </>
    );
  }

  const onSubmit = formValues => {
    const text = renderMsgBody(classes, values);
    const str = ReactDOMServer.renderToString(text);
    const contact = {
      ...formValues.contact,
      id: formValues.contact.poolId,
      groupFlag: 1,
    };
    return createMessage({
      ...formValues,
      user,
      selectedContacts: [contact],
      selectedCCContacts: [],
      message: `${str} ${formValues.message}`,
      subject: 'Request Access to Patient Chart',
      selectedPatient: [],
    }).then(() => {
      handleClose();
    });
  };

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        fullWidth
      >
        <Paper elevation={3}>
          <Box className={classes.recipients} px={3}>
            <Typography variant="subtitle2">Recipients</Typography>
            <Close
              color="disabled"
              fontSize="small"
              onClick={() => setOpen(false)}
              style={{ cursor: 'pointer', color: 'white' }}
            />
          </Box>
          <DialogContent>
            <Box px={2} mt={2}>
              <Form onSubmit={onSubmit} validate={validate}>
                {({ handleSubmit, pristine, invalid, submitting, form }) => (
                  <form
                    onSubmit={e =>
                      handleSubmit(e)?.then(() => {
                        form.reset();
                        resetForm();
                        setMessageSent(true);
                        setOpen(false);
                      })
                    }
                    autoComplete="off"
                    className={classes.root}
                  >
                    <Grid container>
                      <Grid item container alignItems="center">
                        <Field name="contact">
                          {({ input, meta }) => (
                            <>
                              <InputLabel id="gender-label">To:</InputLabel>
                              <Select
                                {...input}
                                labelId="gender-label"
                                label="Contact"
                                fullWidth
                              >
                                {contacts?.map(obj => (
                                  <MenuItem key={obj.id} value={obj}>
                                    {obj.displayName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                        </Field>
                      </Grid>
                      <Error name="recipient" />
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <Field name="subject">
                          {({ input }) => (
                            <TextField
                              id="subject"
                              label="Subject"
                              disabled
                              defaultValue="Request Access to Patient Chart"
                              variant="standard"
                              fullWidth
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Box>
                      <Grid container>
                        <Grid item xs={12}>
                          {renderMsgBody(classes, values)}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box my={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Field name="message">
                            {({ input }) => (
                              <TextField
                                id="outlined-multiline-static"
                                label="Message"
                                multiline
                                rows={10}
                                variant="outlined"
                                fullWidth
                                {...input}
                              />
                            )}
                          </Field>
                          <Error name="message" />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      display="flex"
                      pb={3}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        disabled={submitting || pristine || invalid}
                        type="submit"
                      >
                        {submitting ? 'Sending' : 'Send'}
                      </Button>
                    </Box>
                  </form>
                )}
              </Form>
            </Box>
          </DialogContent>
        </Paper>
      </Dialog>
    </>
  );
}

export default ComposeDialog;
