import React from 'react';
import Container from '@material-ui/core/Container';
import { Switch, Route, Redirect } from 'react-router-dom';
import Messages from 'components/PatientMessages';
import WorkList from 'components/WorkList';
import Chart from 'components/Chart';
import Bar from 'components/AppBar';
import { useRedirect, useUnloadSignOut } from 'utils/hooks';
import { useAuth } from 'context/auth-context';
import Idle from 'react-idle';
import Footer from 'components/Footer';

function renderRoutes(view) {
  switch (view) {
    case 'OWN': {
      return (
        <>
          <Route path="/worklist">
            <WorkList />
          </Route>
          <Route exact path="/">
            <Messages />
          </Route>
        </>
      );
    }
    case 'ALL': {
      return (
        <Route path="/">
          <WorkList />
        </Route>
      );
    }
    default: {
      return Error('Invalid view facility type');
    }
  }
}

function App() {
  const {
    logout,
    configSettings: {
      user: { facilityViewType },
    },
  } = useAuth();

  useUnloadSignOut();

  const redirectChart = useRedirect({
    path: '/chart/:id',
    exact: true,
  });

  return (
    // eslint-disable-next-line react/jsx-fragments
    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Container
        maxWidth="xl"
        disableGutters
        style={{
          flexGrow: 1,
        }}
      >
        <Bar />
        <Switch>
          <Route path="/chart/:id">
            {redirectChart ? <Redirect to="/worklist" /> : <Chart />}
          </Route>
          {renderRoutes(facilityViewType)}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <Idle
          timeout={1000 * 60 * 10}
          onChange={({ idle }) => {
            if (idle) {
              logout('UI:SignOut', 'Auto Sign Out due to user inactivity.');
            }
          }}
        />
      </Container>

      <Footer />
    </div>
  );
}

export default App;
