import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/Inbox';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import PeopleIcon from '@material-ui/icons/People';
import SvgIcon from '@material-ui/core/SvgIcon';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'context/auth-context';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import { ReactComponent as Logo } from 'assets/Chart-Access-Logo.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    bar: {
      minHeight: 70,
      '& > *': {
        minHeight: 70,
      },
      // zIndex: 11,
    },
    BannerBar: {
      background: 'white',
      maxHeight: 70,
      '& > *': {
        maxHeight: 70,
      },
      // zIndex: 11,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: 250,
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
    },
    message: {
      [theme.breakpoints.up('md')]: {
        marginLeft: 225,
      },
    },
    login: {
      flexGrow: 1,
      justifyContent: 'flex-end',
      display: 'flex',
    },
    active: {
      backgroundColor: '#3757FF',
    },
    barItem: {
      color: '#D9D9E4',
      fontSize: 14,
      fontWeight: 600,
      paddingLeft: 30,
      paddingRight: 30,
    },
    poweredByJase: {
      fontSize: 14,
      textAlign: 'left',
      paddingLeft: 5,
    },
    logo: {
      minWidth: 225,
    },
    homeIcon: {
      paddingRight: 10,
    },
    signOut: {
      zIndex: theme.zIndex.drawer - 100,
    },
  }),
);

export default function ButtonAppBar() {
  const classes = useStyles();
  const match = useRouteMatch('/chart/:id');
  const { user, logout, configSettings } = useAuth();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);
  const handleClose = event => {
    if (anchorRef.current && anchorRef?.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSignOut = () => {
    logout('UI:SignOut', 'Sign Out Button');
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const displayMessages = configSettings.user.facilityViewType === 'OWN';

  return (
    <div className={classes.root}>
      <AppBar position="fixed" classes={{ root: classes.bar }}>
        <Toolbar classes={{ root: classes.BannerBar }}>
          <Box style={{ marginLeft: 226, flexGrow: 1, color: 'black' }}>
            <img src={configSettings.logo} alt={configSettings.name} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            color="action.disabled"
            className={classes.barItem}
          >
            <IconButton
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              ref={anchorRef}
            >
              <SvgIcon color="disabled">
                <path
                  d="M7 9.75C6.31 9.75 5.75 10.31 5.75 11C5.75 11.69 6.31 12.25 7 12.25C7.69 12.25 8.25 11.69 8.25 11C8.25 10.31 7.69 9.75 7 9.75ZM13 9.75C12.31 9.75 11.75 10.31 11.75 11C11.75 11.69 12.31 12.25 13 12.25C13.69 12.25 14.25 11.69 14.25 11C14.25 10.31 13.69 9.75 13 9.75ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 9.71 2.02 9.42 2.05 9.14C4.41 8.09 6.28 6.16 7.26 3.77C9.07 6.33 12.05 8 15.42 8C16.2 8 16.95 7.91 17.67 7.74C17.88 8.45 18 9.21 18 10C18 14.41 14.41 18 10 18Z"
                  fill="#979DB0"
                />
              </SvgIcon>
            </IconButton>
            <Popper
              className={classes.signOut}
              open={open}
              anchorEl={anchorRef.current}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Typography variant="body2">
              {user ? user.username : null}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.barItem}>
            <HomeWorkRoundedIcon className={classes.homeIcon} />
            <Typography className={classes.poweredByJase}>
              {user.facilityName}
            </Typography>
          </Box>
          <Box className={`${classes.barItem} ${classes.logo}`}>
            <Logo />
            <Typography className={classes.poweredByJase}>
              Powered by Jase Health Solutions
            </Typography>
          </Box>
        </Toolbar>
        <Toolbar classes={{ root: classes.bar }}>
          {displayMessages && (
            <NavLink
              exact
              className={`${classes.title} ${classes.message}`}
              activeClassName={classes.active}
              to="/"
            >
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="messages"
              >
                <InboxIcon />
              </IconButton>
              <Typography variant="body2">Messages</Typography>
            </NavLink>
          )}
          <NavLink
            exact
            className={clsx(classes.title, {
              [classes.message]: !displayMessages,
            })}
            activeClassName={classes.active}
            to={displayMessages ? '/worklist' : '/'}
          >
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="work list"
            >
              <PeopleIcon />
            </IconButton>
            <Typography variant="body2">Work List</Typography>
          </NavLink>
          {match && (
            <NavLink
              className={classes.title}
              activeClassName={classes.active}
              to="/chart"
            >
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="chart"
              >
                <SvgIcon
                  width="24"
                  height="24"
                  style={{ color: 'rgba(0, 0, 0, 0.04)' }}
                >
                  <path
                    d="M20 6H12L10 4H4C3.4713 4.00264 2.96516 4.21452 2.59225 4.58931C2.21933 4.9641 2.00999 5.4713 2.01 6L2 18C2.00158 18.5299 2.2128 19.0377 2.58753 19.4125C2.96227 19.7872 3.47005 19.9984 4 20H20C20.5299 19.9984 21.0377 19.7872 21.4125 19.4125C21.7872 19.0377 21.9984 18.5299 22 18V8C21.9984 7.47005 21.7872 6.96227 21.4125 6.58753C21.0377 6.2128 20.5299 6.00158 20 6ZM15 9C15.3956 9 15.7822 9.1173 16.1111 9.33706C16.44 9.55682 16.6964 9.86918 16.8478 10.2346C16.9991 10.6001 17.0387 11.0022 16.9616 11.3902C16.8844 11.7781 16.6939 12.1345 16.4142 12.4142C16.1345 12.6939 15.7781 12.8844 15.3902 12.9616C15.0022 13.0387 14.6001 12.9991 14.2346 12.8478C13.8692 12.6964 13.5568 12.44 13.3371 12.1111C13.1173 11.7822 13 11.3956 13 11C13.0016 10.4701 13.2128 9.96227 13.5875 9.58753C13.9623 9.2128 14.4701 9.00158 15 9ZM19 17H11V16C11 14.67 13.67 14 15 14C16.33 14 19 14.67 19 16V17Z"
                    fill="white"
                  />
                </SvgIcon>
              </IconButton>
              <Typography variant="body2">Chart</Typography>
            </NavLink>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
