/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-syntax */
import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Form, Field, FormSpy, useField } from 'react-final-form';
import Close from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { WorkListInterface } from 'utils/worklist';
import Alert from '@material-ui/lab/Alert';
import { Error } from 'components/ErrorField';
import { useForm, useSaveForLater, useInprogressForm } from 'utils/form';
import Temporary from 'components/Temporary';
import { formatDateTime } from 'utils/dateFormat';
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  TextareaAutosize,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import { isArray, isString } from 'util';
import { useAuth } from 'context/auth-context';

const useStyles = makeStyles(theme => ({
  title: {
    flex: '1 1',
    textAlign: 'center',
  },
  paper: {
    maxHeight: 200,
    minHeight: 200,
    padding: theme.spacing(1),
  },
  saved: {
    color: theme.palette.action.disabled,
  },
}));

function orderDatesDesc(arr = []) {
  return arr
    .slice()
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
}

interface PreVisitFormProps {
  setOpen: Function;
  workListItem: any; //WorkListInterface | undefined;
  signForm: Function;
  editForm: Function;
  removeForm: Function;
}

function Spinner() {
  return (
    <Container>
      <Box
        component="div"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 380,
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
}

const useSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const composeValidators =
  (...validators) =>
  value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

const required = value => {
  if (Array.isArray(value) && !value.length) {
    return 'Required';
  }
  if (value) {
    return undefined;
  }
  return 'Required';
};

const feasibleRange = (low, high) => value => {
  if (low === 0 && high === 0) {
    return undefined;
  }
  return (high >= Number(value) && Number(value) >= low) || value === undefined
    ? undefined
    : 'The value entered is outside the feasible range.';
};

const mustBeNumber = value => {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(value) || value === undefined ? undefined : 'Must be a number';
};

function getValidations(input) {
  let validations: any[] = [];
  if (input.required) {
    validations = [required];
  }
  if (input.fieldType === 'numeric') {
    const high = Number(input.dta[0].refRangeFactor[0].feasibleHigh);
    const low = Number(input.dta[0].refRangeFactor[0].feasibleLow);
    validations = [...validations, mustBeNumber];
    validations = [...validations, feasibleRange(low, high)];
  }
  return composeValidators(...validations);
}

function SelectField({ inputData, editing }: any) {
  const classes = useSelectStyles();
  const validate = useMemo(() => getValidations(inputData), [inputData]);

  return (
    <>
      <Field name={`input-${inputData.dta[0].code}`} validate={validate}>
        {({ input, meta }) => (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id={`input-${inputData.dta[0].code}-select-label`}>
                Select
              </InputLabel>
              <Select {...input} label="Select" fullWidth disabled={editing}>
                {inputData.dta[0].refRangeFactor[0]?.alphaResponse.map(obj => (
                  <MenuItem key={obj.sequence} value={obj.shortString}>
                    {obj.shortString}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Error name={`input-${inputData.dta[0].code}`} />
          </>
        )}
      </Field>
    </>
  );
}

const useAlphaStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 'auto',
    },
    scrollbar: {
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-scrollbar:vertical': {
        width: 8,
      },
      '&::-webkit-scrollbar:horizontal': {
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        border:
          '1px solid #ffffff' /* should match background, can't be transparent */,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  }),
);

function isScrollable(data) {
  return data?.length > 3;
}

function AlphaField({ input, editing, initialValues }: FieldInterface) {
  const classes = useAlphaStyles();
  const [checked, setChecked] = useState(false);
  const validate = useMemo(() => getValidations(input), [input]);

  const { input: otherInput } = useField(`other-${input.dta[0].code}`);

  React.useEffect(() => {
    if (initialValues[`other-${input.dta[0].code}`]) {
      setChecked(true);
    }
  }, [initialValues[`other-${input.dta[0].code}`]]);

  if (input.multiSelect) {
    return (
      <div
        style={{ maxHeight: 180, overflow: 'scroll' }}
        className={clsx({
          [classes.scrollbar]: isScrollable(
            input.dta[0]['refRangeFactor'][0]['alphaResponse'],
          ),
        })}
      >
        <List>
          {input.dta[0]['refRangeFactor'][0]['alphaResponse'].map(
            (object, index) => (
              <ListItem button key={index}>
                <ListItemIcon classes={{ root: classes.root }}>
                  <Field
                    name={`input-${input.dta[0].code}`}
                    type="checkbox"
                    value={object.shortString}
                    validate={validate}
                  >
                    {({ input, meta }) => {
                      return (
                        <Checkbox
                          {...input}
                          size="small"
                          color="primary"
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          disabled={editing}
                        />
                      );
                    }}
                  </Field>
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2" color="textSecondary">
                    {object.shortString}
                  </Typography>
                </ListItemText>
              </ListItem>
            ),
          )}
          {input.freeText && (
            <Field name={`other-${input.dta[0].code}`}>
              {({ input }) => {
                return (
                  <ListItem button key="other">
                    <ListItemIcon classes={{ root: classes.root }}>
                      <Checkbox
                        size="small"
                        color="primary"
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        onClick={() => {
                          if (checked) {
                            input.onChange('');
                          }
                          setChecked(!checked);
                        }}
                        checked={checked}
                      />
                    </ListItemIcon>
                    {!checked && (
                      <ListItemText>
                        <Typography variant="body2" color="textSecondary">
                          Other
                        </Typography>
                      </ListItemText>
                    )}

                    <Box
                      display={clsx({
                        ['none']: !checked,
                      })}
                    >
                      <ListItemText>
                        <TextField
                          {...input}
                          id="free-text"
                          label="Other"
                          variant="outlined"
                          disabled={editing}
                        />
                      </ListItemText>
                    </Box>
                  </ListItem>
                );
              }}
            </Field>
          )}
          <Box px={2}>
            <Error name={`input-${input.dta[0].code}`} />
          </Box>
        </List>
      </div>
    );
  }

  return <SelectField inputData={input} editing={editing} />;
}

function NumericField(props: FieldInterface) {
  const { input: inputDta, editing } = props;
  const validate = useMemo(() => getValidations(inputDta), [inputDta]);

  return (
    <Field name={`input-${inputDta.dta[0].code}`} validate={validate}>
      {({ input, meta }) => {
        return (
          <div>
            <Box display="flex" alignItems="center" mt={2}>
              <TextField
                id="free-text"
                variant="outlined"
                {...input}
                disabled={editing}
              />
              <Box ml={1}>
                <Typography color="textSecondary">
                  {inputDta.dta[0].refRangeFactor[0].unitsOfMeasure.display}
                </Typography>
              </Box>
            </Box>
            <Error name={`input-${inputDta.dta[0].code}`} />
          </div>
        );
      }}
    </Field>
  );
}

function DateField({ input, editing }: FieldInterface) {
  const validate = useMemo(() => getValidations(input), [input]);
  // yyyy-mm-dd
  return (
    <Box mt={2} style={{ maxWidth: 220 }}>
      <Field name={`input-${input.dta[0].code}`} validate={validate}>
        {({ input, meta }) => (
          <TextField
            {...input}
            id="date"
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={editing}
          />
        )}
      </Field>
      <Error name={`input-${input.dta[0].code}`} />
    </Box>
  );
}

interface FieldInterface {
  input: any;
  editing: boolean;
  initialValues?: any;
}

function FreeTextField({ input, editing }: FieldInterface) {
  const validate = useMemo(() => getValidations(input), [input]);
  return (
    <Box mt={2}>
      <Field name={`input-${input.dta[0].code}`} validate={validate} disabled>
        {({ input }) => (
          <TextareaAutosize
            {...input}
            aria-label="Text area"
            rowsMin={7}
            rowsMax={8}
            disabled={editing}
            style={{ fontFamily: 'inherit' }}
          />
        )}
      </Field>
      <Error name={`input-${input.dta[0].code}`} />
    </Box>
  );
}

function getFieldType(input, editing, initialValues) {
  switch (input.fieldType) {
    case 'alpha':
      return (
        <AlphaField
          input={input}
          editing={editing}
          initialValues={initialValues}
        />
      );
    case 'numeric':
      return <NumericField input={input} editing={editing} />;
    case 'date':
      return <DateField input={input} editing={editing} />;
    case 'freetext':
      return <FreeTextField input={input} editing={editing} />;
    default:
      return null;
  }
}

function renderFormFields(input: any, editing: boolean, initialValues: any) {
  return (
    <div>
      <Typography variant="subtitle2" align="center">
        {input.description}
        {input.required && (
          <Typography style={{ color: 'red', display: 'inline' }}>*</Typography>
        )}
      </Typography>
      <div>{getFieldType(input, editing, initialValues)}</div>
    </div>
  );
}

interface SaveForLaterInterface {
  isLoading: boolean;
  isSuccess: boolean;
}

function SaveForLater({ isLoading, isSuccess }: SaveForLaterInterface) {
  function isSaveProgressValid(values, errors) {
    let result: any[] = [];
    for (const key in values) {
      if (errors[key]) {
        result = [...result, errors[key]];
      }
    }
    if (result.length) {
      return false;
    }
    return true;
  }

  return (
    <FormSpy
      subscription={{
        values: true,
        errors: true,
        pristine: true,
      }}
    >
      {({ values, errors, pristine, form }) => (
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            disabled={
              isLoading ||
              isSuccess ||
              pristine ||
              !isSaveProgressValid(values, errors)
            }
            onClick={() => form.change('action', 'progress')}
          >
            {isLoading ? 'Saving...' : 'Save For Later'}
          </Button>
        </Grid>
      )}
    </FormSpy>
  );
}

function isComplete(value): boolean {
  return value?.status === 'complete';
}

function disabled(completed, editing) {
  return completed && !editing;
}

interface confirmDialogInterface {
  open: boolean;
  setOpen: Function;
  handleRemove: Function;
}

function ConfirmDialog({
  open,
  setOpen,
  handleRemove,
}: confirmDialogInterface) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove Form?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The form will be removed from this patient.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleRemove();
            }}
            color="primary"
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function isFalsy(value) {
  if (isArray(value)) {
    if (value.length === 0) {
      return true;
    }
    return false;
  }
  if (isString(value)) {
    const data = value.replace(/\s/g, '');
    if (data.length === 0) {
      return true;
    }
    return false;
  }
  if (value === null || value === undefined) {
    return true;
  }
  return false;
}

function PreVisitForm(props: PreVisitFormProps) {
  const { setOpen, signForm, workListItem, editForm, removeForm } = props;
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const {
    configSettings: { config },
  } = useAuth();

  const { data: formData, isLoading } = useForm({
    Dob: workListItem?.patient.dob,
    Gender: workListItem?.patient.gender,
    FormType: 'Pre Visit',
  });

  const [saveForLater, { isSuccess, isLoading: isSaving, reset }] =
    useSaveForLater({
      throwOnError: true,
    });

  const { data: initialValues, isLoading: isFormProgressLoading } =
    useInprogressForm({
      formRefId: formData?.formRefId,
      patientId: workListItem?.patient.patientId,
      encounterId: workListItem?.nextVisit?.encounterId,
      enabled:
        Boolean(formData?.formRefId) &&
        Boolean(workListItem?.patient.patientId) &&
        Boolean(workListItem?.nextVisit?.encounterId),
      retry: false,
    } as any);
  const [editing, setEditing] = useState<boolean>(false);

  const classes = useStyles();

  function getFormData() {
    return formData.section[0].input.reduce((result, current) => {
      return { ...result, [current.dta[0].code]: current };
    }, {});
  }

  function getNomenclature(input, value): Number | null {
    const object = input.dta[0].refRangeFactor[0].alphaResponse.find(
      item => item.shortString === value,
    );
    return object ? object.nomenclatureId : null;
  }

  function formatResultValues(input, type, value): any[] {
    if (input.fieldType === 'alpha') {
      if (input.multiSelect) {
        if (type === 'freetext') {
          return [
            {
              value,
              format: type,
              nomenclatureId: 0,
            },
          ];
        }
        return value.map(item => ({
          value: item,
          format: type,
          nomenclatureId: getNomenclature(input, item),
        }));
      }
      return [
        {
          value,
          format: type,
          nomenclatureId: getNomenclature(input, value),
        },
      ];
    }

    return [{ value, format: type }];
  }

  interface resultValues {
    strings: any[];
    numerics: any[];
    dates: any[];
    alphas: any[];
  }

  function formatFormValues(values) {
    const resultData = getFormData();
    let fieldType: string = '';

    const resultValues: resultValues = {
      strings: [],
      numerics: [],
      dates: [],
      alphas: [],
    };

    for (const key in values) {
      const dtaCode = key.split('-')[1];
      const dtaName = key.split('-')[0];
      const input = resultData[dtaCode];
      if (dtaName === 'other') {
        fieldType = 'freetext';
      } else {
        fieldType = input.fieldType;
      }

      switch (fieldType) {
        case 'alpha':
          if (input.multiSelect) {
            resultValues.alphas.push({ key, value: values[key] });
            break;
          }
          resultValues.strings.push({ key, value: values[key] });
          break;
        case 'numeric':
          resultValues.numerics.push({
            key,
            value: String(values[key]) ?? null,
          });
          break;
        case 'date':
          resultValues.dates.push({ key, value: values[key] });
          break;
        case 'freetext':
          resultValues.strings.push({ key, value: values[key] });
          break;
        default:
          return null;
      }
    }
    return resultValues;
  }

  function parseFormValues(values: resultValues) {
    const result = {};
    for (const key in values) {
      values[key].forEach(obj => {
        result[obj.key] = obj.value;
      });
    }
    return result;
  }

  function hasNextVisit(item) {
    return item.nextVisit && Boolean(item.nextVisit?.encounterId);
  }

  function getEditResultValues(dirtyFields, values) {
    let result: any[] = [];
    for (const key in dirtyFields) {
      const dtaCode = key.split('-')[1];
      const found = values.find(v => Number(v.dtaCode) === Number(dtaCode));
      if (found) {
        result.push(found);
      }
    }
    return result;
  }

  function getEditDeleteValues(dirtyFields, values) {
    const formData = getFormData();
    let result: any[] = [];

    for (const key in dirtyFields) {
      const dtaCode = key.split('-')[1];
      const inputData = formData[dtaCode];
      const found = values.find(
        v => Number(v.eventCode) === Number(inputData.dta[0].eventCode.code),
      );
      if (found) {
        result.push(found);
      }
    }
    return result;
  }

  function formatData(values) {
    const resultData = getFormData();
    let resultValues: any[] = [];
    let deleteValues: any[] = [];
    let fieldType: string = '';

    for (const key in values) {
      const dtaCode = key.split('-')[1];
      const dtaName = key.split('-')[0];
      const input = resultData[dtaCode];
      let found = false;

      if (dtaName === 'other') {
        fieldType = 'freetext';
      } else {
        fieldType = input.fieldType;
      }

      // eslint-disable-next-line no-loop-func
      resultValues = resultValues.map(item => {
        if (Number(item.dtaCode) === Number(dtaCode)) {
          found = true;
          return {
            ...item,
            resultValues: [
              ...item.resultValues,
              ...formatResultValues(input, fieldType, values[key]),
            ],
          };
        }
        return item;
      });
      if (!found) {
        const inputValue = {
          eventCode: Number(input.dta[0].eventCode.code),
          dtaCode: input.dta[0].code,
          unitCode: Number(input.dta[0].refRangeFactor[0].unitsOfMeasure.code),
          resultValues: formatResultValues(input, fieldType, values[key]),
        };
        resultValues = [...resultValues, inputValue];
      } else {
        found = false;
      }

      if (editing) {
        const eventCode = input.dta[0].eventCode.code;
        const filtered = initialValues.eventIds.filter(
          item => Number(item.eventCode) === Number(eventCode),
        );
        deleteValues = deleteValues.concat(filtered);

        if (isFalsy(values[key])) {
          delete values.key;
        }
      }
    }
    return { resultValues, deleteValues };
  }

  function parseInitialValues(data): any {
    return data.values ? parseFormValues(data.values) : {};
  }

  const onSubmit = async (values, form) => {
    const formValues = formatData(values);

    const data = {
      formRefId: formData.formRefId,
      patientId: workListItem?.patient.patientId,
      encounterId: workListItem?.nextVisit?.encounterId,
      values: formatFormValues(values),
      inputJson: {
        encounterId: workListItem?.nextVisit?.encounterId,
        performPersonId: config.systemUserId,
        results: formValues.resultValues,
      },
    };
    if (editing) {
      const dirtyFields = form.getState().dirtyFields;
      const editData = {
        ...data,
        inputJson: {
          ...data.inputJson,
          results: getEditResultValues(dirtyFields, formValues.resultValues),
        },
        deleteValues: getEditDeleteValues(dirtyFields, formValues.deleteValues),
      };
      editForm(editData);
      setEditing(false);
    } else {
      signForm(data);
    }
    setOpen(false);
  };

  function handleRemove() {
    const data = {
      formRefId: formData.formRefId,
      patientId: workListItem?.patient.patientId,
      encounterId: workListItem?.nextVisit?.encounterId,
      performPersonId: config.systemUserId,
      deleteValues: initialValues.eventIds,
    };
    removeForm(data);
    setConfirmDialog(false);
    setOpen(false);
  }

  if (isLoading || isFormProgressLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Box display="flex" mb={4} mt={4}>
        <Typography variant="subtitle2" className={classes.title}>
          {formData?.formName}
        </Typography>
        <Close
          color="primary"
          fontSize="small"
          onClick={() => setOpen(false)}
          style={{ cursor: 'pointer' }}
        />
      </Box>

      {hasNextVisit(workListItem) && (
        <Box mb={3}>
          <Typography variant="subtitle2" className={classes.saved}>
            Encounter: {formatDateTime(workListItem.nextVisit.date)},{' '}
            {workListItem.nextVisit.unitLocation.display},{' '}
            {workListItem.nextVisit.type.display}
          </Typography>
        </Box>
      )}
      <Box>
        {initialValues?.signedDtTm?.length !== 0 &&
          orderDatesDesc(initialValues?.signedDtTm)?.map(date => (
            <Box mb={3}>
              <Typography variant="subtitle2" className={classes.saved}>
                Signed {formatDateTime(date)} by {initialValues?.userName}
              </Typography>
            </Box>
          ))}

        {initialValues?.lastSavedDtTm && (
          <Box mb={3}>
            <Typography variant="subtitle2" className={classes.saved}>
              Last Saved {formatDateTime(initialValues.lastSavedDtTm)} by{' '}
              {initialValues?.userName}
            </Typography>
          </Box>
        )}
      </Box>
      <Form
        initialValues={parseInitialValues(initialValues ?? {})}
        onSubmit={onSubmit}
        subscription={{
          pristine: true,
          invalid: true,
          submitting: true,
        }}
      >
        {({ handleSubmit, pristine, submitting, invalid, form }) => (
          <form
            onSubmit={e => {
              if (form.getState().values.action === 'progress') {
                e.preventDefault();
                const { values } = form.getState();
                delete values.action;
                return saveForLater({
                  values: formatFormValues(values),
                  formRefId: formData.formRefId,
                  patientId: workListItem?.patient.patientId,
                  encounterId: workListItem?.nextVisit.encounterId,
                });
              }
              return handleSubmit(e);
            }}
          >
            <Grid container justify="center" spacing={2}>
              {formData?.section[0].input.map((input, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  <Paper className={classes.paper}>
                    {renderFormFields(
                      input,
                      disabled(isComplete(initialValues), editing) ||
                        !hasNextVisit(workListItem),
                      parseInitialValues(initialValues ?? {}),
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Box mt={4}>
              {isComplete(initialValues) && !editing ? (
                <Grid container justify="center" spacing={4}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setEditing(true)}
                    >
                      Edit Form
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setConfirmDialog(!confirmDialog)}
                    >
                      Remove Form
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container justify="center" spacing={4}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={
                        submitting || invalid || !hasNextVisit(workListItem)
                      }
                    >
                      Sign Form
                    </Button>
                  </Grid>
                  <SaveForLater isLoading={isSaving} isSuccess={isSuccess} />
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="secondary"
                      type="button"
                      disabled={pristine}
                      onClick={form.reset}
                    >
                      Reset Form
                    </Button>
                  </Grid>
                  {editing && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        type="button"
                        disabled={false}
                        onClick={() => {
                          form.reset();
                          setEditing(false);
                        }}
                      >
                        Cancel Edit
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Box>
            {isSuccess && (
              <Box mt={4}>
                <Temporary cb={() => reset()}>
                  <Alert severity="success">The form has been saved!</Alert>
                </Temporary>
              </Box>
            )}
          </form>
        )}
      </Form>
      <ConfirmDialog
        open={confirmDialog}
        setOpen={setConfirmDialog}
        handleRemove={handleRemove}
      />
    </>
  );
}

export default PreVisitForm;
