import { Auth } from 'aws-amplify';

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    identityPoolId: '',
    userPoolId: process.env.REACT_APP_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_API_URL,
        region: 'us-east-1',
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
            'X-API-KEY': process.env.REACT_APP_X_API_KEY,
          };
        },
      },
    ],
  },
};

export default awsConfig;
