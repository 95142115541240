/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo } from 'react';
import Container from '@material-ui/core/Container';
import {
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { useParams } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Link } from 'react-scroll';
import { flow } from 'lodash';
import { WorkListInterface, useWrokListItemFromCache } from 'utils/worklist';
import { formatDOBDate, formatDate } from 'utils/dateFormat';
import { useAllergies, Allergy } from 'utils/allergies';
import { useProblems, Problem } from 'utils/problems';
import { usePendingOrders, PendingOrder } from 'utils/pendingOrders';
import { useVitals, Vital } from 'utils/vitals';
import { useLabResults, labResult } from 'utils/labResults';
import { useImmunizations, Immunization } from 'utils/immunizations';
import { useVisits, Visit } from 'utils/visits';
import {
  useDiagnosticReports,
  useDiagnosticReport,
  Diagnostic,
} from 'utils/diagnostics';
import { useNotes, Note, useNotesDocument } from 'utils/notes';
import { useMedications } from 'utils/medications';
import PreVisitForm from 'components/PreVisitForm';
import { useSignForm, useEditForm, useRemoveForm } from 'utils/form';
import ScrollDialog from 'components/DocumentDialog';
import { useAudit } from 'utils/audit';
import { useAuth } from 'context/auth-context';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  container: {
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
  grid: {
    padding: theme.spacing(2),
  },
  menuButton: {
    marginLeft: theme.spacing(1),
  },
  toolbar: { ...theme.mixins.toolbar, marginTop: theme.spacing(1) },
  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    '& :nth-child(2)': {
      marginLeft: theme.spacing(1),
    },
  },
  patient: {
    height: 64,
    flexGrow: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
  },
  patientInfo: {
    '& > *': { flex: '1 1' },
  },
  navigation: {
    '& > *': {
      flex: '1 1 auto',
      marginLeft: theme.spacing(1),
      color: theme.palette.action.disabled,
    },
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(1),
  },
  button: {
    justifyContent: 'center',
  },
  filter: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  stickyBar: {
    position: 'sticky',
    zIndex: 10,
    background: 'white',
  },
}));

function Chart() {
  const classes = useStyles();
  const { id } = useParams();
  const itemCache = useWrokListItemFromCache(id);
  const data: any = itemCache?.state.data || undefined;
  const [open, setOpen] = useState<boolean>(false);
  const {
    data: allergies,
    isLoading: isAllergiesLoading,
    error: allergiesError,
  } = useAllergies({
    personId: Number(data?.patient?.patientId),
  });
  const {
    data: problems,
    isLoading: isProblemsLoading,
    error: problemsError,
  } = useProblems({
    personId: Number(data?.patient?.patientId),
  });
  const {
    data: labResults,
    isLoading: isLabResultsLoading,
    error: labResultsError,
  } = useLabResults({
    personId: Number(data?.patient?.patientId),
  });
  const {
    data: vitals,
    isLoading: isVitalsLoading,
    error: vitalsError,
  } = useVitals({
    personId: Number(data?.patient?.patientId),
  });

  const {
    data: visits,
    isLoading: isVisitsLoading,
    error: visitsError,
  } = useVisits({
    personId: Number(data?.patient?.patientId),
  });

  const {
    data: immunizations,
    isLoading: isImmunizationsLoading,
    error: immunizationsError,
  } = useImmunizations({
    personId: Number(data?.patient?.patientId),
  });

  const {
    data: pendingOrders,
    isLoading: isPendingLoading,
    error: pendingOrdersError,
  } = usePendingOrders({
    personId: Number(data?.patient?.patientId),
  });

  const {
    data: medications,
    isLoading: isMedicationLoading,
    error: medicationsError,
  } = useMedications({
    personId: Number(data?.patient?.patientId),
  });

  const {
    data: notes,
    isLoading: isNotesLoading,
    error: notesError,
  } = useNotes({
    personId: Number(data?.patient?.patientId),
  });

  const {
    data: diagnostics,
    isLoading: isDiagnosticsLoading,
    error: diagnosticsError,
  } = useDiagnosticReports({
    personId: Number(data?.patient?.patientId),
  });

  const [
    signForm,
    {
      isSuccess: isSuccessSignForm,
      reset: resetSignForm,
      isError: isErrorSignForm,
    },
  ] = useSignForm({
    throwOnError: true,
  });

  const [
    editForm,
    {
      isSuccess: isSuccessEditForm,
      reset: resetEditForm,
      isError: isErrorEditForm,
    },
  ] = useEditForm();

  const [
    removeForm,
    {
      isSuccess: isSuccessRemoveForm,
      reset: resetRemoveForm,
      isError: isErrorRemoveForm,
    },
  ] = useRemoveForm();

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (isSuccessRemoveForm || isErrorRemoveForm) {
      resetRemoveForm();
    } else if (isSuccessSignForm || isErrorSignForm) {
      resetSignForm();
    } else {
      resetEditForm();
    }
  };

  return (
    <Container maxWidth="lg" disableGutters className={classes.container}>
      <Toolbar className={classes.toolbar} />
      <Toolbar />
      <PatientInfo workListItem={data} setOpen={setOpen} />
      <Navigation />
      <Grid container item sm={12}>
        <Grid
          item
          container
          spacing={2}
          className={classes.grid}
          sm={open ? 6 : 12}
        >
          <Grid item xs={12} sm={open ? 12 : 6} id="Allergies">
            <AllergiesTable
              allergies={allergies}
              isLoading={isAllergiesLoading}
              error={allergiesError}
            />
          </Grid>

          <Grid item xs={12} sm={open ? 12 : 6} id="Problems">
            <ProblemsTable
              problems={problems}
              isLoading={isProblemsLoading}
              error={problemsError}
            />
          </Grid>

          <Grid item xs={12} id="Medications">
            <MedicationsTable
              medications={medications}
              isLoading={isMedicationLoading}
              error={medicationsError}
            />
          </Grid>
          <Grid item xs={12} sm={open ? 12 : 6} id="PendingOrders">
            <PendingOrdersTable
              pendingOrders={pendingOrders}
              isLoading={isPendingLoading}
              error={pendingOrdersError}
            />
          </Grid>
          <Grid item xs={12} sm={open ? 12 : 6} id="Vitals">
            <VitalsTable
              vitals={vitals}
              isLoading={isVitalsLoading}
              error={vitalsError}
            />
          </Grid>
          <Grid item xs={12} sm={open ? 12 : 6} id="LabResults">
            <LabResultsTable
              labResults={labResults}
              isLoading={isLabResultsLoading}
              error={labResultsError}
            />
          </Grid>
          <Grid item xs={12} sm={open ? 12 : 6} id="Immunizations">
            <ImmunizationsTable
              immunizations={immunizations}
              isLoading={isImmunizationsLoading}
              error={immunizationsError}
            />
          </Grid>
          <Grid item xs={12} sm={open ? 12 : 6} id="visits">
            <VisitsTable
              visits={visits}
              isLoading={isVisitsLoading}
              error={visitsError}
            />
          </Grid>
          <Grid item xs={12} sm={open ? 12 : 6} id="DiagnosticReports">
            <DiagnosticsTable
              diagnostics={diagnostics}
              isLoading={isDiagnosticsLoading}
              error={diagnosticsError}
              patientId={Number(data?.patient?.patientId)}
              patient={data?.patient}
            />
          </Grid>
          <Grid item xs={12} id="Notes">
            <NotesTable
              notes={notes}
              isLoading={isNotesLoading}
              error={notesError}
              patientId={Number(data?.patient?.patientId)}
              patient={data?.patient}
            />
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Collapse in={open} timeout={0}>
            <PreVisitForm
              setOpen={setOpen}
              workListItem={data}
              signForm={signForm}
              editForm={editForm}
              removeForm={removeForm}
            />
          </Collapse>
        </Grid> */}
        {open && (
          <Grid item xs={12} sm={6}>
            <PreVisitForm
              setOpen={setOpen}
              workListItem={data}
              signForm={signForm}
              editForm={editForm}
              removeForm={removeForm}
            />
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={isSuccessSignForm}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          The form has been signed successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSuccessEditForm}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          The form has been edited successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSuccessRemoveForm}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="success">
          The form has been removed.
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorSignForm || isErrorEditForm || isErrorRemoveForm}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity="error">
          Something went wrong. Try again later.
        </Alert>
      </Snackbar>
    </Container>
  );
}
interface PatientInfoInterface {
  workListItem: WorkListInterface | undefined;
  setOpen: Function;
}
function PatientInfo(props: any) {
  const classes = useStyles();
  const { workListItem, setOpen } = props;

  return (
    <Box
      py={1}
      px={2}
      className={[classes.patient, classes.stickyBar].join(' ')}
      style={{ top: 140 }}
      display="flex"
    >
      <Box display="flex" flex="1 1">
        <Box className={classes.text} flexGrow="1">
          <Typography variant="subtitle2">Name:</Typography>
          <Typography variant="body2">{workListItem?.patient.name}</Typography>
        </Box>
        <Box display="flex" flex="auto">
          <Box className={classes.text} flexGrow="inherit">
            <Typography variant="subtitle2">DOB:</Typography>
            <Typography variant="body2">
              {workListItem &&
                formatDOBDate(new Date(workListItem.patient.dob))}
            </Typography>
          </Box>
          <Box className={classes.text} flexGrow="inherit">
            <Typography variant="subtitle2">Age:</Typography>
            <Typography variant="body2">{workListItem?.patient.age}</Typography>
          </Box>
          <Box className={classes.text} flexGrow="inherit">
            <Typography variant="subtitle2">Gender:</Typography>
            <Typography variant="body2">
              {workListItem?.patient.gender}
            </Typography>
          </Box>
          <Box className={classes.text} flexGrow="inherit">
            <Typography variant="subtitle2">MRN:</Typography>
            <Typography variant="body2">{workListItem?.patient.mrn}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.text}>
        <Typography variant="subtitle2">Pre Visit Form</Typography>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setOpen(open => !open)}
        >
          <AddCircleIcon color="disabled" />
        </IconButton>
      </Box>
    </Box>
  );
}

function Spinner() {
  return (
    <Container>
      <Box
        component="div"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 380,
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
}

function Navigation() {
  const classes = useStyles();

  return (
    <Box
      py={1}
      px={2}
      className={[classes.patient, classes.stickyBar].join(' ')}
      style={{ top: 221 }}
      display="flex"
    >
      <Box
        display="flex"
        flex="1 1"
        alignItems="center"
        className={classes.navigation}
      >
        <Link to="Allergies" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Allergies
            </Typography>
          </Button>
        </Link>
        <Link to="Problems" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Problems
            </Typography>
          </Button>
        </Link>
        <Link to="Medications" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Medications
            </Typography>
          </Button>
        </Link>
        <Link to="PendingOrders" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Pending Orders
            </Typography>
          </Button>
        </Link>
        <Link to="Vitals" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Vitals
            </Typography>
          </Button>
        </Link>
        <Link to="LabResults" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Lab Results
            </Typography>
          </Button>
        </Link>
        <Link to="Immunizations" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Immunizations
            </Typography>
          </Button>
        </Link>
        <Link to="visits" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Visits
            </Typography>
          </Button>
        </Link>
        <Link to="DiagnosticReports" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Diagnostic Reports
            </Typography>
          </Button>
        </Link>
        <Link to="Notes" smooth offset={-300} duration={500}>
          <Button>
            <Typography style={{ color: '#979DB0' }} variant="subtitle2">
              Notes
            </Typography>
          </Button>
        </Link>
      </Box>
    </Box>
  );
}
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableDesc = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.common.black,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useTableStyles = makeStyles(theme => ({
  table: {
    maxHeight: 380,
    overflowY: 'scroll',
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar:vertical': {
      width: 8,
    },
    '&::-webkit-scrollbar:horizontal': {
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      border:
        '1px solid #ffffff' /* should match background, can't be transparent */,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  pagination: {
    color: theme.palette.common.white,
  },
}));

interface MessageProp {
  children: React.ReactNode;
}

const useMessageStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
    height: 90,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function Message({ children }: MessageProp) {
  const classes = useMessageStyles();
  return <div className={classes.root}>{children}</div>;
}

interface AllergiesTableProps {
  allergies: Array<Allergy> | undefined;
  isLoading: Boolean;
  error: any;
}

function isScrollable(data) {
  return data?.length > 5;
}
function noData(data) {
  return !data || data.length === 0;
}

function AllergiesTable(props: AllergiesTableProps) {
  const classes = useTableStyles();
  const { allergies, isLoading, error } = props;
   const {
     configSettings: { config },
   } = useAuth();
  const { lookBack, lookForward } = config.allergies;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(allergies),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Allergies"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Allergies table">
            <TableHead>
              {!noData(allergies) && (
                <TableRow>
                  <StyledTableDesc>Description</StyledTableDesc>
                  <StyledTableDesc>Reaction</StyledTableDesc>
                  <StyledTableDesc>Severity</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {allergies?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>
                    {row?.reactions.map(r => r.description).join(', ')}
                  </StyledTableCell>
                  <StyledTableCell>{row.severity?.display}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(allergies) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}

interface ProblemTableProps {
  problems: Array<Problem> | undefined;
  isLoading: Boolean;
  error: any;
}

function ProblemsTable(props: ProblemTableProps) {
  const classes = useTableStyles();
  const {
    configSettings: { config },
  } = useAuth();
  const { problems, isLoading, error } = props;
  const { lookBack, lookForward } = config.problems;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(problems),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Problems"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Problems table">
            <TableHead>
              {!noData(problems) && (
                <TableRow>
                  <StyledTableDesc>Description</StyledTableDesc>
                  <StyledTableDesc>Onset Date</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {problems?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>{row.onSetDateDisplay}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(problems) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}

function MedicationsTable(props: any) {
  const classes = useTableStyles();
  const {
    configSettings: { config },
  } = useAuth();
  const { medications, isLoading, error } = props;
  const { lookBack, lookForward } = config.medications;
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          component={Paper}
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(medications),
          })}
        >
          <EnhancedTableToolbar
            name="Medications"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table
            className={classes.table}
            stickyHeader
            aria-label="Medications table"
          >
            {!noData(medications) && (
              <TableHead>
                <TableRow>
                  <StyledTableDesc>Name</StyledTableDesc>
                  <StyledTableDesc>Details</StyledTableDesc>
                  <StyledTableDesc>Order Date</StyledTableDesc>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {medications?.map((row, index) => (
                <MedicationRow row={row} key={index} />
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(medications) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}

function getMnemonic(row) {
  return row.primaryMnemonic === row.orderedMnemonic ||
    row.orderedMnemonic === ''
    ? null
    : `(${row.orderedMnemonic})`;
}

const useClickableRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
      cursor: 'pointer',
    },
    '&:nth-of-type(4n+1)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function MedicationRow(props: any) {
  const classes = useClickableRowStyles();
  const [open, setOpen] = React.useState(false);
  const { row } = props;

  const oeFieldDisplay = useMemo(() => {
    const display = row?.orderDetails
      ?.filter(order => order.oeFieldMeaning === 'INDICATION')
      .map(order => order?.oeFieldDisplayValue);
    return display.length ? display : 'No Data';
  }, [row]);

  return (
    <>
      <TableRow onClick={() => setOpen(!open)} className={classes.root}>
        <StyledTableCell>
          {row.primaryMnemonic} {getMnemonic(row)}
        </StyledTableCell>
        <StyledTableCell>{row.orderDisplayLineShort}</StyledTableCell>
        <StyledTableCell>{formatDate(row.orderDate)}</StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="description">
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Indication</TableCell>
                    <TableCell>Ordering Provider</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.typeDisplay}
                    </TableCell>
                    <TableCell>{oeFieldDisplay}</TableCell>
                    <TableCell>
                      {row.orderingProviderName
                        ? row.orderingProviderName
                        : 'No Data'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface PendingOrderProps {
  pendingOrders: Array<PendingOrder> | undefined;
  isLoading: Boolean;
  error: any;
}

function PendingOrdersTable(props: PendingOrderProps) {
  const {
    configSettings: { config },
  } = useAuth();
  const { pendingOrders, isLoading, error } = props;
  const { lookBack, lookForward } = config.pendingOrders;
  const classes = useTableStyles();
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(pendingOrders),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Pending Orders"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Pending Orders table">
            <TableHead>
              {!noData(pendingOrders) && (
                <TableRow>
                  <StyledTableDesc>Name</StyledTableDesc>
                  <StyledTableDesc>Order Date</StyledTableDesc>
                  <StyledTableDesc>Ordering Provider</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {pendingOrders?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.orderedMnemonic}</StyledTableCell>
                  <StyledTableCell>{formatDate(row.orderDate)}</StyledTableCell>
                  <StyledTableCell>{row.orderingProvider}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(pendingOrders) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}

function getNormalcy(data) {
  return data.normalcy.display !== 'Normal' && data.normalcy.display !== ''
    ? `(${data.normalcy.display})`
    : null;
}

interface VitalsProps {
  vitals: Array<Vital> | undefined;
  isLoading: Boolean;
  error: any;
}

function VitalsTable(props: VitalsProps) {
  const classes = useTableStyles();
  const {
    configSettings: { config },
  } = useAuth();
  const { vitals, isLoading, error } = props;
  const { lookBack, lookForward } = config.vitals;
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(vitals),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Vitals"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Vitals table">
            <TableHead>
              {!noData(vitals) && (
                <TableRow>
                  <StyledTableDesc>Name</StyledTableDesc>
                  <StyledTableDesc>Value</StyledTableDesc>
                  <StyledTableDesc>Date</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {vitals?.map((row, index) => (
                <VitalRow row={row} key={index} />
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(vitals) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}

function VitalRow(props: any) {
  const classes = useClickableRowStyles();
  const [open, setOpen] = React.useState(false);
  const { row } = props;

  return (
    <>
      <TableRow onClick={() => setOpen(!open)} className={classes.root}>
        <StyledTableCell>{row.resultName?.display}</StyledTableCell>
        <StyledTableCell>
          {`${row.resultValue} ${row.resultUnit?.display}`} {getNormalcy(row)}
        </StyledTableCell>
        <StyledTableCell>{formatDate(row.resultDate)}</StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="description">
                <TableHead>
                  <TableRow>
                    <TableCell>Normal High</TableCell>
                    <TableCell>Normal Low</TableCell>
                    <TableCell>Critical High</TableCell>
                    <TableCell>Critical Low</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.normalHigh || 'no data'}</TableCell>
                    <TableCell>{row.normalLow || 'no data'}</TableCell>
                    <TableCell>{row.criticalHigh || 'no data'}</TableCell>
                    <TableCell>{row.criticalLow || 'no data'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface LabResultsProps {
  labResults: Array<labResult> | undefined;
  isLoading: Boolean;
  error: any;
}

function LabResultsTable(props: LabResultsProps) {
  const classes = useTableStyles();
  const {
    configSettings: { config },
  } = useAuth();
  const { labResults, isLoading, error } = props;
  const { lookBack, lookForward } = config.labResults;
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(labResults),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Lab Results"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="lab results table">
            <TableHead>
              {!noData(labResults) && (
                <TableRow>
                  <StyledTableDesc>Name</StyledTableDesc>
                  <StyledTableDesc>Value</StyledTableDesc>
                  <StyledTableDesc>Date</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {labResults?.map((row, index) => (
                <LabResultsRow row={row} key={index} />
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(labResults) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}

function LabResultsRow(props: any) {
  const classes = useClickableRowStyles();
  const [open, setOpen] = React.useState(false);
  const { row } = props;

  return (
    <>
      <TableRow onClick={() => setOpen(!open)} className={classes.root}>
        <StyledTableCell>{row.resultName.display}</StyledTableCell>
        <StyledTableCell>
          {`${row.resultValue} ${row.resultUnit?.display}`} {getNormalcy(row)}
        </StyledTableCell>
        <StyledTableCell>{formatDate(row.date)}</StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="description">
                <TableHead>
                  <TableRow>
                    <TableCell>Normal Low</TableCell>
                    <TableCell>Normal High</TableCell>
                    <TableCell>Critical Low</TableCell>
                    <TableCell>Critical High</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.normalLow || 'no data'}
                    </TableCell>
                    <TableCell>{row.normalHigh || 'no data'}</TableCell>
                    <TableCell>{row.criticalLow || 'no data'}</TableCell>
                    <TableCell>{row.criticalHigh || 'no data'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface ImmunizationsProps {
  immunizations: Array<Immunization> | undefined;
  isLoading: Boolean;
  error: any;
}

function ImmunizationsTable(props: ImmunizationsProps) {
  const classes = useTableStyles();
  const {
    configSettings: { config },
  } = useAuth();
  const { immunizations, isLoading, error } = props;
  const { lookBack, lookForward } = config.diagnosticReports;
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(immunizations),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Immunizations"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Immunizations table">
            <TableHead>
              {!noData(immunizations) && (
                <TableRow>
                  <StyledTableDesc>Name</StyledTableDesc>
                  <StyledTableDesc>Date</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>

            <TableBody>
              {immunizations?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {row.immunizationName.display}
                  </StyledTableCell>
                  <StyledTableCell>{formatDate(row.date)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(immunizations) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}
const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    title: {
      flex: '1 1',
      fontSize: 14,
    },
  }),
);

interface EnhancedTableToolbarProps {
  name: string;
  children?: React.ReactNode;
  lookBack?: {
    value: number;
    unit: string;
  };
  lookForward?: {
    value: number;
    unit: string;
  };
}
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { name, children, lookBack, lookForward } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {name}
        {/* Diplays lookback and forward range */}
        {((lookBack && lookBack.value > 0) ||
          (lookForward && lookForward.value > 0)) &&
          ' ('}
        {lookBack &&
          lookBack.value > 0 &&
          `past ${lookBack.value} ${lookBack.unit}`}
        {lookForward &&
          lookBack &&
          lookForward.value > 0 &&
          lookBack.value > 0 &&
          ' to '}
        {lookForward &&
          lookForward.value > 0 &&
          `next ${lookForward.value} ${lookForward.unit}`}
        {((lookBack && lookBack.value > 0) ||
          (lookForward && lookForward.value > 0)) &&
          ') '}
      </Typography>
      {children}
    </Toolbar>
  );
};

function VisitsRow(props: any) {
  const classes = useClickableRowStyles();
  const [open, setOpen] = useState(false);
  const { row, index } = props;

  function getEncounterDiagnoses() {
    const list = row.encounter?.encounterDiagnoses
      .map(e => e.description)
      .join(', ');
    return list?.length ? list : 'No Data';
  }

  const encounterDiagnoses = useMemo(getEncounterDiagnoses, [row]);

  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        className={classes.root}
        key={index}
      >
        <StyledTableCell>
          {row.appointment?.apptBeginDate
            ? formatDate(row.appointment?.apptBeginDate)
            : formatDate(row?.encounter?.regDate)}
        </StyledTableCell>
        <StyledTableCell>
          {row?.encounter?.dischargeDate
            ? formatDate(row?.encounter?.dischargeDate)
            : null}
        </StyledTableCell>
        <StyledTableCell>
          {row?.encounter?.encounterRelationships
            .filter(r => r.relationshipType.display === 'Attending Physician')
            .map(r => r.providerName)
            .join(', ')}
        </StyledTableCell>

        <StyledTableCell>
          {row?.encounter?.encounterRelationships
            .filter(r => r.relationshipType.display === 'Admitting Physician')
            .map(r => r.providerName)
            .join(', ')}
        </StyledTableCell>
        <StyledTableCell>
          {row.encounter
            ? row.encounter.encounterType.display
            : row.appointment?.apptType.display}
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="description">
                <TableHead>
                  <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell>Diagnoses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        row.appointment?.apptLocation?.description
                          ? row.appointment?.apptLocation?.description
                          : row.encounter?.unitLocation?.description
                          ? row.encounter?.unitLocation?.description
                          : 'No Data'
                      }
                    </TableCell>
                    <TableCell>{encounterDiagnoses}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface VisitsTableProps {
  visits: Array<Visit> | undefined;
  isLoading: Boolean;
  error: any;
}

function VisitsTable(props: VisitsTableProps) {
  const classes = useTableStyles();
  const {
    configSettings: { config },
  } = useAuth();
  const { lookBack, lookForward } = config.visits;
  const { visits, isLoading, error } = props;
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(visits),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Visits"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Visits table">
            <TableHead>
              {!noData(visits) && (
                <TableRow>
                  <StyledTableDesc>Date</StyledTableDesc>
                  <StyledTableDesc>Disch. Date</StyledTableDesc>
                  <StyledTableDesc>Attending</StyledTableDesc>
                  <StyledTableDesc>Admitting</StyledTableDesc>
                  <StyledTableDesc>Type</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {visits?.map((row, index) => (
                <VisitsRow row={row} key={index} />
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(visits) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
    </>
  );
}

interface DiagnosticsTableProps {
  diagnostics: Array<Diagnostic> | undefined;
  isLoading: Boolean;
  error: any;
  patientId: number;
  patient: any;
}

function DiagnosticsTable(props: DiagnosticsTableProps) {
  const classes = useTableStyles();
  const {
    configSettings: { config },
  } = useAuth();
  const { diagnostics, isLoading, error, patientId, patient } = props;
  const { lookBack, lookForward } = config.diagnosticReports;

  const [open, setDialogOpen] = useState<boolean>(false);
  const [eventId, setEventId] = useState<null | number>(null);
  const { data: diagnostic, isLoading: isReportLoading } = useDiagnosticReport({
    eventId,
  });

  const [
    audit,
    { isError: isAuditError, isSuccess: isAuditSuccess, status: statusAudit },
  ] = useAudit();

  function getDocument(id: number) {
    setDialogOpen(true);
    setEventId(id);
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(diagnostics),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Diagnostics"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Diagnostics table">
            <TableHead>
              {!noData(diagnostics) && (
                <TableRow>
                  <StyledTableDesc>Name</StyledTableDesc>
                  <StyledTableDesc>Date</StyledTableDesc>
                  <StyledTableDesc>Status</StyledTableDesc>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {diagnostics?.map((row, index) => (
                <StyledTableRow
                  style={{ cursor: 'pointer' }}
                  key={index}
                  onClick={() => getDocument(row.id)}
                >
                  <StyledTableCell>{row.resultName.display}</StyledTableCell>
                  <StyledTableCell>{formatDate(row.dtTm)}</StyledTableCell>
                  <StyledTableCell>{row.status.display}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(diagnostics) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
      <ScrollDialog
        open={open}
        onChange={(value: boolean) => setDialogOpen(value)}
        title="Report"
        isLoading={isReportLoading}
        printCb={() =>
          audit({
            eventType: 'Patients:Diagnostic:Print:Report',
            details: {
              patientId,
              uiScreen: 'Print Diagostic Report',
              eventId: String(diagnostic?.reportId),
            },
          })
        }
      >
        {isReportLoading ? (
          <Box width={350}>
            <Spinner />
          </Box>
        ) : diagnostic?.fullDocument ? (
          <Box minWidth={400}>
            {getHospitalInformationJSX(
              'diagnostic',
              config.diagnosticReports,
              diagnostic,
            )}
            <Box display="flex" m={1} flexWrap>
              <Box p={1}>
                <Typography variant="body2">{patient.name}</Typography>
              </Box>
              <Box p={1}>
                <Typography variant="body2">
                  DOB: {formatDOBDate(new Date(patient.dob))}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant="body2">MRN: {patient.mrn}</Typography>
              </Box>
            </Box>
            <Box my={2} ml={2} display="flex">
              <Encounter encounter={diagnostic.encounter} />
              <Box m={1}>
                <Typography variant="body2">{diagnostic.reportName}</Typography>
                <Typography variant="body2">
                  {formatDOBDate(new Date(diagnostic.reportDtTm))}
                </Typography>
                <Typography variant="body2">
                  Performed By: {diagnostic.performedBy}
                </Typography>
                <Typography variant="body2">
                  Status: {diagnostic.reportStatus.display}
                </Typography>
              </Box>
            </Box>
            <RenderHTML html={diagnostic?.fullDocument} />
          </Box>
        ) : (
          <Box minWidth={350}>Nothing to display</Box>
        )}
      </ScrollDialog>
    </>
  );
}

interface NotesProps {
  notes: Array<Note> | undefined;
  isLoading: boolean;
  error: any;
  patientId: number;
  patient: any;
}

function RenderHTML(props: { html: string }) {
  const { html } = props;
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

function getHospitalInformation(name, encounter) {
  return (
    <Box m={1} display="flex" flexDirection="column" alignItems="center">
      <Typography>{encounter[name].description}</Typography>
      {encounter[name].address.streetLine1 && (
        <Typography>{encounter[name].address.streetLine1}</Typography>
      )}
      {encounter[name].address.streetLine2 && (
        <Typography>{encounter[name].address.streetLine2}</Typography>
      )}
      {encounter[name].address.city && (
        <Typography>{`${encounter[name].address.city}, ${encounter[name].address.state}`}</Typography>
      )}
      {encounter[name].address.zipCode && (
        <Typography>{encounter[name].address.zipCode}</Typography>
      )}
    </Box>
  );
}

const findAddress = type => address => {
  return address.find(a => a.type.display === type);
};

const getAddressJSX = address => {
  if (!address) return null;
  return (
    <Box m={1} display="flex" flexDirection="column" alignItems="center">
      {address.streetLine1 && (
        <Typography variant="body2">{address.streetLine1}</Typography>
      )}
      {address.streetLine2 && (
        <Typography variant="body2">{address.streetLine2}</Typography>
      )}
      {address.city && (
        <Typography variant="body2">{`${address.city}, ${address.state}`}</Typography>
      )}
      {address.zipCode && (
        <Typography variant="body2">{address.zipCode}</Typography>
      )}
    </Box>
  );
};

const getAuthorAddress = (type, address) =>
  flow(findAddress(type), getAddressJSX)(address);

function getHospitalInformationJSX(name, config, data) {
  switch (config.headerFacilitySource.source) {
    case 'encounter.facility': {
      return getHospitalInformation('facility', data.encounter);
    }
    case 'encounter.building': {
      return getHospitalInformation('building', data.encounter);
    }
    case 'author':
      {
        const type = config.headerFacilitySource.addressType;
        if (name === 'document') {
          return getAuthorAddress(type, data.authorAddress);
        }
        if (name === 'diagnostic') {
          return getAuthorAddress(type, data.performedByAddress);
        }
      }
      break;
    default:
      return null;
  }
}

function Encounter({ encounter }) {
  return (
    <Box m={1}>
      <Typography variant="body2">Encounter:</Typography>
      <Typography variant="body2">{encounter.facility.description}</Typography>
      <Typography variant="body2">{encounter.type.display}</Typography>
      {encounter.regDate && (
        <Typography variant="body2">
          {formatDate(encounter.regDate)}{' '}
          {encounter.dischargeDate &&
            `- ${formatDate(encounter.dischargeDate)}`}
        </Typography>
      )}
      <Typography variant="body2">FIN: {encounter.fin}</Typography>
    </Box>
  );
}

function NotesTable(props: NotesProps) {
  const {
    configSettings: { config },
  } = useAuth();

  const classes = useTableStyles();
  const { notes, isLoading, error, patientId, patient } = props;
  const [open, setDialogOpen] = useState<boolean>(false);
  const [eventId, setEventId] = useState<null | number>(null);
  const [
    audit,
    { isError: isAuditError, isSuccess: isAuditSuccess, status: statusAudit },
  ] = useAudit();
  const { data: document, isLoading: isDocumentLoading } = useNotesDocument({
    eventId,
  });
  const { lookBack, lookForward } = config.documents;

  function getDocument(id: number) {
    setDialogOpen(true);
    setEventId(id);
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer
          className={clsx(classes.table, {
            [classes.scrollbar]: isScrollable(notes),
          })}
          component={Paper}
        >
          <EnhancedTableToolbar
            name="Notes"
            lookBack={lookBack}
            lookForward={lookForward}
          />
          <Table stickyHeader aria-label="Notes table">
            {!noData(notes) && (
              <TableHead>
                <TableRow>
                  <StyledTableDesc>Name</StyledTableDesc>
                  <StyledTableDesc>Date</StyledTableDesc>
                  <StyledTableDesc>Author</StyledTableDesc>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {notes?.map((row, index) => (
                <StyledTableRow
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => getDocument(row.id)}
                >
                  <StyledTableCell>{row.resultName.display}</StyledTableCell>
                  <StyledTableCell>{formatDate(row.dtTm)}</StyledTableCell>
                  <StyledTableCell>{row.author}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {error && (
            <Message>
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                Unable to retrieve data from EMR
              </Typography>
            </Message>
          )}
          {noData(notes) && !error && (
            <Message>
              <Typography variant="subtitle1">No Data</Typography>
            </Message>
          )}
        </TableContainer>
      )}
      <ScrollDialog
        open={open}
        onChange={(value: boolean) => setDialogOpen(value)}
        title="Document"
        isLoading={isDocumentLoading}
        printCb={() =>
          audit({
            eventType: 'Patients:Notes:Print:Document',
            details: {
              patientId,
              uiScreen: 'Print Note Document',
              eventId: String(document?.documentId),
            },
          })
        }
      >
        {isDocumentLoading ? (
          <Box width={350}>
            <Spinner />
          </Box>
        ) : document?.fullDocument ? (
          <Box minWidth={350}>
            {getHospitalInformationJSX('document', config.documents, document)}
            <Box display="flex" m={1} flexWrap>
              <Box p={1}>
                <Typography variant="body2">{patient.name}</Typography>
              </Box>
              <Box p={1}>
                <Typography variant="body2">
                  DOB: {formatDOBDate(new Date(patient.dob))}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant="body2">MRN: {patient.mrn}</Typography>
              </Box>
            </Box>
            <Box my={2} ml={1} display="flex" justifyContent="space-between">
              <Encounter encounter={document.encounter} />
              <Box m={1}>
                <Typography variant="body2">{document.documentName}</Typography>
                <Typography variant="body2">
                  {formatDOBDate(new Date(document.documentDtTm))}
                </Typography>
                <Typography variant="body2">
                  Author: {document.documentAuthor}
                </Typography>
                <Typography variant="body2">
                  Status: {document.documentStatus.display}
                </Typography>
              </Box>
            </Box>
            <RenderHTML html={document?.fullDocument} />
          </Box>
        ) : (
          <Box minWidth={350}>Nothing to display</Box>
        )}
      </ScrollDialog>
    </>
  );
}

export default Chart;
